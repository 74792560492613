import { AsyncPipe, NgFor, NgIf, SlicePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, debounceTime } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { FuehrungsebeneDTO, Fuehrungsebenentyp, TaktischesZeichenStatus } from 'src/app/api/build/openapi';
import { DatetimeLocalAccessorDirective } from 'src/app/shared/accessors/datetime-local-accessor.directive';
import { LoadableComponentComponent } from 'src/app/shared/components/loadable-component/loadable-component.component';
import { DateTimePipe } from 'src/app/shared/pipes/date-time.pipe';
import { TaktischeZeichenService } from '../../taktische-zeichen/taktische-zeichen.service';
import { BaseMapComponent } from '../base-map/base-map.component';
import { FuehrungsebeneHierarchieTreeComponent } from '../fuehrungsebene-hierarchie/fuehrungsebene-hierarchie-tree/fuehrungsebene-hierarchie-tree.component';
import {
  childFuehrungsebenenSelector,
  currentFuehrungsebeneSelector,
  isLoadingFuehrungsebeneSelector,
} from '../fuehrungsebene/+state/fuehrungsebene.selectors';
import { FuehrungsebeneCardComponent } from '../fuehrungsebene/fuehrungsebene-card/fuehrungsebene-card.component';
import { FuehrungsebeneChildCounterComponent } from '../fuehrungsebene/fuehrungsebene-child-counter/fuehrungsebene-child-counter.component';
import {
  FuehrungsebeneDetailsDialogComponent,
  FuehrungsebeneDialogData,
} from '../fuehrungsebene/fuehrungsebene-details-dialog/fuehrungsebene-details-dialog.component';
import { FuehrungsebeneDisplayService } from '../fuehrungsebene/fuehrungsebene-display.service';
import { FuehrungsebeneHeaderComponent } from '../fuehrungsebene/fuehrungsebene-header/fuehrungsebene-header.component';
import { FuehrungsebeneVisibilityDialogComponent } from '../fuehrungsebene/fuehrungsebene-visibility-dialog/fuehrungsebene-visibility-dialog.component';
import { FuehrungsebeneService } from '../fuehrungsebene/fuehrungsebene.service';
import { LogistikSmallComponent } from '../fuehrungsebene/logistik/logistik-small/logistik-small.component';
import { LagedarstellungSidebarToolsComponent } from '../lagedarstellung-sidebar-tools/lagedarstellung-sidebar-tools.component';
import { LagezeitComponent } from '../lagezeit/lagezeit.component';
import { LayerService } from '../layer.service';
import { TaktischeZeichenContainerComponent } from '../taktische-zeichen/taktische-zeichen-container/taktische-zeichen-container.component';
import { ToolService } from '../tool.service';
import { PersonenuebersichtComponent } from '../triage/personenuebersicht/personenuebersicht.component';
import { LagedarstellungWetterAnzeigeComponent } from '../wetter/lagedarstellung-wetter-anzeige/lagedarstellung-wetter-anzeige.component';

export enum MapLayout {
  FULL,
  MAP_ONLY,
}
/**
 * Ansicht für den gesamten mittleren Teil der Lagewand.
 * Enthält die Lagekarte, Einsatzabschnitte und Custom-Felder die zukünftig noch folgen.
 */
@Component({
  selector: 'app-lage-map',
  templateUrl: './lage-map.component.html',
  styleUrls: ['./lage-map.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    PersonenuebersichtComponent,
    NgIf,
    FuehrungsebeneHeaderComponent,
    TaktischeZeichenContainerComponent,
    LagedarstellungWetterAnzeigeComponent,
    LogistikSmallComponent,
    MatIconModule,
    MatTooltipModule,
    MatToolbarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    NgFor,
    FuehrungsebeneCardComponent,
    MatButtonToggleModule,
    LagedarstellungSidebarToolsComponent,
    BaseMapComponent,
    FuehrungsebeneHierarchieTreeComponent,
    AsyncPipe,
    SlicePipe,
    DateTimePipe,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    DatetimeLocalAccessorDirective,
    LoadableComponentComponent,
    LagezeitComponent,
    FuehrungsebeneChildCounterComponent,
  ],
})
export class LageMapComponent {
  MapLayout = MapLayout;

  @Input()
  layout: MapLayout = MapLayout.FULL;

  Fuehrungsebenentyp = Fuehrungsebenentyp;
  currentFuehrungsebene: FuehrungsebeneDTO | null = null;
  childFuehrungsebeneHeader = '';

  // Sammlungen/Zählungen von ChildFuehrungsebenenn und der Auswahl angezeigter Fuehrungsebenen daraus
  childMainFuehrungsebenen: FuehrungsebeneDTO[] = [];
  canMoreChildrenBeAdded = false;

  // Maximal 6 Fuehrungsebenen dürfen links und rechts neben der Karte angezeigt werden
  readonly maxDisplayedFuehrungsebeneCount = 6;
  displayedFuehrungsebenen: FuehrungsebeneDTO[] = [];
  headers$ = this.fuehrungsebeneDisplayService.headers$;

  // True, wenn Custom-Elemente ausgeblendet werden sollen
  fullscreen = false;
  customCollapsed = false;
  leftCollapsed = false;
  rightCollapsed = false;

  isLoadingFuehrungsebenen$: Observable<boolean>;

  constructor(
    protected layerService: LayerService,
    protected toolService: ToolService,
    protected store: Store<AppStateInterface>,
    private dialog: MatDialog,
    protected taktischeZeichenService: TaktischeZeichenService,
    private fuehrungsebeneService: FuehrungsebeneService,
    private fuehrungsebeneDisplayService: FuehrungsebeneDisplayService
  ) {
    this.isLoadingFuehrungsebenen$ = this.store
      .select(isLoadingFuehrungsebeneSelector)
      .pipe(takeUntilDestroyed(), debounceTime(500));

    combineLatest([
      store.select(currentFuehrungsebeneSelector),
      store.select(childFuehrungsebenenSelector),
      fuehrungsebeneDisplayService.displayedFuehrungsebenen$,
    ])
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.currentFuehrungsebene = value[0];
        this.childMainFuehrungsebenen = value[1].filter((child) =>
          fuehrungsebeneService.mainFuehrungsebenentypen.includes(child.typ)
        );
        this.displayedFuehrungsebenen = value[2];

        if (
          this.currentFuehrungsebene?.typ === Fuehrungsebenentyp.Unterabschnitt ||
          this.currentFuehrungsebene?.typ === Fuehrungsebenentyp.LogistikEinsatzabschnittChild ||
          this.currentFuehrungsebene?.typ === Fuehrungsebenentyp.LogistikEinsatzstelleChild
        ) {
          this.setCollapsedLeft(true);
          this.setCollapsedRight(true);
        } else {
          this.setCollapsedLeft(false);
          this.setCollapsedRight(false);
        }

        if (!this.currentFuehrungsebene) {
          this.canMoreChildrenBeAdded = false;
          return;
        }

        this.canMoreChildrenBeAdded =
          this.fuehrungsebeneService.getAvailableChildTypes(
            this.currentFuehrungsebene,
            this.fuehrungsebeneService.mainFuehrungsebenentypen
          ).length > 0;
      });

    fuehrungsebeneService.childFuehrungsebeneHeader$
      .pipe(takeUntilDestroyed())
      .subscribe((header) => (this.childFuehrungsebeneHeader = header));
  }

  toggleCollapsedCustom() {
    this.fullscreen = false;
    this.customCollapsed = !this.customCollapsed;
    setTimeout(() => window.dispatchEvent(new Event('resize')), 250);
  }

  setCollapsedLeft(collapsed: boolean) {
    this.fullscreen = false;
    this.leftCollapsed = collapsed;
    setTimeout(() => window.dispatchEvent(new Event('resize')), 250);
  }

  toggleCollapsedLeft() {
    this.setCollapsedLeft(!this.leftCollapsed);
  }

  setCollapsedRight(collapsed: boolean) {
    this.fullscreen = false;
    this.rightCollapsed = collapsed;
    setTimeout(() => window.dispatchEvent(new Event('resize')), 250);
  }

  toggleCollapsedRight() {
    this.setCollapsedRight(!this.rightCollapsed);
  }

  setFullscreen(fullscreen: boolean) {
    this.fullscreen = fullscreen;
    this.customCollapsed = fullscreen;
    this.rightCollapsed = fullscreen;
    this.leftCollapsed = fullscreen;
    setTimeout(() => window.dispatchEvent(new Event('resize')), 250);
  }

  createFuehrungsebene() {
    const preparedFuehrungsebene = this.fuehrungsebeneService.prepareNewChildFuehrungsebene();
    if (preparedFuehrungsebene) {
      const inputData: FuehrungsebeneDialogData = {
        fuehrungsebeneDTO: preparedFuehrungsebene,
        filteredTypes: this.fuehrungsebeneService.mainFuehrungsebenentypen,
      };
      this.dialog.open(FuehrungsebeneDetailsDialogComponent, {
        data: inputData,
      });
    }
  }

  editFuehrungsebene(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    this.openFuehrungsebeneDetails({ ...fuehrungsebeneDTO });
  }

  protected fuehrungsebenentypToTztyp(fuehrungsebenentyp: Fuehrungsebenentyp): TaktischesZeichenStatus | undefined {
    return this.fuehrungsebeneService.fuehrungsebenentypStatusMapping.get(fuehrungsebenentyp);
  }

  private openFuehrungsebeneDetails(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    this.dialog.open(FuehrungsebeneDetailsDialogComponent, {
      data: { fuehrungsebeneDTO: fuehrungsebeneDTO },
    });
  }

  openFuehrungsebeneVisibilityDialog() {
    this.dialog.open(FuehrungsebeneVisibilityDialogComponent);
  }
}
