import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { ErrorResponse, PersonenschadenResourceService } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { personenschadenActions } from './personenschaden.actions';

@Injectable()
export class PersonenschadenEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Personenschäden anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return personenschadenActions.getPersonenschaeden({ lageId: props.currentLage.id });
        } else {
          return personenschadenActions.resetStore();
        }
      })
    )
  );

  /**
   * Alle Personenschäden einer Lage laden
   */
  getPersonenschaeden$ = createEffect(() =>
    this.actions$.pipe(
      ofType(personenschadenActions.getPersonenschaeden),
      mergeMap((props) =>
        this.personenschaedenResourceService.getPersonenschaedenByLageId(props.lageId).pipe(
          map((personenschaeden) =>
            personenschadenActions.getPersonenschaedenSuccess({
              personenschadenDTOs: personenschaeden,
              lageId: props.lageId,
            })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(personenschadenActions.getPersonenschaedenFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Neuen Personenschaden anlegen
   */
  createPersonenschaden$ = createEffect(() =>
    this.actions$.pipe(
      ofType(personenschadenActions.createPersonenschaden),
      mergeMap((props) =>
        this.personenschaedenResourceService.createPersonenschaden(props.lageId, props.personenschadenDTO).pipe(
          map((createdPersonenschadenDTO) =>
            personenschadenActions.createPersonenschadenSuccess({ createdPersonenschadenDTO })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(personenschadenActions.createPersonenschadenFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Existierenden Personenschaden editieren
   */
  patchPersonenschaden$ = createEffect(() =>
    this.actions$.pipe(
      ofType(personenschadenActions.patchPersonenschaden),
      mergeMap((props) => {
        if (!props.personenschadenDTO.id) {
          return throwError(() => new Error('Personenschaden muss eine Id besitzen'));
        }
        return this.personenschaedenResourceService
          .patchPersonenschaden(props.lageId, props.personenschadenDTO.id, props.personenschadenDTO)
          .pipe(
            map((patchedPersonenschadenDTO) =>
              personenschadenActions.patchPersonenschadenSuccess({ patchedPersonenschadenDTO })
            ),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(personenschadenActions.patchPersonenschadenFailure({ errorResponse }));
            })
          );
      })
    )
  );

  constructor(private actions$: Actions, private personenschaedenResourceService: PersonenschadenResourceService) {}
}
