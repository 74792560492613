<div class="lage-map-container">
  <!-- Zeile mit Custom-Elementen -->
  <div class="custom-bar" *ngIf="this.layout === MapLayout.FULL" [style.flexBasis.%]="customCollapsed ? 0 : 20">
    <mat-card class="custom-element">
      <app-personenuebersicht />
    </mat-card>

    <mat-card class="schadenkonto-container">
      <app-loadable [isLoading$]="isLoadingFuehrungsebenen$">
        <div
          class="schadenkonto"
          *ngIf="
            currentFuehrungsebene &&
            currentFuehrungsebene?.typ &&
            fuehrungsebenentypToTztyp(currentFuehrungsebene.typ!) as tzTyp
          "
        >
          <app-fuehrungsebene-header
            [fuehrungsebeneDTO]="currentFuehrungsebene"
            (edit)="editFuehrungsebene(currentFuehrungsebene)"
          />
          <app-taktische-zeichen-container
            class="flex-grow scrollable"
            [groupItems]="true"
            [fuehrungsebeneDTO]="currentFuehrungsebene"
            [taktischesZeichenStatus]="tzTyp"
          />
        </div>
      </app-loadable>
    </mat-card>

    <mat-card class="custom-element-empty">
      <button mat-icon-button [disabled]="true">
        <mat-icon>add</mat-icon>
      </button>
    </mat-card>

    <mat-card class="custom-element">
      <app-lagedarstellung-wetter-anzeige />
    </mat-card>

    <mat-card class="custom-element">
      <app-logistik-small />
    </mat-card>
  </div>

  <!-- Button zum ein-/ausklappen der Custom-Elemente -->
  <mat-icon
    *ngIf="this.layout === MapLayout.FULL"
    class="custom-collapse"
    (click)="toggleCollapsedCustom()"
    [matTooltip]="'Custom-Elemente ' + (customCollapsed ? 'ausklappen' : 'einklappen')"
    >{{ customCollapsed ? "expand_more" : "expand_less" }}</mat-icon
  >

  <!-- Zeile mit Karte in der Mitte und Fuehrungsebenen links und rechts -->
  <div class="ea-map-row">
    <!-- Fuehrungsebenen auf der linken Seite -->
    <mat-card
      class="fuehrungsebene-column"
      *ngIf="this.layout === MapLayout.FULL"
      [style.flexBasis.%]="leftCollapsed ? 0 : 20"
    >
      <mat-toolbar class="column-header">
        <app-fuehrungsebene-child-counter />
        <span>
          <button
            mat-icon-button
            (click)="openFuehrungsebeneVisibilityDialog()"
            [disabled]="!childMainFuehrungsebenen.length"
            matTooltip="Sichtbare Führungsebenen festlegen"
          >
            <mat-icon>checklist</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="createFuehrungsebene()"
            [disabled]="!canMoreChildrenBeAdded"
            matTooltip="Führungsebene hinzufügen"
          >
            <mat-icon>add</mat-icon>
          </button>
        </span>
      </mat-toolbar>

      <!-- Nur die geraden Fuehrungsebenen -->
      <app-loadable [isLoading$]="isLoadingFuehrungsebenen$">
        <ng-container
          *ngFor="
            let fuehrungsebeneDTO of displayedFuehrungsebenen | slice : 0 : maxDisplayedFuehrungsebeneCount;
            let even = even
          "
        >
          <app-fuehrungsebene-card
            *ngIf="even"
            [fuehrungsebeneDTO]="fuehrungsebeneDTO"
            (edit)="editFuehrungsebene(fuehrungsebeneDTO)"
          />
        </ng-container>
      </app-loadable>

      <h3 class="keine-daten-text" *ngIf="!childMainFuehrungsebenen.length">Keine Führungsebenen vorhanden</h3>
      <h3 class="keine-daten-text" *ngIf="childMainFuehrungsebenen.length && !displayedFuehrungsebenen.length">
        Keine Führungsebenen zur Anzeige ausgewählt
      </h3>
    </mat-card>

    <!-- Button zum ein-/ausklappen der Linken Child-Fuehrungsebenen -->
    <mat-icon
      *ngIf="this.layout === MapLayout.FULL"
      class="left-collapse"
      (click)="toggleCollapsedLeft()"
      [matTooltip]="'Führungsebenen links ' + (leftCollapsed ? 'ausklappen' : 'einklappen')"
      >{{ leftCollapsed ? "expand_more" : "expand_less" }}</mat-icon
    >

    <!-- Karte in der Mitte -->
    <mat-card class="map-center">
      <!-- Karten-Header -->
      <mat-toolbar class="column-header">
        <app-lagezeit />

        <!-- Buttons zum enablen/disablen von Tool- und Layer-Sidebar sowie Festsetzen der Karte -->
        <span class="flex-row flex-center">
          <!-- <mat-button-toggle-group>
            <mat-button-toggle
              (click)="taktischeZeichenService.toggleLineVisibility()"
              [checked]="!taktischeZeichenService.linesVisible"
            >
              <mat-icon>
                {{ this.taktischeZeichenService.linesVisible ? "visibility" : "visibility_off" }}
              </mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group> -->

          <mat-button-toggle-group class="mr-05">
            <mat-button-toggle
              (click)="layerService.toggleMapLocked()"
              [checked]="layerService.mapLocked$ | async"
              matTooltip="Karte sperren"
              matTooltipPosition="below"
              matTooltipClass="tooltip"
            >
              <mat-icon>vpn_lock</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle
              (click)="setFullscreen(!fullscreen)"
              [checked]="fullscreen"
              [matTooltip]="fullscreen ? 'Karte verkleinern' : 'Karte maximieren'"
              matTooltipPosition="below"
              matTooltipClass="tooltip"
            >
              <mat-icon>{{ fullscreen ? "fullscreen_exit" : "fullscreen" }}</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>

          <mat-button-toggle-group>
            <!-- <mat-button-toggle
              (click)="toolService.toggleToolSidebarVisibility()"
              [checked]="toolService.toolSidebarVisibility$ | async"
              matTooltip="Werkzeuge einblenden"
              matTooltipPosition="below"
              matTooltipClass="tooltip"
            >
              <mat-icon>draw</mat-icon>
            </mat-button-toggle> -->
            <mat-button-toggle
              (click)="layerService.toggleLayerSidebarVisibility()"
              [checked]="layerService.layerSidebarVisibility$ | async"
              matTooltip="Kartenlayer einblenden"
              matTooltipPosition="below"
              matTooltipClass="tooltip"
            >
              <mat-icon>layers</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </span>
      </mat-toolbar>

      <!-- Karte mit Optionalen Sidebars für Tools und Layers -->
      <div class="map-container">
        <app-lagedarstellung-sidebar-tools *ngIf="toolService.toolSidebarVisibility$ | async" />
        <app-base-map class="flex-grow" />
        <app-fuehrungsebene-hierarchie-tree *ngIf="layerService.layerSidebarVisibility$ | async" />
      </div>
    </mat-card>

    <!-- Button zum ein-/ausklappen der rechten Child-Fuehrungsebenen -->
    <mat-icon
      *ngIf="this.layout === MapLayout.FULL"
      class="right-collapse"
      (click)="toggleCollapsedRight()"
      [matTooltip]="'Führungsebenen rechts ' + (rightCollapsed ? 'ausklappen' : 'einklappen')"
      >{{ rightCollapsed ? "expand_more" : "expand_less" }}</mat-icon
    >

    <!-- Fuehrungsebenen auf der rechten Seite -->
    <mat-card
      class="fuehrungsebene-column"
      *ngIf="this.layout === MapLayout.FULL"
      [style.flexBasis.%]="rightCollapsed ? 0 : 20"
    >
      <mat-toolbar class="column-header">
        <app-fuehrungsebene-child-counter />
      </mat-toolbar>

      <!-- Nur die ungeraden Fuehrungsebenen -->
      <app-loadable [isLoading$]="isLoadingFuehrungsebenen$">
        <ng-container
          *ngFor="
            let fuehrungsebeneDTO of displayedFuehrungsebenen | slice : 0 : maxDisplayedFuehrungsebeneCount;
            let odd = odd
          "
        >
          <app-fuehrungsebene-card
            *ngIf="odd"
            [fuehrungsebeneDTO]="fuehrungsebeneDTO"
            (edit)="editFuehrungsebene(fuehrungsebeneDTO)"
          />
        </ng-container>
      </app-loadable>
      <h3 class="keine-daten-text" *ngIf="!childMainFuehrungsebenen.length">Keine Führungsebenen vorhanden</h3>
      <h3 class="keine-daten-text" *ngIf="childMainFuehrungsebenen.length && !displayedFuehrungsebenen.length">
        Keine Führungsebenen zur Anzeige ausgewählt
      </h3>
    </mat-card>
  </div>
</div>
