<app-tz-popup-toolbar [tzDto]="dto" (editClicked)="editDto()" (moveClicked)="moveDto()" />

<div class="content">
  <app-fuehrungsebene-icon-and-text *ngIf="parentFuehrungsebene" [fuehrungsebeneDTO]="parentFuehrungsebene" />

  <div class="columns">
    <mat-card class="column">
      <div class="column-header">
        <img src="assets/svg/tz/tier-verletzt.svg" />
        <h5>verletzt</h5>
      </div>
      <div class="column-data">
        <h5>{{ tierschadenSubset.verletzt }}</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-header">
        <img src="assets/svg/tz/tier-tot.svg" />
        <h5>tot</h5>
      </div>
      <div class="column-data">
        <h5>{{ tierschadenSubset.tot }}</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-header">
        <img src="assets/svg/tz/tier.svg" />
        <h5>betroffen</h5>
      </div>
      <div class="column-data">
        <h5>{{ tierschadenSubset.betroffen }}</h5>
      </div>
    </mat-card>
  </div>
</div>
