import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Feature } from 'geojson';
import { BehaviorSubject } from 'rxjs';
import { TaktischesZeichenTyp } from '../api/build/openapi';
import { LogService } from '../shared/services/log.service';
import { SnackbarService } from '../shared/services/snackbar.service';
import { LayerService } from './layer.service';
import { Tool, ToolSettings, ToolType } from './tool.interface';

@Injectable({
  providedIn: 'root',
})
export class ToolService {
  private currentFeature: Feature | null = null;

  // Blendet Informationen zur Werkzeugnutzung ein/aus
  showToolHint$ = new BehaviorSubject<boolean>(false);
  // Verwaltet die Sichtbarkeit der Werkzeug-Sidebar der Lagekarte
  toolSidebarVisibility$ = new BehaviorSubject<boolean>(false);

  // Alle Tools, die zur Kartenbearbeitung angeboten werden
  readonly tools: Tool[] = [
    {
      name: 'Cursor',
      type: ToolType.Cursor,
      icon: 'north_west',
    },
    {
      name: 'Punkt',
      type: ToolType.Point,
      icon: 'location_on',
    },
    {
      name: 'Linie',
      type: ToolType.Line,
      icon: 'polyline',
    },
    {
      name: 'Rechteck',
      type: ToolType.Rectangle,
      icon: 'rectangle',
    },
    {
      name: 'Kreis',
      type: ToolType.Circle,
      icon: 'circle',
    },
    {
      name: 'Polygon',
      type: ToolType.Polygon,
      icon: 'hexagon',
    },
    {
      name: 'Ping',
      type: ToolType.Ping,
      icon: 'radio_button_checked',
    },
    {
      name: 'Text',
      type: ToolType.Text,
      icon: 'text_fields',
    },
    {
      name: 'Distanz',
      type: ToolType.Distance,
      icon: 'straighten',
    },
    {
      name: 'Löschen',
      type: ToolType.Remove,
      icon: 'delete',
    },
  ];

  readonly toolColors: string[] = [
    '#303f46', // Grau
    '#469990', // Teal
    '#3cb44b', // Grün
    '#3388ff', // Blau
    '#911eb4', // Violett
    '#e6194b', // Rot
    '#f58231', // Orange
    '#ffcf38', // Gelb
  ];

  // Leaflet Default Settings für gezeichnete Objekte
  private readonly toolDefaultSettings: ToolSettings = {
    color: this.toolColors[3],
    fillOpacity: 0.2,
    weight: 3,
  };

  // Vom Benutzer ausgewählte ToolSettings zwischenspeichern
  private toolCustomSettings: ToolSettings = {
    ...this.toolDefaultSettings,
  };

  // Aktuelle ToolSettings
  private toolSettings: ToolSettings = { ...this.toolCustomSettings };
  toolSettings$ = new BehaviorSubject<ToolSettings>(this.toolSettings);

  // Aktuell ausgewähltes Tool
  selectedTool$ = new BehaviorSubject<Tool>(this.tools[0]);

  constructor(private layerService: LayerService, private logger: LogService, private snackbar: SnackbarService) {
    this.toolSettings$.next(this.toolSettings);
    /**
     * Wenn ausgewähltes Feature ToolSettings hat, Steuerelemente auf diese Settings setzen.
     * Wenn kein Feature ausgewählt oder Feature, das keine Settings hat (z.B. Marker, Distacnce), Default Settings einstellen.
     */
    layerService.currentFeature$.pipe(takeUntilDestroyed()).subscribe((feature) => {
      this.currentFeature = feature;
      if (!feature?.properties) {
        this.resetToolSettings();
        return;
      }

      const featureSettings = feature.properties['style'];
      if (!featureSettings) {
        this.resetToolSettings();
        return;
      }
      if (featureSettings['color']) {
        this.toolSettings.color = featureSettings['color'];
      }
      if (featureSettings['fillOpacity']) {
        this.toolSettings.fillOpacity = featureSettings['fillOpacity'];
      }
      if (featureSettings['weight']) {
        this.toolSettings.weight = featureSettings['weight'];
      }
      this.toolSettings$.next(this.toolSettings);
    });
  }

  /**
   * Überegbenes Werkzeug auswählen und Feature-Auswahl entfernen.
   */
  selectTool(tool: Tool) {
    this.selectedTool$.next(tool);
    this.layerService.setCurrentFeature(null);
    this.setShowToolHint(false);
  }

  /**
   * Gibt an, ob auf ein Infotext zum ausgewählten Werkzeug angezeigt werden soll
   * (z.B. "Werkzeugnutzung mit Rechtsklick abschließen")
   */
  setShowToolHint(showToolHint: boolean) {
    this.showToolHint$.next(showToolHint);
  }

  toggleToolSidebarVisibility() {
    this.toolSidebarVisibility$.next(!this.toolSidebarVisibility$.getValue());
    // Cursor auswählen, wenn Toolbar ausgeblendet wird
    if (!this.toolSidebarVisibility$.getValue()) {
      this.selectTool(this.tools[0]);
    }
    window.dispatchEvent(new Event('resize'));
  }

  /**
   * Tool-Settings auf die zuletzt ausgewählten Settings zurücksetzen.
   */
  resetToolSettings() {
    this.toolSettings = { ...this.toolCustomSettings };
    this.toolSettings$.next(this.toolSettings);
  }

  /**
   * Übergebenes Setting auf einen anderen Wert setzen (z.B. Farbe auf blau) und andere über Änderung informieren.
   * Ist momentan ein Feature ausgewählt, die entsprechende Eigenschaft im Feature anpassen.
   */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  changeToolSetting(setting: string, value: any) {
    // Update ToolSettings
    switch (setting) {
      case 'color':
        this.toolSettings.color = value;
        if (!this.currentFeature) {
          this.toolCustomSettings.color = value;
        }
        break;
      case 'fillOpacity':
        this.toolSettings.fillOpacity = value;
        if (!this.currentFeature) {
          this.toolCustomSettings.fillOpacity = value;
        }
        break;
      case 'weight':
        this.toolSettings.weight = value;
        if (!this.currentFeature) {
          this.toolCustomSettings.weight = value;
        }
        break;
      default:
        this.logger.error(`No key ${setting} in ToolSettings`);
        this.snackbar.showLong('Werkzeug konnte nicht gefunden werden');
        return;
    }
    this.toolSettings$.next(this.toolSettings);

    if (!this.currentFeature?.properties) {
      return;
    }

    // Ggf. Settings des ausgewählten Features anpassen
    const featureStyle = this.currentFeature.properties['style'];
    if (featureStyle) {
      switch (setting) {
        case 'color':
          featureStyle.color = value;
          break;
        case 'fillOpacity':
          featureStyle.fillOpacity = value;
          break;
        case 'weight':
          featureStyle.weight = value;
          break;
      }
      this.currentFeature.properties['style'] = featureStyle;
      this.layerService.featureStyleChanged$.next(this.currentFeature);
    }
  }

  getAllowedToolTypes(taktischesZeichenTyp: TaktischesZeichenTyp) {
    switch (taktischesZeichenTyp) {
      case TaktischesZeichenTyp.Fahrzeug:
      case TaktischesZeichenTyp.Person:
      case TaktischesZeichenTyp.TaktischeFormation:
      case TaktischesZeichenTyp.Personenschaden:
      case TaktischesZeichenTyp.Tierschaden:
      case TaktischesZeichenTyp.Foto:
        return [ToolType.Point];
      case TaktischesZeichenTyp.Stelle:
      case TaktischesZeichenTyp.Befehlsstelle:
      case TaktischesZeichenTyp.Gebaeude:
        return [ToolType.Point, ToolType.Polygon];
      case TaktischesZeichenTyp.Gefahr:
      case TaktischesZeichenTyp.Massnahme:
      case TaktischesZeichenTyp.Anlass:
        return [ToolType.Point, ToolType.Polygon, ToolType.Line];
      case TaktischesZeichenTyp.Gebiet:
        return [ToolType.Polygon];
      default:
        return [];
    }
  }
}
