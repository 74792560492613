import { CommonModule } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ErrorService } from '@product/ise-error-lib';
import { catchError, EMPTY } from 'rxjs';
import { LagebesprechungDTO } from 'src/app/api/build/openapi/model/lagebesprechungDTO';
import { DatetimeLocalAccessorDirective } from 'src/app/shared/accessors/datetime-local-accessor.directive';
import { LagebesprechungService } from '../lagebesprechung.service';

export interface LabebesprechungDialogData {
  lagebesprechung: LagebesprechungDTO;
}

interface NotificationTime {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-lagebesprechung-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatSelectModule,
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatTooltipModule,
    DatetimeLocalAccessorDirective,
    MatCheckboxModule,
  ],
  templateUrl: './lagebesprechung-dialog.component.html',
  styleUrls: ['./lagebesprechung-dialog.component.scss'],
})
export class LagebesprechungDialogComponent {
  private currentLagebesprechung: LagebesprechungDTO | undefined;
  private errorService = inject(ErrorService);

  protected isSaving = false;

  reminderOptions: NotificationTime[] = [
    { value: 0, viewValue: 'keine' },
    { value: 5, viewValue: '5 Minuten vorher' },
    { value: 10, viewValue: '10 Minuten vorher' },
    { value: 20, viewValue: '20 Minuten vorher' },
    { value: 30, viewValue: '30 Minuten vorher' },
  ];

  notification = new FormControl<NotificationTime>(this.reminderOptions[0], { nonNullable: true });

  time = new FormControl<Date | null>(new Date(), { nonNullable: true });
  erledigt = new FormControl<boolean>(false, { nonNullable: true });
  folgebesprechung = new FormControl<boolean>(false, { nonNullable: true });
  naechsteBesprechung = new FormControl<number | undefined>(60, { nonNullable: true });

  formGroup = new FormGroup({
    time: this.time,
    notification: this.notification,
    erledigt: this.erledigt,
    folgebesprechung: this.folgebesprechung,
    naechsteBesprechung: this.naechsteBesprechung,
  });

  constructor(
    private lagebesprechungService: LagebesprechungService,
    private dialogRef: MatDialogRef<LagebesprechungDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: LabebesprechungDialogData
  ) {
    if (data.lagebesprechung) {
      this.currentLagebesprechung = data.lagebesprechung;

      this.time.setValue(
        this.currentLagebesprechung.zeitpunkt ? new Date(this.currentLagebesprechung.zeitpunkt) : new Date()
      );

      if (this.currentLagebesprechung.erinnerungInMinuten) {
        this.notification.setValue(
          this.reminderOptions.find((option) => option.value === this.currentLagebesprechung?.erinnerungInMinuten) ||
            this.reminderOptions[0]
        );
      }

      this.erledigt.setValue(this.currentLagebesprechung.status === LagebesprechungDTO.StatusEnum.Erledigt);

      if (this.currentLagebesprechung.naechsteAnlegenInMinuten) {
        this.folgebesprechung.setValue(true);
        this.naechsteBesprechung.setValue(this.currentLagebesprechung.naechsteAnlegenInMinuten);
      }
    }
  }

  cancel() {
    const ref = this.lagebesprechungService.cancelLagebesprechung(this.toDto()).subscribe(() => {
      this.dialogRef.close();
      ref.unsubscribe();
    });
  }

  submit() {
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    const ref = this.lagebesprechungService
      .createOrUpdateLagebesprechung(this.toDto())
      .pipe(
        catchError((error) => {
          this.errorService.showError(error.error);
          this.isSaving = false;
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.dialogRef.close();
        ref.unsubscribe();
      });
  }

  private toDto(): LagebesprechungDTO {
    return {
      ...this.currentLagebesprechung,
      lageId: this.currentLagebesprechung?.lageId ? this.currentLagebesprechung.lageId : '',
      id: this.currentLagebesprechung?.id ? this.currentLagebesprechung.id : '',
      status: this.erledigt.value ? LagebesprechungDTO.StatusEnum.Erledigt : LagebesprechungDTO.StatusEnum.Anstehend,
      zeitpunkt: this.time.value ? this.time.value.toISOString() : undefined,
      erinnerungInMinuten: this.notification.value.value,
      naechsteAnlegenInMinuten: this.folgebesprechung.value ? this.naechsteBesprechung.value : 0,
    };
  }
}
