<mat-toolbar class="toolbar">
  <h2>Verschieben nach ...</h2>
</mat-toolbar>

<mat-dialog-content class="content">
  <app-verwaltungsebenen-tree
    [verwaltungsebenen]="verwaltungsebenen$ | async"
    [expandTree]="true"
    [enableSelection]="true"
    (verwaltungsebeneSelected)="selectVerwaltungsebene($event)"
  />
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
</mat-dialog-actions>
-
