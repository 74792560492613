import { createReducer, on } from '@ngrx/store';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { PersonenschadenStateInterface } from './personenschaden-state.interface';
import { personenschadenActions } from './personenschaden.actions';

export const initialState: PersonenschadenStateInterface = {
  isCreating: false,
  isLoading: false,
  isPatching: false,
  personenschaeden: [],
  loadedLageId: '',
};

export const personenschadenReducer = createReducer(
  initialState,

  // Reset Store
  on(personenschadenActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get Personenschaeden
  on(personenschadenActions.getPersonenschaeden, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(personenschadenActions.getPersonenschaedenSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    personenschaeden: action.personenschadenDTOs,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(personenschadenActions.getPersonenschaedenFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create Personenschaden
  on(personenschadenActions.createPersonenschaden, (state) => ({
    ...state,
    isCreating: true,
    errorResponse: undefined,
  })),
  on(personenschadenActions.createPersonenschadenSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    personenschaeden: [...state.personenschaeden, action.createdPersonenschadenDTO],
    errorResponse: undefined,
  })),
  on(personenschadenActions.createPersonenschadenFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Personenschaden
  on(personenschadenActions.patchPersonenschaden, (state) => ({
    ...state,
    isPatching: true,
    errorResponse: undefined,
  })),
  on(personenschadenActions.patchPersonenschadenSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    personenschaeden: replaceOrKeepCurrent(state.personenschaeden, action.patchedPersonenschadenDTO),
    errorResponse: undefined,
  })),
  on(personenschadenActions.patchPersonenschadenFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Personenschaden
  on(personenschadenActions.deletePersonenschadenSuccess, (state, action) => ({
    ...state,
    personenschaeden: state.personenschaeden.filter((person) => person.id !== action.deletedPersonenschadenDTO.id),
  }))
);
