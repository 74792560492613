import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FuehrungsebeneDisplayService } from '../fuehrungsebene-display.service';

/**
 * Zeigt an, wie viele und welche direkten Children eine Führungsebene hat und
 * wie viele davon links und rechts neben der Karte dargestellt werden.
 */
@Component({
  selector: 'app-fuehrungsebene-child-counter',
  standalone: true,
  imports: [NgIf, AsyncPipe],
  templateUrl: './fuehrungsebene-child-counter.component.html',
  styleUrl: './fuehrungsebene-child-counter.component.scss',
})
export class FuehrungsebeneChildCounterComponent {
  fuehrungsebeneDisplayService = inject(FuehrungsebeneDisplayService);
  headers$ = this.fuehrungsebeneDisplayService.headers$;
}
