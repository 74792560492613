import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { FuehrungsebeneDTO, Fuehrungsebenentyp } from 'src/app/api/build/openapi';
import { childFuehrungsebenenSelector, currentFuehrungsebeneSelector } from './+state/fuehrungsebene.selectors';

@Injectable({
  providedIn: 'root',
})
export class FuehrungsebeneDisplayService {
  readonly LOCAL_STORAGE_KEY = 'displayedFuehrungsebenen';
  readonly MAX_DISPLAYED_FUEHRUNGSEBENEN_COUNT = 6;
  private selectedFuehrungsebenen$ = new BehaviorSubject<Map<string, string[]>>(new Map<string, string[]>());

  /**
   * Angezeigte Führungsebenen ergeben sich aus dem currentFuehrungsebene und ob der Benutzer aktiv Child-Führungsebenen zur Anzeige selektiert hat.
   * Hat er keine Führungsebenen selektiert, werden bis zu 6 Child-Führungsebenen angezeigt. Andernfalls wird seine Auswahl angezeigt.
   */
  displayedFuehrungsebenen$ = new BehaviorSubject<FuehrungsebeneDTO[]>([]);
  headers$ = new BehaviorSubject<string[]>([]);

  constructor(store: Store<AppStateInterface>) {
    combineLatest([
      store.select(currentFuehrungsebeneSelector),
      this.selectedFuehrungsebenen$,
      store.select(childFuehrungsebenenSelector),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(([currentFuehrungsebene, selectedFuehrungsebenenMap, childFuehrungsebenen]) => {
        // Wenn keine Führungsebenen vorhanden, kann nichts angezeigt werden
        if (!currentFuehrungsebene?.id) {
          this.displayedFuehrungsebenen$.next([]);
          return;
        }

        // Skippen, wenn Children nicht zum Parent passen
        if (
          childFuehrungsebenen.length &&
          childFuehrungsebenen[0].parentFuehrungsebeneId !== currentFuehrungsebene.id
        ) {
          this.displayedFuehrungsebenen$.next([]);
          return;
        }

        const selectedFuehrungsebeneIds = this.getSelectedFuehrungsebeneIds(currentFuehrungsebene.id);
        if (!selectedFuehrungsebeneIds) {
          this.prepareDisplayedChildrenWithoutSelection(childFuehrungsebenen);
        } else {
          this.prepareDisplayedChildrenWithSelection(childFuehrungsebenen, selectedFuehrungsebeneIds);
        }
        this.generateHeaders(currentFuehrungsebene, childFuehrungsebenen, this.displayedFuehrungsebenen$.value);
      });

    const displayedFuehrungsebenen = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (!displayedFuehrungsebenen) {
      return;
    }

    this.selectedFuehrungsebenen$.next(new Map(JSON.parse(displayedFuehrungsebenen)));
  }

  /**
   * Wenn Benutzer explizit Führungsebenen für die Anzeige ausgewählt hat, diese anzeigen.
   *
   */
  prepareDisplayedChildrenWithSelection(
    childFuehrungsebenen: FuehrungsebeneDTO[],
    selectedFuehrungsebeneIds: string[]
  ) {
    let displayedFuehrungsebenen = childFuehrungsebenen;
    if (selectedFuehrungsebeneIds) {
      displayedFuehrungsebenen = displayedFuehrungsebenen.filter(
        (childFuehrungsebene) => childFuehrungsebene.id && selectedFuehrungsebeneIds.includes(childFuehrungsebene.id)
      );
    }
    displayedFuehrungsebenen = displayedFuehrungsebenen.slice(
      0,
      Math.min(childFuehrungsebenen.length, this.MAX_DISPLAYED_FUEHRUNGSEBENEN_COUNT)
    );
    this.displayedFuehrungsebenen$.next(displayedFuehrungsebenen);
  }

  /**
   * Wenn Benutzer keine Führungsebenen ausgewählt hat, die ersten (maximal 6) Führungsebenen anzeigen.
   */
  prepareDisplayedChildrenWithoutSelection(childFuehrungsebenen: FuehrungsebeneDTO[]) {
    this.displayedFuehrungsebenen$.next(
      childFuehrungsebenen.slice(0, Math.min(childFuehrungsebenen.length, this.MAX_DISPLAYED_FUEHRUNGSEBENEN_COUNT))
    );
  }

  /**
   * Je nach CurrentFuehrungsebene wird angezeigt, welche und wie viele Child-Führungsebenen links und rechts neben der Karte dargestellt werden.
   */
  private generateHeaders(
    currentFuehrungsebene: FuehrungsebeneDTO,
    childFuehrungsebenen: FuehrungsebeneDTO[],
    displayedFuehrungsebenen: FuehrungsebeneDTO[]
  ) {
    const headers: string[] = [];
    switch (currentFuehrungsebene.typ) {
      case Fuehrungsebenentyp.Schadengebiet: {
        const einsatzraumChildrenDisplayedCount = displayedFuehrungsebenen.filter(
          (child) => child.typ === Fuehrungsebenentyp.Einsatzraum
        ).length;
        const einsatzraumChildrenTotalCount = childFuehrungsebenen.filter(
          (child) => child.typ === Fuehrungsebenentyp.Einsatzraum
        ).length;
        const einsatzstellenChildredDisplayedCount = displayedFuehrungsebenen.filter(
          (child) =>
            child.typ === Fuehrungsebenentyp.Einsatzstelle || child.typ === Fuehrungsebenentyp.LogistikEinsatzstelle
        ).length;
        const einsatzstellenChildredTotalCount = childFuehrungsebenen.filter(
          (child) =>
            child.typ === Fuehrungsebenentyp.Einsatzstelle || child.typ === Fuehrungsebenentyp.LogistikEinsatzstelle
        ).length;
        headers.push(`Einsatzräume: (${einsatzraumChildrenDisplayedCount}/${einsatzraumChildrenTotalCount})`);
        headers.push(`Einsatzstellen: (${einsatzstellenChildredDisplayedCount}/${einsatzstellenChildredTotalCount})`);
        break;
      }
      case Fuehrungsebenentyp.Einsatzraum: {
        const einsatzstellenChildredDisplayedCount = displayedFuehrungsebenen.filter(
          (child) =>
            child.typ === Fuehrungsebenentyp.Einsatzstelle || child.typ === Fuehrungsebenentyp.LogistikEinsatzstelle
        ).length;
        const einsatzstellenChildredTotalCount = childFuehrungsebenen.filter(
          (child) =>
            child.typ === Fuehrungsebenentyp.Einsatzstelle || child.typ === Fuehrungsebenentyp.LogistikEinsatzstelle
        ).length;
        headers.push(`Einsatzstellen: (${einsatzstellenChildredDisplayedCount}/${einsatzstellenChildredTotalCount})`);
        break;
      }
      case Fuehrungsebenentyp.Einsatzstelle:
      case Fuehrungsebenentyp.LogistikEinsatzstelle: {
        const einsatzabschnittChildrenDisplayedCount = displayedFuehrungsebenen.filter(
          (child) =>
            child.typ === Fuehrungsebenentyp.Einsatzabschnitt ||
            child.typ === Fuehrungsebenentyp.LogistikEinsatzabschnitt
        ).length;
        const einsatzabschnittChildrenTotalCount = childFuehrungsebenen.filter(
          (child) =>
            child.typ === Fuehrungsebenentyp.Einsatzabschnitt ||
            child.typ === Fuehrungsebenentyp.LogistikEinsatzabschnitt
        ).length;
        headers.push(
          `Einsatzabschnitte: (${einsatzabschnittChildrenDisplayedCount}/${einsatzabschnittChildrenTotalCount})`
        );
        break;
      }
      case Fuehrungsebenentyp.Einsatzabschnitt:
      case Fuehrungsebenentyp.LogistikEinsatzabschnitt: {
        const unterabschnittChildrenDisplayedCount = displayedFuehrungsebenen.filter(
          (child) =>
            child.typ === Fuehrungsebenentyp.Unterabschnitt ||
            child.typ === Fuehrungsebenentyp.LogistikEinsatzabschnittChild
        ).length;
        const unterabschnittChildrenTotalCount = childFuehrungsebenen.filter(
          (child) =>
            child.typ === Fuehrungsebenentyp.Unterabschnitt ||
            child.typ === Fuehrungsebenentyp.LogistikEinsatzabschnittChild
        ).length;
        headers.push(`Unterabschnitte: (${unterabschnittChildrenDisplayedCount}/${unterabschnittChildrenTotalCount})`);
        break;
      }
    }
    this.headers$.next(headers);
  }

  /**
   * Liefert eine Liste anzuzeigender Führungsebenen für eine Parent-Führungsebene
   */
  getSelectedFuehrungsebeneIds(parentFuehrungsebeneId: string): string[] | undefined {
    return this.selectedFuehrungsebenen$.value.get(parentFuehrungsebeneId);
  }

  /**
   * Liest alle anzuzeigenden Führungsebenen aus dem LocalStorage
   */
  readSelectedFuehrungsebenen(): void {
    const displayedFuehrungsebenen = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (displayedFuehrungsebenen) {
      this.selectedFuehrungsebenen$.next(new Map(JSON.parse(displayedFuehrungsebenen)));
    } else {
      this.selectedFuehrungsebenen$.next(new Map());
    }
  }

  /**
   * Speichert die übergebene Liste anzuzeigender Child-Führungsebenen für eine Parent-Führungsebene im LocalStorage
   */
  saveSelectedFuehrungsebenen(parentFuehrungsebeneId: string, selectedFuehrungsebeneIds: string[]): void {
    const mapCopy = new Map(this.selectedFuehrungsebenen$.value);
    mapCopy.set(parentFuehrungsebeneId, selectedFuehrungsebeneIds);

    this.selectedFuehrungsebenen$.next(mapCopy);
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(Array.from(mapCopy.entries())));
  }
}
