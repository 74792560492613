import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { FahrzeugDTO, FuehrungsebeneDTO } from 'src/app/api/build/openapi';
import { AuftragHeaderElementComponent } from 'src/app/lagedarstellung/auftrag/auftrag-header-element/auftrag-header-element.component';
import { FuehrungsebeneIconAndTextComponent } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { KontaktIconsComponent } from 'src/app/lagedarstellung/kontakt/kontakt-icons/kontakt-icons.component';
import { IconAndTextComponent } from 'src/app/shared/components/icon-and-text/icon-and-text.component';
import { DateTimePipe } from 'src/app/shared/pipes/date-time.pipe';
import { TaktischesZeichenPopup } from '../../taktische-zeichen.interface';
import { TaktischesZeichenStatusPipe } from '../../taktisches-zeichen-status.pipe';
import { TzDialogService } from '../../tz-dialog.service';
import { TzPopupToolbarComponent } from '../../tz-popup-toolbar/tz-popup-toolbar.component';

@Component({
  selector: 'app-fahrzeug-popup',
  standalone: true,
  imports: [
    CommonModule,
    KontaktIconsComponent,
    AuftragHeaderElementComponent,
    DateTimePipe,
    IconAndTextComponent,
    FuehrungsebeneIconAndTextComponent,
    TzPopupToolbarComponent,
    TaktischesZeichenStatusPipe,
  ],
  templateUrl: './fahrzeug-popup.component.html',
  styleUrls: ['./fahrzeug-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FahrzeugPopupComponent implements OnInit, TaktischesZeichenPopup {
  @Input({ required: true }) dto!: FahrzeugDTO;

  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private tzDialogService = inject(TzDialogService);

  protected parentFuehrungsebene?: FuehrungsebeneDTO;

  ngOnInit(): void {
    this.parentFuehrungsebene = this.fuehrungsebeneService
      .getAllFuehrungsebenen()
      .find((fuehrungsebeneDTO) => fuehrungsebeneDTO.id === this.dto.fuehrungsebeneId);
  }

  moveDto() {
    this.tzDialogService.openTzMoveDialog([this.dto], false);
  }

  editDto() {
    this.tzDialogService.openEditTzDialog(this.dto);
  }
}
