import { NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { FuehrungsebeneNestedDTO } from 'src/app/api/build/openapi';
import { FuehrungsebeneIconAndTextComponent } from '../fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService } from '../fuehrungsebene.service';

/**
 * Input zum Hereingeben der Führungsebenen und der ggf. vorselektierten Führungsebene.
 */
export interface FuehrungsebeneTreeNestedDialogInputData {
  treeData: FuehrungsebeneNestedDTO;
  selectedFuehrungsebeneId?: string;
  headerText?: string;
}

/**
 * Output zum Zurückgeben der ausgewählten Führungsebene.
 */
export interface FuehrungsebeneTreeNestedDialogOutputData {
  selectedFuehrungsebeneId: string;
}

/**
 * Dialog mit dem ein bestimmten Führungsebene aus allen Führungsebenen ausgewählt werden kann.
 *
 * Stellt alle Führungsebenen in einem Baum dar und markiert ggf. eine Führungsebene als "aktuelle" Führungsebene.
 * Durch Klick auf eine Führungsebene wird der Dialog geschlossen und die angeklickte Führungsebene wird zurückgeliefert.
 */
@Component({
  selector: 'app-fuehrungsebene-tree-select-nested-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatTreeModule,
    MatIconModule,
    FuehrungsebeneIconAndTextComponent,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './fuehrungsebene-tree-select-nested-dialog.component.html',
  styleUrls: ['./fuehrungsebene-tree-select-nested-dialog.component.scss'],
})
export class FuehrungsebeneTreeSelectNestedDialogComponent {
  dialogInputData: FuehrungsebeneTreeNestedDialogInputData = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef<FuehrungsebeneTreeSelectNestedDialogComponent>);

  treeControl = new NestedTreeControl<FuehrungsebeneNestedDTO>((node) => node.fuehrungsebeneDTOs);
  dataSource = new MatTreeNestedDataSource<FuehrungsebeneNestedDTO>();
  selectedFuehrungsebeneId?: string;
  headerText = 'Führungsebene auswählen';

  protected expandNavTree = true;
  protected fuehrungsebeneService = inject(FuehrungsebeneService);

  constructor() {
    if (!this.dialogInputData) {
      return;
    }
    // Input Daten verarbeiten
    this.selectedFuehrungsebeneId = this.dialogInputData.selectedFuehrungsebeneId;
    this.dataSource.data = [this.dialogInputData.treeData];
    this.headerText = this.dialogInputData.headerText || this.headerText;

    // Baum komplett ausklappen
    if (this.dataSource.data.length) {
      this.treeControl.expandDescendants(this.dataSource.data[0]);
    }
  }

  selectFuehrungsebene(fuehrungsebeneDTO: FuehrungsebeneNestedDTO) {
    if (fuehrungsebeneDTO.id === this.selectedFuehrungsebeneId) {
      return;
    }

    const outputdata: FuehrungsebeneTreeNestedDialogOutputData = {
      selectedFuehrungsebeneId: fuehrungsebeneDTO.id,
    };
    this.dialogRef.close(outputdata);
  }

  hasChild = (_: number, node: FuehrungsebeneNestedDTO) => node.fuehrungsebeneDTOs.length;

  toggleTree() {
    this.expandNavTree = !this.expandNavTree;
    if (this.expandNavTree) {
      this.treeControl.expandDescendants(this.dataSource.data[0]);
    } else {
      this.treeControl.collapseAll();
    }
  }
}
