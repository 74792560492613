import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';

/**
 * Wenn die Bibliothek im "Selektionsmodus" ist, sammelt dieser Service die selektierten Zeichen aus der Bibliothek.
 */
@Injectable({
  providedIn: 'root',
})
export class TzMultiSelectService {
  private selectedTaktischeZeichen$ = new BehaviorSubject<TaktischesZeichenDTO[]>([]);

  toggleSelection(zeichen: TaktischesZeichenDTO) {
    if (this.isSelected(zeichen)) {
      this.remove(zeichen);
    } else {
      this.add(zeichen);
    }
  }

  isSelected(zeichen: TaktischesZeichenDTO): boolean {
    return this.selectedTaktischeZeichen$.value.some((v) => zeichen === v);
  }

  add(tzDto: TaktischesZeichenDTO) {
    if (this.isSelected(tzDto)) {
      console.warn('Das Zeichen ist bereits ausgewählt.');
      return;
    }

    // Auswahl aufheben, wenn TZs unterschiedlicher Führungsebenen gewählt werden
    if (this.hasSelection()) {
      const firstSelectedTz = this.selectedTaktischeZeichen$.value[0];
      const firstFuehrungsebeneId = firstSelectedTz.fuehrungsebeneId;

      if (firstFuehrungsebeneId !== tzDto.fuehrungsebeneId) {
        this.clearSelection();
      }
    }

    const current = this.selectedTaktischeZeichen$.value;
    this.selectedTaktischeZeichen$.next([...current, tzDto]);
  }

  remove(tzDto: TaktischesZeichenDTO) {
    if (!this.isSelected(tzDto)) {
      console.warn('Zeichen kann nicht gelöscht werden, da es sich nicht in der Auswahl befindet');
      return;
    }
    this.selectedTaktischeZeichen$.next(this.selectedTaktischeZeichen$.value.filter((z) => tzDto !== z));
  }

  clearSelection() {
    this.selectedTaktischeZeichen$.next([]);
  }

  getSelection() {
    return [...this.selectedTaktischeZeichen$.value];
  }

  hasSelection() {
    return this.selectedTaktischeZeichen$.value.length;
  }

  hasMultipleSelections() {
    return this.selectedTaktischeZeichen$.value.length > 1;
  }
}
