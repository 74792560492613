import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, GefahrDTO } from 'src/app/api/build/openapi';

export const gefahrActions = createActionGroup({
  source: 'Gefahren',
  events: {
    'Reset Store': emptyProps(),

    'Get Gefahren': props<{ lageId: string }>(),
    'Get Gefahren Success': props<{ gefahrDTOs: GefahrDTO[]; lageId: string }>(),
    'Get Gefahren Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Gefahr': props<{ lageId: string; gefahrDTO: GefahrDTO }>(),
    'Create Gefahr Success': props<{ createdGefahrDTO: GefahrDTO }>(),
    'Create Gefahr Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Gefahr': props<{ lageId: string; gefahrDTO: GefahrDTO }>(),
    'Patch Gefahr Success': props<{ patchedGefahrDTO: GefahrDTO }>(),
    'Patch Gefahr Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Gefahr Success': props<{ deletedGefahrDTO: GefahrDTO }>(),
  },
});
