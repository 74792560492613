<mat-toolbar>
  <h2>{{ tzDescription }} {{ deleteVerb }}</h2>
</mat-toolbar>

<div class="content">
  <p>{{ tzDescription }} sicher {{ deleteVerb }}? Die Aktion kann nicht rückgängig gemacht werden.</p>
  @if (containsFormationen) {
  <mat-checkbox [formControl]="fcRecursive"
    >Untereinheiten ausgewählter Taktischer Formationen ebenfalls {{ deleteVerb }}</mat-checkbox
  >
  <p class="error">Wenn sich in den Untereinheiten Vorlagen befinden, könnten diese dadurch gelöscht werden!</p>
  }
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="delete()">{{ deleteVerbCapitalized }}</button>
</mat-dialog-actions>
