<mat-expansion-panel class="flex-grow" [expanded]="expand">
  <mat-expansion-panel-header class="expansion-panel-header">
    <div class="header">
      <h2>{{ verwaltungsebene.name }}</h2>
      &nbsp;&nbsp;|&nbsp;&nbsp;
      <h3
        *ngIf="verwaltungsebene.verwaltungsebene; let v"
        [matTooltip]="'Verwaltungsebene: ' + verwaltungsebenenMap.get(v)"
      >
        {{ verwaltungsebenenMap.get(v) }}
      </h3>
      <ng-container *ngIf="hasKommunikationsOptionen()">
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <mat-icon
          *ngFor="let kommunikation of verwaltungsebene.kommunikationOptionen"
          [matTooltip]="summarizeKommunikation(kommunikation)"
          >{{ getKommunikationIcon(kommunikation) }}
        </mat-icon>
      </ng-container>
    </div>

    <button *ngIf="!selectionMode" mat-icon-button (click)="createTz($event)" matTooltip="Taktisches Zeichen anlegen">
      <mat-icon>add</mat-icon>
    </button>
    <button *ngIf="!selectionMode" mat-icon-button (click)="edit($event)" matTooltip="Verwaltungsebene bearbeiten">
      <mat-icon>edit</mat-icon>
    </button>
  </mat-expansion-panel-header>

  <mat-card-content class="scrollable content flex-col zeichen-container" (click)="clearSelection($event)">
    <ng-container *ngFor="let fahrzeug of fahrzeuge">
      <ng-container *ngTemplateOutlet="zeichen; context: { zeichen: fahrzeug }" />
    </ng-container>

    <ng-container *ngFor="let person of personen">
      <ng-container *ngTemplateOutlet="zeichen; context: { zeichen: person }" />
    </ng-container>

    <ng-container *ngFor="let taktischeFormation of taktischeFormationen">
      <ng-container *ngTemplateOutlet="zeichen; context: { zeichen: taktischeFormation }" />
    </ng-container>

    <h3 class="keine-daten-text" *ngIf="!fahrzeuge.length && !personen.length && !taktischeFormationen.length">
      Noch keine Taktischen Zeichen vorhanden
    </h3>
  </mat-card-content>

  <ng-container *ngIf="verwaltungsebene.verwaltungsebenen?.length">
    <mat-accordion class="example-headers-align" multi>
      <app-verwaltungsebene-details
        *ngFor="let verwaltungsebene of verwaltungsebene.verwaltungsebenen"
        [verwaltungsebene]="verwaltungsebene"
        [expandChildren]="expandChildren"
        [selectionMode]="selectionMode"
        [bibliothekFilter$]="bibliothekFilter$"
      />
    </mat-accordion>
  </ng-container>
</mat-expansion-panel>

<!-- Template für einzelne Taktisch Zeichen -->
<ng-template #zeichen let-zeichen="zeichen">
  <app-taktische-zeichen-item
    class="zeichen"
    [ngClass]="{ 'has-lage': hasLage(zeichen) }"
    [item]="zeichen"
    (click)="toggleSelection(zeichen, $event)"
    [selected]="isSelected(zeichen)"
    [matTooltip]="getTooltip(zeichen)"
    [useDefaultTooltip]="false"
    (tzClick)="selectTz($event)"
    (tzContextmenu)="openTzBubbleMenu($event)"
  />
</ng-template>
