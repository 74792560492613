import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, throwError } from 'rxjs';
import { BibliothekResourceService, ErrorResponse, VerwaltungsebenenHierarchyDTO } from 'src/app/api/build/openapi';
import { bibliothekActions } from './bibliothek.actions';

@Injectable()
export class BibliothekEffects {
  // Alle Verwaltungsebenen holen
  getVerwaltungsebenen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bibliothekActions.getVerwaltungsebenen),
      switchMap(() =>
        this.bibliothekResourceService.getParentVerwaltungsebenen().pipe(
          map((verwaltungsebenen) => bibliothekActions.getVerwaltungsebenenSuccess({ verwaltungsebenen })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(bibliothekActions.getVerwaltungsebenenFailure({ errorResponse }));
          })
        )
      )
    )
  );

  // Neue Verwaltungsebene anlegen
  createVerwaltungsebenen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bibliothekActions.createVerwaltungsebene),
      switchMap((props) =>
        this.bibliothekResourceService.createVerwaltungsebene(props.verwaltungsebene).pipe(
          map((newVerwaltungsebenenHierarchy) =>
            bibliothekActions.createVerwaltungsebeneSuccess({ newVerwaltungsebenenHierarchy })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(bibliothekActions.createVerwaltungsebeneFailure({ errorResponse }));
          })
        )
      )
    )
  );

  // Verwaltungsebene aktualisieren
  patchVerwaltungsebenen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bibliothekActions.patchVerwaltungsebene),
      switchMap((props) => {
        if (!props.verwaltungsebene.id) {
          return throwError(() => new Error('Verwaltungsebene muss eine Id besitzen'));
        }
        return this.bibliothekResourceService.patchVerwaltungsebene(props.verwaltungsebene).pipe(
          map((newHirarchy: VerwaltungsebenenHierarchyDTO) =>
            bibliothekActions.patchVerwaltungsebeneSuccess({ newVerwaltungsebenenHierarchy: newHirarchy })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(bibliothekActions.patchVerwaltungsebeneFailure({ errorResponse }));
          })
        );
      })
    )
  );

  moveVerwaltungsebene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bibliothekActions.moveVerwaltungsebene),
      switchMap((props) => {
        if (!props.verwaltungsebeneMoveDTO) {
          return throwError(() => new Error('Keine Verwaltungsebene angegeben'));
        }
        return this.bibliothekResourceService.moveVerwaltungsebene(props.verwaltungsebeneMoveDTO).pipe(
          map((newVerwaltungsebenenHierarchy: VerwaltungsebenenHierarchyDTO) =>
            bibliothekActions.moveVerwaltungsebeneSuccess({ newVerwaltungsebenenHierarchy })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(bibliothekActions.moveVerwaltungsebeneFailure({ errorResponse }));
          })
        );
      })
    )
  );

  // Verwaltungseben löschen
  deleteVerwaltungsebenen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bibliothekActions.deleteVerwaltungsebene),
      switchMap((props) =>
        this.bibliothekResourceService.deleteVerwaltungsebene(props.verwaltungsebeneId).pipe(
          map((deletedVerwaltungsebene) =>
            bibliothekActions.deleteVerwaltungsebeneSuccess({ deletedVerwaltungsebene })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(
              bibliothekActions.deleteVerwaltungsebeneFailure({
                errorResponse,
              })
            );
          })
        )
      )
    )
  );

  // #########################################
  // Fahrzeuge
  // #########################################

  // Fahrzeuge mit Verwaltungsebenen
  getFahrzeugeWithVerwaltungsebenen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bibliothekActions.getFahrzeugeWithVerwaltungsebene),
      switchMap(() =>
        this.bibliothekResourceService.getBibliothekFahrzeugeWithVerwaltungsebene().pipe(
          map((fahrzeuge) => bibliothekActions.getFahrzeugeWithVerwaltungsebeneSuccess({ fahrzeuge })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(
              bibliothekActions.getFahrzeugeWithVerwaltungsebeneFailure({
                errorResponse,
              })
            );
          })
        )
      )
    )
  );

  createFahrzeug$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bibliothekActions.createFahrzeug),
      mergeMap((props) =>
        this.bibliothekResourceService.createBibliothekFahrzeug(props.fahrzeug).pipe(
          map((newFahrzeug) => bibliothekActions.createFahrzeugSuccess({ newFahrzeug })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(bibliothekActions.createFahrzeugFailure({ errorResponse }));
          })
        )
      )
    )
  );

  patchFahrzeug$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bibliothekActions.patchFahrzeug),
      mergeMap((props) =>
        this.bibliothekResourceService.patchBibliothekFahrzeug(props.fahrzeugDTO).pipe(
          map((patchedFahrzeug) => bibliothekActions.patchFahrzeugSuccess({ patchedFahrzeug })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(bibliothekActions.patchFahrzeugFailure({ errorResponse }));
          })
        )
      )
    )
  );

  // #########################################
  // Personen
  // #########################################

  // Personen mit Verwaltungsebenen holen
  getPersonenWithVerwaltungsebenen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bibliothekActions.getPersonenWithVerwaltungsebene),
      switchMap(() =>
        this.bibliothekResourceService.getBibliothekPersonenWithVerwaltungsebene().pipe(
          map((personen) => bibliothekActions.getPersonenWithVerwaltungsebeneSuccess({ personen })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(
              bibliothekActions.getPersonenWithVerwaltungsebeneFailure({
                errorResponse,
              })
            );
          })
        )
      )
    )
  );

  createPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bibliothekActions.createPerson),
      mergeMap((props) =>
        this.bibliothekResourceService.createBibliothekPerson(props.person).pipe(
          map((newPerson) => bibliothekActions.createPersonSuccess({ newPerson })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(bibliothekActions.createPersonFailure({ errorResponse }));
          })
        )
      )
    )
  );

  patchPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bibliothekActions.patchPerson),
      mergeMap((props) =>
        this.bibliothekResourceService.patchBibliothekPerson(props.personId, props.personDTO).pipe(
          map((patchedPerson) => bibliothekActions.patchPersonSuccess({ patchedPerson })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(bibliothekActions.patchPersonFailure({ errorResponse }));
          })
        )
      )
    )
  );

  // #########################################
  // TaktischeFormationen
  // #########################################

  // TaktischeFormationen mit Verwaltungsebenen holen
  getTaktischeFormationenWithVerwaltungsebenen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bibliothekActions.getTaktischeFormationenWithVerwaltungsebene),
      switchMap(() =>
        this.bibliothekResourceService.getBibliothekTaktischeFormationenWithVerwaltungsebene().pipe(
          map((taktischeFormationen) =>
            bibliothekActions.getTaktischeFormationenWithVerwaltungsebeneSuccess({ taktischeFormationen })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(
              bibliothekActions.getTaktischeFormationenWithVerwaltungsebeneFailure({
                errorResponse,
              })
            );
          })
        )
      )
    )
  );

  createTaktischeFormation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bibliothekActions.createTaktischeFormation),
      mergeMap((props) =>
        this.bibliothekResourceService.createBibliothekTaktischeFormation(props.taktischeFormation).pipe(
          map((newTaktischeFormation) => bibliothekActions.createTaktischeFormationSuccess({ newTaktischeFormation })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(bibliothekActions.createTaktischeFormationFailure({ errorResponse }));
          })
        )
      )
    )
  );

  patchTaktischeFormation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bibliothekActions.patchTaktischeFormation),
      mergeMap((props) =>
        this.bibliothekResourceService
          .patchBibliothekTaktischeFormation(props.taktischeFormationId, props.taktischeFormationDTO)
          .pipe(
            map((patchedTaktischeFormation) =>
              bibliothekActions.patchTaktischeFormationSuccess({ patchedTaktischeFormation })
            ),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(bibliothekActions.patchTaktischeFormationFailure({ errorResponse }));
            })
          )
      )
    )
  );

  constructor(private actions$: Actions, private bibliothekResourceService: BibliothekResourceService) {}
}
