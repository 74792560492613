<!-- Toolbar mit Titel und Buttons zum Anlegen/Editieren von Lagen-->
<app-lage-toolbar (toolbarEvent)="handleToolbarEvent($event)" [itemSelected]="!!(currentLage$ | async)" />

<!-- Tabelle mit allen Lagen und Buttons zum Wechseln zu Meldungen oder Lagedarstellung-->

<app-loadable [isLoading$]="isLoading$" *ngIf="lagenSorted">
  <table
    #lageTable
    mat-table
    [dataSource]="lagenSorted"
    matSort
    (matSortChange)="sortData($event)"
    [matSortDisableClear]="true"
  >
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
      <td mat-cell *matCellDef="let lage">
        @if(lage.lageStatus === LageStatus.Beendet){
        <mat-icon matTooltip="Lage ist beendet">lock</mat-icon>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="startedOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="startedOn">Beginn</th>
      <td mat-cell *matCellDef="let lage">
        {{ lage.startedOn | dateTime | async }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</th>
      <td mat-cell *matCellDef="let lage">
        {{ lage.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="adresse">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="adresse">Ort</th>
      <td mat-cell *matCellDef="let lage">
        {{ lage.adresse }}
      </td>
    </ng-container>

    <ng-container matColumnDef="nav">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let lage">
        <span class="nav-button">
          <button mat-icon-button (click)="onClickMeldungen(lage)" matTooltip="Zu Meldungen wechseln">
            <mat-icon>message</mat-icon>
          </button>
          <button mat-icon-button (click)="onClickMap(lage)" matTooltip="Zur Lagedarstellung wechseln">
            <mat-icon>map</mat-icon>
          </button>
          <button mat-icon-button (click)="onClickEinsatztagebuch(lage)" matTooltip="Zum Einsatztagebuch wechseln">
            <mat-icon>notes</mat-icon>
          </button>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="stichworte">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="stichworte">Stichworte</th>
      <td mat-cell *matCellDef="let lage">
        <mat-chip-set>
          <mat-chip *ngFor="let stichwort of lage.stichworte"> {{ stichwort }}</mat-chip>
        </mat-chip-set>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      class="list-row"
      mat-row
      *matRowDef="let lageDTO; columns: displayedColumns"
      (click)="updateSelection(lageDTO)"
      (dblclick)="doubleClickAction(lageDTO)"
      [ngClass]="{
        'selected-list-row': lageDTO.id === (currentLage$ | async)?.id
      }"
    ></tr>
  </table>
  <h3 class="keine-daten-text" *ngIf="!lagen.length">Keine Lagen vorhanden</h3>
</app-loadable>
