import { createReducer, on } from '@ngrx/store';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { TierschadenStateInterface } from './tierschaden-state.interface';
import { tierschadenActions } from './tierschaden.actions';

export const initialState: TierschadenStateInterface = {
  isCreating: false,
  isLoading: false,
  isPatching: false,
  tierschaeden: [],
  loadedLageId: '',
};

export const tierschadenReducer = createReducer(
  initialState,

  // Reset Store
  on(tierschadenActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get Tierschaeden
  on(tierschadenActions.getTierschaeden, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(tierschadenActions.getTierschaedenSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    tierschaeden: action.tierschadenDTOs,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(tierschadenActions.getTierschaedenFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create Tierschaden
  on(tierschadenActions.createTierschaden, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(tierschadenActions.createTierschadenSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    tierschaeden: [...state.tierschaeden, action.createdTierschadenDTO],
    errorResponse: undefined,
  })),
  on(tierschadenActions.createTierschadenFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Tierschaden
  on(tierschadenActions.patchTierschaden, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(tierschadenActions.patchTierschadenSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    tierschaeden: replaceOrKeepCurrent(state.tierschaeden, action.patchedTierschadenDTO),
    errorResponse: undefined,
  })),
  on(tierschadenActions.patchTierschadenFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Tierschaden
  on(tierschadenActions.deleteTierschadenSuccess, (state, action) => ({
    ...state,
    tierschaeden: state.tierschaeden.filter((person) => person.id !== action.deletedTierschadenDTO.id),
  }))
);
