<app-tz-popup-toolbar [tzDto]="dto" (editClicked)="editDto()" (moveClicked)="moveDto()" />

<div class="content">
  <app-fuehrungsebene-icon-and-text *ngIf="parentFuehrungsebene" [fuehrungsebeneDTO]="parentFuehrungsebene" />

  <div class="row">
    <div class="links">
      <ng-container *ngIf="dto.status">
        <span>{{ dto.status.status | taktischesZeichenStatus }}</span>
        <span> [{{ dto.status.zeitpunktStatusWechsel | dateTime | async }}] </span>
      </ng-container>
    </div>

    <img class="zeichen" [src]="dto.dataUrl" />

    <div class="rechts">
      {{ dto.ortsangabe }}
    </div>
  </div>

  <div class="unten">
    <!-- Mannschaftsstärke -->
    <span
      >{{ dto.mannschaftsstaerke1 }} / {{ dto.mannschaftsstaerke2 }} / {{ dto.mannschaftsstaerke3 }} =
      {{ (dto.mannschaftsstaerke1 || 0) + (dto.mannschaftsstaerke2 || 0) + (dto.mannschaftsstaerke3 || 0) }}</span
    >
  </div>

  <!-- Kontakte -->
  <app-kontakt-icons *ngIf="dto.kommunikationOptionen" [kontaktmoeglichkeiten]="dto.kommunikationOptionen" />

  <!-- Aufträge -->
  <app-auftrag-header-element *ngIf="dto.auftraege" [auftraege]="dto.auftraege" />
</div>
