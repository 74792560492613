<mat-card class="auftrag-list-card">
  <!-- Kopfzeile mit Zusammenfassung zum Stand der zugewiesenen Aufträge -->
  <mat-toolbar>
    <h3 class="pr-05">Aufträge</h3>

    <!-- Fortschittsangabe, wenn Aufträge vorhanden -->
    <div class="progress">
      <div
        class="done-div"
        *ngIf="auftraegeErledigtCount !== auftraege.length; else done"
        matTooltip="Es sind {{ auftraege.length - auftraegeErledigtCount }} Aufträge unerledigt."
      >
        <mat-icon>insert_drive_file</mat-icon> {{ auftraegeErledigtCount }} von {{ auftraege.length }} erledigt
      </div>

      <!-- Template, wenn keine Aufträge vorhanden oder alle erledigt -->
      <ng-template #done>
        <ng-container *ngIf="auftraege.length <= 0"> Keine Aufträge </ng-container>
        <ng-container *ngIf="auftraege.length > 0">
          <div class="done-div" matTooltip="Alle {{ auftraege.length }} Aufträge erledigt">
            <mat-icon class="done-green">task</mat-icon>
            Alle Aufträge erledigt
          </div>
        </ng-container>
      </ng-template>
      <mat-progress-bar color="accent" [value]="(auftraegeErledigtCount / auftraege.length) * 100"></mat-progress-bar>
    </div>
    <button mat-icon-button (click)="newAuftrag()" matTooltip="Auftrag hinzufügen">
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="toggleErledigteAufgaben()"
      [matTooltip]="'Erledigte Aufträge ' + (showErledigteAuftraege ? 'ausblenden' : 'einblenden')"
    >
      <mat-icon>{{ showErledigteAuftraege ? "visibility" : "visibility_off" }}</mat-icon>
    </button>
  </mat-toolbar>

  <!-- Liste aller vorhandenen Aufträge. -->
  <mat-card-content>
    <mat-selection-list (selectionChange)="updateSelection($event)" #auftraegeSelection>
      <mat-list-option
        *ngFor="let auftrag of auftraegeDisplay"
        [value]="auftrag"
        [selected]="auftrag.erledigt"
        [disableRipple]="true"
      >
        <div class="auftrag-option">
          {{ auftrag | auftrag }}
          <span class="spacer"></span>
          <button class="end" mat-icon-button (click)="editAuftrag($event, auftrag)" matTooltip="Auftrag bearbeiten">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </mat-list-option>
    </mat-selection-list>

    <h3 class="keine-daten-text" *ngIf="!auftraege.length">Keine Aufträge vorhanden</h3>
  </mat-card-content>
</mat-card>
