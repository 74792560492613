import { Component, HostListener, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ApplicationToolbarComponent } from './navigation/application-toolbar/application-toolbar.component';
import { MetricsService } from './shared/services/metrics.service';
import { StoreErrorHandlerService } from './shared/services/store-error-handler.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [ApplicationToolbarComponent, RouterOutlet],
})
export class AppComponent {
  metricsService = inject(MetricsService);
  storeErrorHandler = inject(StoreErrorHandlerService);

  /**
   * Normales Browser-Contextmenu anwendungsweit nur mit Strg+Shift+Rechtsclick öffnen
   */
  @HostListener('window:contextmenu', ['$event'])
  onRightClick(event: MouseEvent) {
    if (!(event.ctrlKey && event.shiftKey)) {
      event.preventDefault();
    }
  }
}
