import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { FuehrungsebeneDTO, PersonenschadenDTO } from 'src/app/api/build/openapi';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { FuehrungsebeneIconAndTextComponent } from '../../../fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { ManvSmallComponent } from '../../../triage/manv-small/manv-small.component';
import { TaktischesZeichenPopup } from '../../taktische-zeichen.interface';
import { TzDialogService } from '../../tz-dialog.service';
import { TzPopupToolbarComponent } from '../../tz-popup-toolbar/tz-popup-toolbar.component';

@Component({
  selector: 'app-personenschaden-popup',
  standalone: true,
  imports: [
    CommonModule,
    FuehrungsebeneIconAndTextComponent,
    TzPopupToolbarComponent,
    MatCardModule,
    ManvSmallComponent,
  ],
  templateUrl: './personenschaden-popup.component.html',
  styleUrl: './personenschaden-popup.component.scss',
})
export class PersonenschadenPopupComponent implements OnInit, TaktischesZeichenPopup {
  @Input({ required: true }) dto!: PersonenschadenDTO;

  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private tzDialogService = inject(TzDialogService);

  protected parentFuehrungsebene?: FuehrungsebeneDTO;

  ngOnInit(): void {
    this.parentFuehrungsebene = this.fuehrungsebeneService
      .getAllFuehrungsebenen()
      .find((fuehrungsebeneDTO) => fuehrungsebeneDTO.id === this.dto.fuehrungsebeneId);
  }

  moveDto() {
    this.tzDialogService.openTzMoveDialog([this.dto], false);
  }

  editDto() {
    this.tzDialogService.openEditTzDialog(this.dto);
  }
}
