import { createReducer, on } from '@ngrx/store';
import { FuehrungsebeneMassnahmeDTO } from 'src/app/api/build/openapi';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { FuehrungsebeneMassnahmeStateInterface } from './fuehrungsebene-massnahme-state.interface';
import { fuehrungsebeneMassnahmeActions } from './fuehrungsebene-massnahme.actions';

const initialState: FuehrungsebeneMassnahmeStateInterface = {
  isCreating: false,
  isLoading: false,
  isPatching: false,
  fuehrungsebeneMassnahmen: [],
  loadedLageId: '',
};

export const fuehrungsebeneMassnahmeReducer = createReducer(
  initialState,

  // Reset Store
  on(fuehrungsebeneMassnahmeActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get Massnahmen
  on(fuehrungsebeneMassnahmeActions.getFuehrungsebeneMassnahmen, (state) => ({
    ...state,
    isLoading: true,
    errorResponse: undefined,
  })),
  on(fuehrungsebeneMassnahmeActions.getFuehrungsebeneMassnahmenSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    fuehrungsebeneMassnahmen: action.fuehrungsebeneMassnahmeDTOs,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(fuehrungsebeneMassnahmeActions.getFuehrungsebeneMassnahmenFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create Massnahme
  on(fuehrungsebeneMassnahmeActions.createFuehrungsebeneMassnahme, (state) => ({
    ...state,
    isCreating: true,
    errorResponse: undefined,
  })),
  on(fuehrungsebeneMassnahmeActions.createFuehrungsebeneMassnahmeSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    fuehrungsebeneMassnahmen: [...state.fuehrungsebeneMassnahmen, action.createdFuehrungsebeneMassnahmeDTO],
    errorResponse: undefined,
  })),
  on(fuehrungsebeneMassnahmeActions.createFuehrungsebeneMassnahmeFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Massnahme
  on(fuehrungsebeneMassnahmeActions.patchFuehrungsebeneMassnahme, (state) => ({
    ...state,
    isPatching: true,
    errorResponse: undefined,
  })),
  on(fuehrungsebeneMassnahmeActions.patchFuehrungsebeneMassnahmeSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    fuehrungsebeneMassnahmen: replaceOrKeepCurrent(
      state.fuehrungsebeneMassnahmen,
      action.patchedFuehrungsebeneMassnahmeDTO
    ),
    errorResponse: undefined,
  })),
  on(fuehrungsebeneMassnahmeActions.patchFuehrungsebeneMassnahmeFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  on(fuehrungsebeneMassnahmeActions.deleteFuehrungsebeneMassnahmeSuccess, (state, action) => ({
    ...state,
    isDeleting: false,
    fuehrungsebeneMassnahmen: state.fuehrungsebeneMassnahmen.filter(
      (massnahme: FuehrungsebeneMassnahmeDTO) => massnahme.id !== action.deletedFuehrungsebeneMassnahmeDTO.id
    ),
    errorResponse: undefined,
  }))
);
