<div class="dialog">
  <mat-toolbar class="flex-row">
    <h2>{{ heading }}</h2>
  </mat-toolbar>

  <mat-dialog-content class="row">
    @if (true) {
    <mat-card class="tz-list-card">
      <mat-toolbar>
        <h3>Zu verschiebende Zeichen</h3>
      </mat-toolbar>
      <app-tz-list class="tz-list" [tzDtos]="tzDtos" (tzDtoSelected)="selectTz($event)" />
    </mat-card>
    }

    <mat-card class="current-tz-card">
      <mat-toolbar>
        <img [src]="selectedTzDto?.dataUrl" />
        <h3>{{ selectedTzDto?.anzeigename }}</h3>
      </mat-toolbar>

      <mat-card-content class="card-content">
        <div class="inputs">
          <p *ngIf="fuehrungsebeneMessage">{{ fuehrungsebeneMessage }}</p>
          <p *ngIf="statusMessage">{{ statusMessage }}</p>
          <form [formGroup]="formGroup" class="flex-col flex-grow">
            <mat-form-field>
              <mat-label>Datum/Uhrzeit des Wechsels</mat-label>
              <input matInput type="datetime-local" [formControl]="fcDatum" dateTimeLocalAsDate />
              <mat-error>Bitte gültiges Datum eingeben</mat-error>
            </mat-form-field>

            @if (showOrtsangabe) {
            <mat-form-field>
              <mat-label>Adresse (Straße, Stadt, ...)</mat-label>
              <textarea matInput cdkTextareaAutosize [formControl]="fcOrtsangabe"></textarea>
            </mat-form-field>
            }

            <mat-form-field>
              <mat-label>Koordinate</mat-label>
              <input matInput [value]="geometry | geometryAsString" readonly />
              <button *ngIf="geometry" matSuffix mat-icon-button (click)="copyGeometryToClipboard($event)">
                <mat-icon>content_copy</mat-icon>
              </button>
            </mat-form-field>

            @if (tzDtos.length > 1) {
            <button mat-raised-button color="accent" (click)="applyChangesToAllTzs()">
              Änderungen für alle Zeichen übernehmen
            </button>
            }
          </form>
        </div>
        <app-tz-geometry-map class="map" (geometryChanged)="setKoordinate($event)" />
      </mat-card-content>
    </mat-card>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="accent" (click)="saveAndClose()" [disabled]="isSaving">Speichern</button>
  </mat-dialog-actions>
</div>
