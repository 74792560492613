<mat-toolbar> Lage beenden </mat-toolbar>

<mat-dialog-content>
  <p>
    Sind Sie sicher, dass die Lage
    {{ fuehrungsebenenService.getCurrentLage() ? '"' + fuehrungsebenenService.getCurrentLage()!.name + '"' : "" }}
    beendet werden soll?
  </p>
  <ul>
    <li><b>Beendete Lagen können nicht wiedereröffnet werden!</b></li>
    <li><b>Beendete Lagen und deren Inhalte können grundsätzlich nicht mehr verändert werden!</b></li>
    <li>Taktische Zeichen die aus der Bibliothek genutzt wurden, werden freigegeben.</li>
    <li>Das Beenden wird im Einsatztagebuch protokolliert.</li>
    <li>Das Einsatztagebuch der Lage bleibt bearbeitbar.</li>
  </ul>
  <br />
  <form [formGroup]="formGroup" class="flex-col">
    <mat-form-field>
      <mat-label>Begründung für das Einsatztagebuch</mat-label>
      <textarea matInput placeholder="Begründung ..." [formControl]="fcBegruendung"> </textarea>
      <mat-error>{{ getErrorMessage(fcBegruendung) }}</mat-error>
    </mat-form-field>

    <p>
      Geben Sie nachfolgenden Bestätigungscode ein: <b>{{ data.confirmationCode }}</b>
    </p>
    <mat-form-field>
      <mat-label>Bestätigungscode</mat-label>
      <input matInput placeholder="Bestätigungscode..." [formControl]="fcBestaetigungscode" />
      <mat-error>{{ getErrorMessage(fcBestaetigungscode) }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<div class="footer">
  <mat-dialog-actions class="end">
    <button mat-raised-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="accent" (click)="endLage()" [disabled]="!formGroup.valid">Beenden</button>
  </mat-dialog-actions>
</div>
