<div [id]="droplistId" cdkDropList (cdkDropListDropped)="dropped($event)" class="content">
  <!-- Ungruppierte Ansicht -->
  <div *ngIf="!groupItems" class="zeichen-container" (click)="clearSelection($event)">
    <ng-container *ngIf="isTzTypVisible(TaktischesZeichenTyp.Anlass)">
      <app-taktische-zeichen-item
        *ngFor="let anlass of anlassDTOs"
        cdkDrag
        [cdkDragData]="getSelection()"
        [item]="anlass"
        [selected]="multiSelectService.isSelected(anlass)"
        (cdkDragStarted)="startDraggingTz(anlass)"
        (tzClick)="clickedTz($event)"
        (tzContextmenu)="openTzBubbleMenu($event)"
      />
    </ng-container>

    <ng-container *ngIf="isTzTypVisible(TaktischesZeichenTyp.Befehlsstelle)">
      <app-taktische-zeichen-item
        *ngFor="let befehlsstelle of befehlsstelleDTOs"
        cdkDrag
        [cdkDragData]="getSelection()"
        [item]="befehlsstelle"
        [selected]="multiSelectService.isSelected(befehlsstelle)"
        (cdkDragStarted)="startDraggingTz(befehlsstelle)"
        (tzClick)="clickedTz($event)"
        (tzContextmenu)="openTzBubbleMenu($event)"
      />
    </ng-container>

    <ng-container *ngIf="isTzTypVisible(TaktischesZeichenTyp.Fahrzeug)">
      <app-taktische-zeichen-item
        *ngFor="let fahrzeug of fahrzeugDTOs"
        cdkDrag
        [cdkDragData]="getSelection()"
        [item]="fahrzeug"
        [selected]="multiSelectService.isSelected(fahrzeug)"
        (cdkDragStarted)="startDraggingTz(fahrzeug)"
        (tzClick)="clickedTz($event)"
        (tzContextmenu)="openTzBubbleMenu($event)"
      />
    </ng-container>

    <ng-container *ngIf="isTzTypVisible(TaktischesZeichenTyp.Foto)">
      <app-taktische-zeichen-item
        *ngFor="let foto of fotoDTOs"
        cdkDrag
        [cdkDragData]="getSelection()"
        [item]="foto"
        [selected]="multiSelectService.isSelected(foto)"
        (cdkDragStarted)="startDraggingTz(foto)"
        (tzClick)="clickedTz($event)"
        (tzContextmenu)="openTzBubbleMenu($event)"
      />
    </ng-container>

    <ng-container *ngIf="isTzTypVisible(TaktischesZeichenTyp.Gebaeude)">
      <app-taktische-zeichen-item
        *ngFor="let gebaeude of gebaeudeDTOs"
        cdkDrag
        [cdkDragData]="getSelection()"
        [item]="gebaeude"
        [selected]="multiSelectService.isSelected(gebaeude)"
        (cdkDragStarted)="startDraggingTz(gebaeude)"
        (tzClick)="clickedTz($event)"
        (tzContextmenu)="openTzBubbleMenu($event)"
      />
    </ng-container>

    <ng-container *ngIf="isTzTypVisible(TaktischesZeichenTyp.Gebiet)">
      <app-taktische-zeichen-item
        *ngFor="let gebiet of gebietDTOs"
        cdkDrag
        [cdkDragData]="getSelection()"
        [item]="gebiet"
        [selected]="multiSelectService.isSelected(gebiet)"
        (cdkDragStarted)="startDraggingTz(gebiet)"
        (tzClick)="clickedTz($event)"
        (tzContextmenu)="openTzBubbleMenu($event)"
      />
    </ng-container>

    <ng-container *ngIf="isTzTypVisible(TaktischesZeichenTyp.Gefahr)">
      <app-taktische-zeichen-item
        *ngFor="let gefahr of gefahrDTOs"
        cdkDrag
        [cdkDragData]="getSelection()"
        [item]="gefahr"
        [selected]="multiSelectService.isSelected(gefahr)"
        (cdkDragStarted)="startDraggingTz(gefahr)"
        (tzClick)="clickedTz($event)"
        (tzContextmenu)="openTzBubbleMenu($event)"
      />
    </ng-container>

    <ng-container *ngIf="isTzTypVisible(TaktischesZeichenTyp.Person)">
      <app-taktische-zeichen-item
        *ngFor="let person of personenDTOs"
        cdkDrag
        [cdkDragData]="getSelection()"
        [item]="person"
        [selected]="multiSelectService.isSelected(person)"
        (cdkDragStarted)="startDraggingTz(person)"
        (tzClick)="clickedTz($event)"
        (tzContextmenu)="openTzBubbleMenu($event)"
      />
    </ng-container>

    <ng-container *ngIf="isTzTypVisible(TaktischesZeichenTyp.Personenschaden)">
      <app-taktische-zeichen-item
        *ngFor="let personenschaden of personenschadenDTOs"
        cdkDrag
        [cdkDragData]="getSelection()"
        [item]="personenschaden"
        [selected]="multiSelectService.isSelected(personenschaden)"
        (cdkDragStarted)="startDraggingTz(personenschaden)"
        (tzClick)="clickedTz($event)"
        (tzContextmenu)="openTzBubbleMenu($event)"
      />
    </ng-container>

    <ng-container *ngIf="isTzTypVisible(TaktischesZeichenTyp.Massnahme)">
      <app-taktische-zeichen-item
        *ngFor="let massnahme of massnahmeDTOs"
        cdkDrag
        [cdkDragData]="getSelection()"
        [item]="massnahme"
        [selected]="multiSelectService.isSelected(massnahme)"
        (cdkDragStarted)="startDraggingTz(massnahme)"
        (tzClick)="clickedTz($event)"
        (tzContextmenu)="openTzBubbleMenu($event)"
      />
    </ng-container>

    <ng-container *ngIf="isTzTypVisible(TaktischesZeichenTyp.Stelle)">
      <app-taktische-zeichen-item
        *ngFor="let stelle of stelleDTOs"
        cdkDrag
        [cdkDragData]="getSelection()"
        [item]="stelle"
        [selected]="multiSelectService.isSelected(stelle)"
        (cdkDragStarted)="startDraggingTz(stelle)"
        (tzClick)="clickedTz($event)"
        (tzContextmenu)="openTzBubbleMenu($event)"
      />
    </ng-container>

    <ng-container *ngIf="isTzTypVisible(TaktischesZeichenTyp.TaktischeFormation)">
      <app-taktische-zeichen-item
        *ngFor="let taktischeFormation of taktischeFormationDTOs"
        cdkDrag
        [cdkDragData]="getSelection()"
        [item]="taktischeFormation"
        [selected]="multiSelectService.isSelected(taktischeFormation)"
        (cdkDragStarted)="startDraggingTz(taktischeFormation)"
        (tzClick)="clickedTz($event)"
        (tzContextmenu)="openTzBubbleMenu($event)"
      />
    </ng-container>

    <ng-container *ngIf="isTzTypVisible(TaktischesZeichenTyp.Tierschaden)">
      <app-taktische-zeichen-item
        *ngFor="let tierschaden of tierschadenDTOs"
        cdkDrag
        [cdkDragData]="getSelection()"
        [item]="tierschaden"
        [selected]="multiSelectService.isSelected(tierschaden)"
        (cdkDragStarted)="startDraggingTz(tierschaden)"
        (tzClick)="clickedTz($event)"
        (tzContextmenu)="openTzBubbleMenu($event)"
      />
    </ng-container>

    <ng-container *ngIf="areEinsaetzeVisible() && (componentService.isShowDevelopment$ | async)">
      <app-einsatz-item
        *ngFor="let einsatzDto of einsatzDtos"
        [einsatzDto]="einsatzDto"
        (einsatzContextmenu)="openEinsatzBubbleMenu(einsatzDto, $event)"
      />
    </ng-container>

    <!-- Button zum Hinzufügen neuer Zeichen -->
    <ng-container [ngTemplateOutlet]="addZeichen"></ng-container>
  </div>

  <!-- Gruppierte Ansicht -->
  <div class="zeichen-container" *ngIf="groupItems">
    <app-taktische-zeichen-item-grouped
      *ngIf="anlassDTOs.length && isTzTypVisible(TaktischesZeichenTyp.Anlass)"
      [tzDTOs]="anlassDTOs"
      [tzTyp]="TaktischesZeichenTyp.Anlass"
      (click)="openTzDetails(TaktischesZeichenTyp.Anlass)"
    />

    <app-taktische-zeichen-item-grouped
      *ngIf="befehlsstelleDTOs.length && isTzTypVisible(TaktischesZeichenTyp.Befehlsstelle)"
      [tzDTOs]="befehlsstelleDTOs"
      [tzTyp]="TaktischesZeichenTyp.Befehlsstelle"
      (click)="openTzDetails(TaktischesZeichenTyp.Befehlsstelle)"
    />

    <app-taktische-zeichen-item-grouped
      *ngIf="fahrzeugDTOs.length && isTzTypVisible(TaktischesZeichenTyp.Fahrzeug)"
      [tzDTOs]="fahrzeugDTOs"
      [tzTyp]="TaktischesZeichenTyp.Fahrzeug"
      (click)="openTzDetails(TaktischesZeichenTyp.Fahrzeug)"
    />

    <app-taktische-zeichen-item-grouped
      *ngIf="fotoDTOs.length && isTzTypVisible(TaktischesZeichenTyp.Foto)"
      [tzDTOs]="fotoDTOs"
      [tzTyp]="TaktischesZeichenTyp.Foto"
      (click)="openTzDetails(TaktischesZeichenTyp.Foto)"
    />

    <app-taktische-zeichen-item-grouped
      *ngIf="gebaeudeDTOs.length && isTzTypVisible(TaktischesZeichenTyp.Gebaeude)"
      [tzDTOs]="gebaeudeDTOs"
      [tzTyp]="TaktischesZeichenTyp.Gebaeude"
      (click)="openTzDetails(TaktischesZeichenTyp.Gebaeude)"
    />

    <app-taktische-zeichen-item-grouped
      *ngIf="gebietDTOs.length && isTzTypVisible(TaktischesZeichenTyp.Gebiet)"
      [tzDTOs]="gebietDTOs"
      [tzTyp]="TaktischesZeichenTyp.Gebiet"
      (click)="openTzDetails(TaktischesZeichenTyp.Gebiet)"
    />

    <app-taktische-zeichen-item-grouped
      *ngIf="gefahrDTOs.length && isTzTypVisible(TaktischesZeichenTyp.Gefahr)"
      [tzDTOs]="gefahrDTOs"
      [tzTyp]="TaktischesZeichenTyp.Gefahr"
      (click)="openTzDetails(TaktischesZeichenTyp.Gefahr)"
    />

    <app-taktische-zeichen-item-grouped
      *ngIf="massnahmeDTOs.length && isTzTypVisible(TaktischesZeichenTyp.Massnahme)"
      [tzDTOs]="massnahmeDTOs"
      [tzTyp]="TaktischesZeichenTyp.Massnahme"
      (click)="openTzDetails(TaktischesZeichenTyp.Massnahme)"
    />
    <app-taktische-zeichen-item-grouped
      *ngIf="personenDTOs.length && isTzTypVisible(TaktischesZeichenTyp.Person)"
      [tzDTOs]="personenDTOs"
      [tzTyp]="TaktischesZeichenTyp.Person"
      (click)="openTzDetails(TaktischesZeichenTyp.Person)"
    />

    <app-taktische-zeichen-item-grouped
      *ngIf="personenschadenDTOs.length && isTzTypVisible(TaktischesZeichenTyp.Personenschaden)"
      [tzDTOs]="personenschadenDTOs"
      [tzTyp]="TaktischesZeichenTyp.Personenschaden"
      (click)="openTzDetails(TaktischesZeichenTyp.Personenschaden)"
    />

    <app-taktische-zeichen-item-grouped
      *ngIf="stelleDTOs.length && isTzTypVisible(TaktischesZeichenTyp.Stelle)"
      [tzDTOs]="stelleDTOs"
      [tzTyp]="TaktischesZeichenTyp.Stelle"
      (click)="openTzDetails(TaktischesZeichenTyp.Stelle)"
    />

    <app-taktische-zeichen-item-grouped
      *ngIf="taktischeFormationDTOs.length && isTzTypVisible(TaktischesZeichenTyp.TaktischeFormation)"
      [tzDTOs]="taktischeFormationDTOs"
      [tzTyp]="TaktischesZeichenTyp.TaktischeFormation"
      (click)="openTzDetails(TaktischesZeichenTyp.TaktischeFormation)"
    />

    <app-taktische-zeichen-item-grouped
      *ngIf="tierschadenDTOs.length && isTzTypVisible(TaktischesZeichenTyp.Tierschaden)"
      [tzDTOs]="tierschadenDTOs"
      [tzTyp]="TaktischesZeichenTyp.Tierschaden"
      (click)="openTzDetails(TaktischesZeichenTyp.Tierschaden)"
    />

    <app-einsatz-item-grouped
      *ngIf="einsatzfilterDtos.length && (componentService.isShowDevelopment$ | async)"
      [einsatzDtos]="einsatzDtos"
      (click)="openEinsatzDetails()"
    />

    <ng-container [ngTemplateOutlet]="addZeichen"></ng-container>
  </div>

  <!-- Button zum Hinzufügen neuer Taktischer Zeichen. Es gibt die Option zum Anlegen und zur Auswahl aus der Bibliothek-->
  <ng-template #addZeichen>
    <mat-card class="add-button-container">
      <div
        class="add-button"
        mat-button
        matRipple
        (click)="openTaktischeZeichenDialog(fuehrungsebeneDTO)"
        matTooltip="Neues Zeichen anlegen"
      >
        <mat-icon>add</mat-icon>
      </div>
      <div
        class="add-button"
        mat-button
        matRipple
        (click)="openBibliothekSelectionDialog(fuehrungsebeneDTO)"
        matTooltip="Zeichen aus Bibliothek auswählen"
      >
        <mat-icon>library_add</mat-icon>
      </div>
    </mat-card>
  </ng-template>

  <h3 class="keine-daten-text" *ngIf="!tzTotalCount && (groupItems ? !einsatzfilterDtos?.length : !einsatzDtos.length)">
    Keine Taktischen Zeichen zugewiesen
  </h3>
</div>
