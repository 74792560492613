import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorResponse, LageberichtDTO, LageberichtResourceService } from 'src/app/api/build/openapi';
import { lageberichtActions } from './lagebericht.actions';
import { catchError, map, mergeMap, of, switchMap, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';

@Injectable()
export class LageberichtEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Lageberichte anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return lageberichtActions.getLageberichte({ lageId: props.currentLage.id });
        } else {
          return lageberichtActions.resetStore();
        }
      })
    )
  );

  getLagebericht$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageberichtActions.getLageberichte),
      mergeMap((props) =>
        this.lageberichtResourceService.getLageberichteByLageId(props.lageId).pipe(
          map((lageberichte) => lageberichtActions.getLageberichteSuccess({ lageberichte })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(lageberichtActions.getLageberichteFailure({ errorResponse }));
          })
        )
      )
    )
  );

  createLagebericht$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageberichtActions.createLagebericht),
      switchMap((props) =>
        this.lageberichtResourceService.createLagebericht(props.copy, props.lageId).pipe(
          map((newLagebericht) => lageberichtActions.createLageberichtSuccess({ newLagebericht })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(lageberichtActions.createLageberichtFailure({ errorResponse }));
          })
        )
      )
    )
  );

  patchLagebericht$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageberichtActions.patchLagebericht),
      switchMap((props) => {
        if (!props.lagebericht.id || !props.lagebericht.lageId) {
          return throwError(() => new Error('Lagebericht muss eine Lage und eine Id besitzen'));
        }
        return this.lageberichtResourceService
          .patchLagebericht(props.lagebericht.lageId, props.lagebericht.id, props.lagebericht)
          .pipe(
            map((patchedLagebericht) => lageberichtActions.patchLageberichtSuccess({ patchedLagebericht })),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(lageberichtActions.patchLageberichtFailure({ errorResponse }));
            })
          );
      })
    )
  );

  deleteLagebericht$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageberichtActions.deleteLagebericht),
      map((action) => action.lagebericht),
      switchMap((lagebericht: LageberichtDTO) => {
        if (!lagebericht.id || !lagebericht.lageId) {
          return throwError(() => new Error('Lagebericht muss eine Lage und eine Id besitzen'));
        }
        return this.lageberichtResourceService.deleteLagebericht(lagebericht.lageId, lagebericht.id).pipe(
          map(() => lageberichtActions.deleteLageberichtSuccess({ deletedLageberichtId: lagebericht.id as string })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(lageberichtActions.deleteLageberichtFailure({ errorResponse }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private lageberichtResourceService: LageberichtResourceService) {}
}
