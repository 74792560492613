import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi';

@Component({
  selector: 'app-tz-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tz-list.component.html',
  styleUrl: './tz-list.component.scss',
})
export class TzListComponent implements OnChanges {
  @Input({ required: true }) tzDtos: TaktischesZeichenDTO[] = [];
  @Output() tzDtoSelected = new EventEmitter<TaktischesZeichenDTO>();

  protected selectedTzDto?: TaktischesZeichenDTO;

  /**
   * Nach neuem Input, das erste TZ auswählen
   */
  ngOnChanges(): void {
    if (this.tzDtos.length) {
      this.selectTzDto(this.tzDtos[0]);
    } else {
      this.selectedTzDto = undefined;
    }
  }

  /**
   * TZ sowohl intern auswählen und Event versenden
   */
  selectTzDto(tzDto: TaktischesZeichenDTO) {
    this.selectedTzDto = tzDto;
    this.tzDtoSelected.emit(tzDto);
  }
}
