import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { lageActions } from '../lagen/+state/lage.actions';
import { lagenSelector } from '../lagen/+state/lage.selectors';

/**
 * Erkennt, ob es sich bei dem aktuellen Fenster um ein Popup handelt.
 * Zentrale Methode ist die `initIfPopUp`. Hintergrund ist die Multimonitorfähigkeit von LAGE.
 *
 * Wird ein Popup erkannt, wird für dieses die aktuelle Lage gesetzt,
 * woraufhin das allgemeine Handling ausgelöst wird.
 */
@Injectable({
  providedIn: 'root',
})
export class PopupManagerService {
  private destroyRef = inject(DestroyRef);
  /** Das aktuelle/eigene Fenster */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private window = window as any;

  private lagen$ = this.store.pipe(select(lagenSelector));
  private currentLageId: string | null = null;

  /** Der PopupManagerService hat das aktuelle Window als Popup erkannt, welches initialisiert wird/wurde. */
  isInitializedPopup$ = new BehaviorSubject(false);

  constructor(private store: Store<AppStateInterface>) {}

  /**
   *
   * @param route die aktive Route der Komponente, die als Popup geöffnet wurde.
   * Muss übergeben werden, da nur die initial aufgerufende Komponente die Informationen enthält.
   * (In diesem Service wäre die aktive Route aus dem constructor leer)
   *
   * @description aus den Params von `route` wird die LageId ausgelesen, damit die aktuelle Lage gesetzt werden kann.
   */
  initIfPopUp(route: ActivatedRoute): void {
    // Abbruch, wenn kein Popup / sekundäres Fenster
    if (!this.isSecondaryScreen()) {
      return;
    }

    // Selektiert aus allen Lagen die aktuelle Lage (s.u.)
    this.lagen$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((lagen) => {
      if (this.currentLageId) {
        const currentLage = lagen.find((l) => l.id === this.currentLageId);

        if (currentLage) {
          this.store.dispatch(lageActions.setCurrentLage({ currentLage: currentLage }));

          // TODO Lage-Liste leeren, sobald aktuelle Lage gesetzt ist?
        }
      }
    });

    // Aus den Parametern die Lage-Id(= aktuelle Lage) auslesen. Löst dann das Laden aller Lagen aus.
    route.paramMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      this.currentLageId = params.get('lage-id');

      if (this.currentLageId) {
        this.store.dispatch(lageActions.getLagen());
      }
    });

    this.isInitializedPopup$.next(true);
  }

  /**
   *
   * Prüft anhand der "Operner"-Information, ob es sich um ein Popup = sekundäres Fenster handelt.
   *
   * @todo Beste Möglichkeit die gefunden wurde, ggf. nicht die aller beste! https://stackoverflow.com/a/30873964
   *
   * @returns `true` wenn erkannt wird, dass das aktuelle Fenster ein "sekunäres"
   */
  isSecondaryScreen = () => this.window.opener !== null;
}
