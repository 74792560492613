import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { ErrorResponse, TaktischeFormationResourceService } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { fahrzeugActions } from '../../fahrzeuge/+state/fahrzeug.actions';
import { personActions } from '../../personen/+state/person.actions';
import { taktischeFormationActions } from './taktische-formation.actions';

@Injectable()
export class TaktischeFormationEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Taktischen Formationen anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return taktischeFormationActions.getTaktischeFormationen({ lageId: props.currentLage.id });
        } else {
          return taktischeFormationActions.resetStore();
        }
      })
    )
  );

  patchSuccessReloadFormationen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taktischeFormationActions.patchTaktischeFormationSuccess),
      mergeMap((props) => {
        if (!props.patchedTaktischeFormationDTO.lageId) {
          return throwError(() => new Error('Taktische Formation muss eine Id besitzen'));
        }
        return of(
          taktischeFormationActions.getTaktischeFormationen({
            lageId: props.patchedTaktischeFormationDTO.lageId,
          }),
          fahrzeugActions.getFahrzeuge({
            lageId: props.patchedTaktischeFormationDTO.lageId,
          }),
          personActions.getPersonen({
            lageId: props.patchedTaktischeFormationDTO.lageId,
          })
        );
      })
    )
  );

  /**
   * Alle Taktischen Formationen einer Lage laden
   */
  getTaktischeFormationen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taktischeFormationActions.getTaktischeFormationen),
      mergeMap((props) =>
        this.taktischeFormationenResourceService.getTaktischeFormationenByLageId(props.lageId).pipe(
          map((taktischeFormationen) =>
            taktischeFormationActions.getTaktischeFormationenSuccess({
              taktischeFormationDTOs: taktischeFormationen,
              lageId: props.lageId,
            })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(
              taktischeFormationActions.getTaktischeFormationenFailure({
                errorResponse,
              })
            );
          })
        )
      )
    )
  );

  /**
   * Neue Taktische Formation anlegen
   */
  createTaktischeFormation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taktischeFormationActions.createTaktischeFormation),
      mergeMap((props) =>
        this.taktischeFormationenResourceService
          .createTaktischeFormation(props.lageId, props.taktischeFormationDTO)
          .pipe(
            map((createdTaktischeFormationDTO) =>
              taktischeFormationActions.createTaktischeFormationSuccess({ createdTaktischeFormationDTO })
            ),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(
                taktischeFormationActions.createTaktischeFormationFailure({
                  errorResponse,
                })
              );
            })
          )
      )
    )
  );

  /**
   * Existierende Taktische Formation editieren
   */
  patchTaktischeFormation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taktischeFormationActions.patchTaktischeFormation),
      mergeMap((props) => {
        if (!props.taktischeFormationDTO.id) {
          return throwError(() => new Error('Taktische Formation muss eine Id besitzen'));
        }
        return this.taktischeFormationenResourceService
          .patchTaktischeFormation(props.lageId, props.taktischeFormationDTO.id, props.taktischeFormationDTO)
          .pipe(
            map((patchedTaktischeFormationDTO) =>
              taktischeFormationActions.patchTaktischeFormationSuccess({ patchedTaktischeFormationDTO })
            ),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(
                taktischeFormationActions.patchTaktischeFormationFailure({
                  errorResponse,
                })
              );
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private taktischeFormationenResourceService: TaktischeFormationResourceService
  ) {}
}
