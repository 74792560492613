import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, GebaeudeDTO } from 'src/app/api/build/openapi';

export const gebaeudeActions = createActionGroup({
  source: 'Gebaeude',
  events: {
    'Reset Store': emptyProps(),

    'Get Gebaeude': props<{ lageId: string }>(),
    'Get Gebaeude Success': props<{ gebaeudeDTOs: GebaeudeDTO[]; lageId: string }>(),
    'Get Gebaeude Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Gebaeude': props<{ lageId: string; gebaeudeDTO: GebaeudeDTO }>(),
    'Create Gebaeude Success': props<{ createdGebaeudeDTO: GebaeudeDTO }>(),
    'Create Gebaeude Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Gebaeude': props<{ lageId: string; gebaeudeDTO: GebaeudeDTO }>(),
    'Patch Gebaeude Success': props<{ patchedGebaeudeDTO: GebaeudeDTO }>(),
    'Patch Gebaeude Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Gebaeude Success': props<{ deletedGebaeudeDTO: GebaeudeDTO }>(),
  },
});
