import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BefehlsstelleDTO, ErrorResponse } from 'src/app/api/build/openapi';

export const befehlsstelleActions = createActionGroup({
  source: 'Befehlsstellen',
  events: {
    'Reset Store': emptyProps(),

    'Get Befehlsstellen': props<{ lageId: string }>(),
    'Get Befehlsstellen Success': props<{ befehlsstelleDTOs: BefehlsstelleDTO[]; lageId: string }>(),
    'Get Befehlsstellen Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Befehlsstelle': props<{ lageId: string; befehlsstelleDTO: BefehlsstelleDTO }>(),
    'Create Befehlsstelle Success': props<{ createdBefehlsstelleDTO: BefehlsstelleDTO }>(),
    'Create Befehlsstelle Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Befehlsstelle': props<{ lageId: string; befehlsstelleDTO: BefehlsstelleDTO }>(),
    'Patch Befehlsstelle Success': props<{ patchedBefehlsstelleDTO: BefehlsstelleDTO }>(),
    'Patch Befehlsstelle Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Befehlsstelle Success': props<{ deletedBefehlsstelleDTO: BefehlsstelleDTO }>(),
  },
});
