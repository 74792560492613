<mat-toolbar>
  <span class="header-row">
    <span *ngIf="(!showEinsaetze && taktischesZeichenTypen.length !== 1) || showAll">Taktische Zeichen in:</span>
    <span *ngIf="taktischesZeichenTypen.length === 1 && !showAll"
      >{{ taktischeZeichenService.taktischeZeichenInfoMapping.get(taktischesZeichenTypen[0])?.plural }} in:</span
    >
    <span *ngIf="showEinsaetze && !showAll">ELS Einsätze in:</span>
    <app-fuehrungsebene-icon-and-text [fuehrungsebeneDTO]="fuehrungsebeneDTO" textSize="large" />
  </span>
  <mat-slide-toggle [(ngModel)]="showAll">Alle</mat-slide-toggle>
</mat-toolbar>

<div class="content">
  <app-taktische-zeichen-container
    [fuehrungsebeneDTO]="fuehrungsebeneDTO"
    [taktischesZeichenStatus]="taktischesZeichenStatus"
    [taktischeZeichenTypen]="taktischesZeichenTypen"
    [showAll]="showAll"
    [showEinsaetze]="showEinsaetze"
  />
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="accent">Schließen</button>
</mat-dialog-actions>
