import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, FotoDTO } from 'src/app/api/build/openapi';

export const fotoActions = createActionGroup({
  source: 'Fotos',
  events: {
    'Reset Store': emptyProps(),

    'Get Fotos': props<{ lageId: string }>(),
    'Get Fotos Success': props<{ fotoDTOs: FotoDTO[]; lageId: string }>(),
    'Get Fotos Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Foto': props<{ lageId: string; fotoDTO: FotoDTO }>(),
    'Create Foto Success': props<{ createdFotoDTO: FotoDTO }>(),
    'Create Foto Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Foto': props<{ lageId: string; fotoDTO: FotoDTO }>(),
    'Patch Foto Success': props<{ patchedFotoDTO: FotoDTO }>(),
    'Patch Foto Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Foto Success': props<{ deletedFotoDTO: FotoDTO }>(),
  },
});
