import { createReducer, on } from '@ngrx/store';
import { GefahrDTO } from 'src/app/api/build/openapi';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { GefahrStateInterface } from './gefahr-state.interface';
import { gefahrActions } from './gefahr.actions';

export const initialState: GefahrStateInterface = {
  isCreating: false,
  isLoading: false,
  isPatching: false,
  gefahren: [],
  loadedLageId: '',
};

export const gefahrReducer = createReducer(
  initialState,

  // Reset Store
  on(gefahrActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get Gefahren
  on(gefahrActions.getGefahren, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(gefahrActions.getGefahrenSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    gefahren: action.gefahrDTOs,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(gefahrActions.getGefahrenFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create Gefahr
  on(gefahrActions.createGefahr, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(gefahrActions.createGefahrSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    gefahren: [...state.gefahren, action.createdGefahrDTO],
    errorResponse: undefined,
  })),
  on(gefahrActions.createGefahrFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Gefahr
  on(gefahrActions.patchGefahr, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(gefahrActions.patchGefahrSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    gefahren: replaceOrKeepCurrent(state.gefahren, action.patchedGefahrDTO),
    errorResponse: undefined,
  })),
  on(gefahrActions.patchGefahrFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Gefahr
  on(gefahrActions.deleteGefahrSuccess, (state, action) => ({
    ...state,
    gefahren: state.gefahren.filter((gefahr: GefahrDTO) => gefahr.id !== action.deletedGefahrDTO.id),
  }))
);
