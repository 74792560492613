<span *ngIf="currentLage$ | async as currentLage" class="infobar-container">
  @if(currentLage.lageStatus === LageStatus.Beendet){
  <mat-icon matTooltip="Lage ist beendet">lock</mat-icon>
  }
  <span class="ml-1 text">{{ currentLage.startedOn ? (currentLage.startedOn | dateTime | async) : "" }}</span>
  <span class="text">{{ currentLage.name }}</span>
  <span class="text">{{ currentLage.adresse }}</span>
  <div class="chips">
    <mat-chip *ngFor="let stichwort of currentLage.stichworte"> {{ stichwort }}</mat-chip>
  </div>
</span>
