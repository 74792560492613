import { Routes } from '@angular/router';
import { LandingPageComponent } from './auth/landing-page/landing-page.component';
import { NotFoundComponent } from './auth/not-found/not-found.component';
import { LagedarstellungBaseComponent } from './lagedarstellung/lagedarstellung-base/lagedarstellung-base.component';

import { AuthGuard } from './auth/auth.guard';
import { LageOverviewComponent } from './lagedarstellung/lagen/lage-overview/lage-overview.component';
import { LageSelectedGuard } from './lagedarstellung/lagen/lage-selected-guard';

import {
  CookieHinweisComponent,
  DatenschutzerklaerungComponent,
  FeedbackComponent,
  ImpressumComponent,
  LicenseComponent,
  ReleaseNotesComponent,
} from '@product/ise-product-information-lib';
import { ProgramSelectGuard } from './auth/program-select.guard';
import { ProgramSelectComponent } from './auth/program-select/program-select.component';
import { LageKBaseComponent } from './lagek/lage-k-base/lage-k-base.component';

const loginPathInternal = 'login';
export const loginPath = '/' + loginPathInternal;

export const routes: Routes = [
  { path: '', redirectTo: 'lagen', pathMatch: 'full' },

  {
    path: loginPathInternal,
    component: LandingPageComponent,
  },
  {
    path: 'lagen',
    component: LageOverviewComponent,
    canActivate: [AuthGuard, ProgramSelectGuard],
  },
  {
    path: 'anwendung',
    component: ProgramSelectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'einsaetze',
    loadComponent: () =>
      import('./einsatz/einsatz-main-view/einsatz-main-view.component').then((mod) => mod.EinsatzMainViewComponent),
    canActivate: [AuthGuard, LageSelectedGuard],
  },
  {
    path: 'meldungen',
    loadComponent: () => import('./meldungen-bereich/meldungen-bereich.component').then((mod) => mod.MeldungenBereichComponent),
    canActivate: [AuthGuard, LageSelectedGuard],
  },
  {
    path: 'lagedarstellung',
    component: LagedarstellungBaseComponent,
    canActivate: [AuthGuard, LageSelectedGuard],
  },
  {
    path: 'lage-k',
    component: LageKBaseComponent,
    canActivate: [AuthGuard, LageSelectedGuard],
  },
  {
    path: 'einsatztagebuch',
    loadComponent: () =>
      import('./einsatztagebuch/einsatztagebuch/einsatztagebuch.component').then((mod) => mod.EinsatztagebuchComponent),
    canActivate: [AuthGuard, LageSelectedGuard],
  },
  {
    path: 'license',
    component: LicenseComponent,
    canActivate: [AuthGuard],
  },
  { path: 'lage-k', loadChildren: () => lageKRoutes },
  { path: 'planung', loadChildren: () => planungRoutes },
  { path: 'informationen', loadChildren: () => informationRoutes },
  { path: 'popup', loadChildren: () => multiMonitorRoutes },
  { path: '**', component: NotFoundComponent },
];

export const planungRoutes: Routes = [
  { path: '', redirectTo: 'bibliothek', pathMatch: 'full' },
  {
    path: 'bibliothek',
    loadComponent: () =>
      import('./planung/bibliothek/bibliothek-base/bibliothek-base.component').then(
        (mod) => mod.BibliothekBaseComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'karte',
    loadComponent: () =>
      import('./planung/karte/map-config/map-config.component').then((mod) => mod.MapConfigComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'voreinstellungen',
    loadComponent: () =>
      import('./planung/application-settings/application-settings-main/application-settings-main.component').then(
        (mod) => mod.ApplicationSettingsMainComponent
      ),
  },
  // TODO Pfade schon verwendet
  // {
  //   path: 'kontakte',
  //   loadComponent: () => {...}
  // },
];

export const lageKRoutes: Routes = [
  { path: '', redirectTo: 'lagen', pathMatch: 'full' },
  {
    path: 'dashboard',
    component: LagedarstellungBaseComponent,
    canActivate: [AuthGuard, LageSelectedGuard],
  },
  {
    path: 'meldungen',
    loadComponent: () => import('./meldungen-bereich/meldungen-bereich.component').then((mod) => mod.MeldungenBereichComponent),
    canActivate: [AuthGuard, LageSelectedGuard],
  },
  {
    path: 'anwesenheit',
    loadComponent: () =>
      import('./anwesenheitsliste/anwesenheitsliste.component').then((mod) => mod.AnwesenheitslisteComponent),
    canActivate: [AuthGuard, LageSelectedGuard],
  },
];

export const informationRoutes: Routes = [
  { path: '', redirectTo: 'releasenotes', pathMatch: 'full' },
  {
    path: 'releasenotes',
    component: ReleaseNotesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'lizenzen',
    component: LicenseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cookies',
    component: CookieHinweisComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'datenschutz',
    component: DatenschutzerklaerungComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
    canActivate: [AuthGuard],
  },
];

/** Routen für den Multimonitorbetrieb */
const multiMonitorRoutes: Routes = [
  {
    path: 'res/:lage-id',
    loadComponent: () =>
      import('./lagedarstellung/einsatzmittel-base/einsatzmittel-base.component').then(
        (mod) => mod.EinsatzmittelBaseComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'org/:lage-id',
    loadComponent: () =>
      import('./lagedarstellung/screen-three/screen-three.component').then((mod) => mod.ScreenThreeComponent),
    canActivate: [AuthGuard],
  },
];
