import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  catchError,
  distinctUntilChanged,
  filter,
  interval,
  map,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { AuthenticationResourceService } from '../api/build/openapi';

// import { AuthenticationService } from 'src/app/api/build/openapi';
import { ApplicationType } from '../app.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // leeres Subject, das zum Abbruch des "isAuthenticated" Intervalls führt
  private logout$ = new Subject();
  private authResourceService = inject(AuthenticationResourceService);

  /**
   * Fragt alle 5 Sekunden das Backend an um zu prüfen, ob dies erreichbar ist --> zunächst bedeutet dies für uns "erreichbar" und "authentifiziert"
   *
   * wird durch das subject "logout" abgebrochen
   *
   */
  isAuthenticated$ = interval(60_000).pipe(
    takeUntil(this.logout$),
    startWith(0),
    switchMap(() => this.apiAuthService.getUserinfo()),
    map((v) => (v ? v.name != '' : false)),
    catchError(() => of(false)),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  userInfo$ = this.isAuthenticated$.pipe(
    filter((authenticated) => authenticated),
    switchMap(() => this.apiAuthService.getUserinfo())
  );

  constructor(private router: Router, private apiAuthService: AuthenticationResourceService) {}

  toggleLogin() {
    this.isAuthenticated$.subscribe({
      next: (v) => (v ? this.logout() : this.login()),
    });
  }

  login() {
    window.open('/api/v1/authentication/login', '_self');
  }

  logout() {
    // cancel idAuthenticated check
    this.logout$.next(true);
    // call backend
    window.open('/api/logout', '_self');
  }

  mapUserToApplication(type: ApplicationType) {
    this.authResourceService.mapUserToApplication(type).subscribe();
  }
}
