import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Store } from '@ngrx/store';
import { BehaviorSubject, first } from 'rxjs';
import { einsatzfilterSelector } from '../+state/einsatzfilter.selectors';
import { EinsatzDTO, EinsatzResourceService, EinsatzfilterDTO } from '../../api/build/openapi';
import { EinsatzListComponent } from '../einsatz-list/einsatz-list.component';
import { EinsatzfilterListComponent } from '../einsatzfilter-list/einsatzfilter-list.component';

export interface EinsatzfilterSelectDialogInputOutputData {
  selectedEinsatzfilterDTOs: EinsatzfilterDTO[];
}

/**
 * Dialog zum Auswählen von Einsatzfiltern
 */
@Component({
  selector: 'app-einsatzfilter-select-dialog',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    EinsatzfilterListComponent,
    EinsatzListComponent,
    MatCardModule,
  ],
  templateUrl: './einsatzfilter-select-dialog.component.html',
  styleUrl: './einsatzfilter-select-dialog.component.scss',
})
export class EinsatzfilterSelectDialogComponent {
  selectedEinsatzfilterDTOs: EinsatzfilterDTO[] = [];
  availableEinsatzfilterDTOs: EinsatzfilterDTO[] = [];

  private einsatzResourceService = inject(EinsatzResourceService);
  private store = inject(Store);
  private dialogRef = inject(MatDialogRef);
  private dialogData: EinsatzfilterSelectDialogInputOutputData = inject(MAT_DIALOG_DATA);

  private selectedFilterDTO?: EinsatzfilterDTO;
  protected allEinsatzDTOs$ = new BehaviorSubject<EinsatzDTO[]>([]);
  protected filteredEinsatzDTOs$ = new BehaviorSubject<EinsatzDTO[]>([]);
  protected isSaving = false;

  constructor() {
    this.store
      .select(einsatzfilterSelector)
      .pipe(first())
      .subscribe((filterDTOs) => (this.availableEinsatzfilterDTOs = filterDTOs));

    if (this.dialogData) {
      this.selectedEinsatzfilterDTOs = this.dialogData.selectedEinsatzfilterDTOs;
    }
  }

  /**
   * Einen Filter auswählen und zugehörige Einsätze laden
   */
  selectFilter(einsatzfilterDTO: EinsatzfilterDTO) {
    this.selectedFilterDTO = einsatzfilterDTO;
    this.refreshFilteredEinsatzDTOs();
  }

  /**
   * Einsätze des ausgewählten Filters auf der rechten Seite laden
   */
  refreshFilteredEinsatzDTOs() {
    if (!this.selectedFilterDTO?.id) {
      this.filteredEinsatzDTOs$.next([]);
      return;
    }

    this.einsatzResourceService
      .previewEinsaetzeByFilterDTO(this.selectedFilterDTO)
      .subscribe((einsaetze) => this.filteredEinsatzDTOs$.next(einsaetze));
  }

  save() {
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    const result: EinsatzfilterSelectDialogInputOutputData = {
      selectedEinsatzfilterDTOs: this.selectedEinsatzfilterDTOs,
    };
    this.dialogRef.close(result);
  }
}
