export * from './anlassResource.service';
import { AnlassResourceService } from './anlassResource.service';
export * from './anwesenheitResource.service';
import { AnwesenheitResourceService } from './anwesenheitResource.service';
export * from './applicationSettingsResource.service';
import { ApplicationSettingsResourceService } from './applicationSettingsResource.service';
export * from './authenticationResource.service';
import { AuthenticationResourceService } from './authenticationResource.service';
export * from './befehlsstelleResource.service';
import { BefehlsstelleResourceService } from './befehlsstelleResource.service';
export * from './bibliothekResource.service';
import { BibliothekResourceService } from './bibliothekResource.service';
export * from './einsatzResource.service';
import { EinsatzResourceService } from './einsatzResource.service';
export * from './einsatzfilterResource.service';
import { EinsatzfilterResourceService } from './einsatzfilterResource.service';
export * from './fahrzeugResource.service';
import { FahrzeugResourceService } from './fahrzeugResource.service';
export * from './fotoResource.service';
import { FotoResourceService } from './fotoResource.service';
export * from './fuehrungsebeneMassnahmeResource.service';
import { FuehrungsebeneMassnahmeResourceService } from './fuehrungsebeneMassnahmeResource.service';
export * from './fuehrungsebeneResource.service';
import { FuehrungsebeneResourceService } from './fuehrungsebeneResource.service';
export * from './gebaeudeResource.service';
import { GebaeudeResourceService } from './gebaeudeResource.service';
export * from './gebietResource.service';
import { GebietResourceService } from './gebietResource.service';
export * from './gefahrResource.service';
import { GefahrResourceService } from './gefahrResource.service';
export * from './lageEinsatztagebuchResource.service';
import { LageEinsatztagebuchResourceService } from './lageEinsatztagebuchResource.service';
export * from './lageMassnahmeResource.service';
import { LageMassnahmeResourceService } from './lageMassnahmeResource.service';
export * from './lageResource.service';
import { LageResourceService } from './lageResource.service';
export * from './lageberichtResource.service';
import { LageberichtResourceService } from './lageberichtResource.service';
export * from './lagebesprechungResource.service';
import { LagebesprechungResourceService } from './lagebesprechungResource.service';
export * from './mapConfigResource.service';
import { MapConfigResourceService } from './mapConfigResource.service';
export * from './meldungResource.service';
import { MeldungResourceService } from './meldungResource.service';
export * from './personResource.service';
import { PersonResourceService } from './personResource.service';
export * from './personenschadenResource.service';
import { PersonenschadenResourceService } from './personenschadenResource.service';
export * from './simulationResource.service';
import { SimulationResourceService } from './simulationResource.service';
export * from './stabMassnahmeResource.service';
import { StabMassnahmeResourceService } from './stabMassnahmeResource.service';
export * from './stelleResource.service';
import { StelleResourceService } from './stelleResource.service';
export * from './taktischeFormationResource.service';
import { TaktischeFormationResourceService } from './taktischeFormationResource.service';
export * from './taktischeZeichenResource.service';
import { TaktischeZeichenResourceService } from './taktischeZeichenResource.service';
export * from './tierschadenResource.service';
import { TierschadenResourceService } from './tierschadenResource.service';
export * from './wetterResource.service';
import { WetterResourceService } from './wetterResource.service';
export const APIS = [AnlassResourceService, AnwesenheitResourceService, ApplicationSettingsResourceService, AuthenticationResourceService, BefehlsstelleResourceService, BibliothekResourceService, EinsatzResourceService, EinsatzfilterResourceService, FahrzeugResourceService, FotoResourceService, FuehrungsebeneMassnahmeResourceService, FuehrungsebeneResourceService, GebaeudeResourceService, GebietResourceService, GefahrResourceService, LageEinsatztagebuchResourceService, LageMassnahmeResourceService, LageResourceService, LageberichtResourceService, LagebesprechungResourceService, MapConfigResourceService, MeldungResourceService, PersonResourceService, PersonenschadenResourceService, SimulationResourceService, StabMassnahmeResourceService, StelleResourceService, TaktischeFormationResourceService, TaktischeZeichenResourceService, TierschadenResourceService, WetterResourceService];
