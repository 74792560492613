import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { AnlassDTO, FuehrungsebeneDTO } from 'src/app/api/build/openapi';
import { FuehrungsebeneIconAndTextComponent } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { TaktischesZeichenPopup } from '../../taktische-zeichen.interface';
import { TzDialogService } from '../../tz-dialog.service';
import { TzPopupToolbarComponent } from '../../tz-popup-toolbar/tz-popup-toolbar.component';

@Component({
  selector: 'app-anlass-popup',
  standalone: true,
  imports: [CommonModule, MatBadgeModule, FuehrungsebeneIconAndTextComponent, TzPopupToolbarComponent, MatIconModule],
  templateUrl: './anlass-popup.component.html',
  styleUrls: ['./anlass-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnlassPopupComponent implements OnInit, TaktischesZeichenPopup {
  @Input({ required: true }) dto!: AnlassDTO;

  private fuehrungsebeneService = inject(FuehrungsebeneService);

  protected parentFuehrungsebene?: FuehrungsebeneDTO;
  private tzDialogService = inject(TzDialogService);

  ngOnInit(): void {
    this.parentFuehrungsebene = this.fuehrungsebeneService
      .getAllFuehrungsebenen()
      .find((fuehrungsebeneDTO) => fuehrungsebeneDTO.id === this.dto.fuehrungsebeneId);
  }

  moveDto() {
    this.tzDialogService.openTzMoveDialog([this.dto], false);
  }

  editDto() {
    this.tzDialogService.openEditTzDialog(this.dto);
  }
}
