import { inject, Pipe, PipeTransform } from '@angular/core';
import { AuftragDTO } from 'src/app/api/build/openapi';
import { BefehlTypPipe } from './befehl-typ.pipe';

@Pipe({
  name: 'auftrag',
  standalone: true,
})
export class AuftragPipe implements PipeTransform {
  private befehlTypPipe = inject(BefehlTypPipe);

  transform(auftragDto: AuftragDTO): string {
    if (!auftragDto) {
      return '';
    }

    return `${this.befehlTypPipe.transform(auftragDto.befehlTyp)}: ${auftragDto.name}`;
  }
}
