import { CdkDropListGroup } from '@angular/cdk/drag-drop';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, DestroyRef, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { Geometry } from 'geojson';
import * as L from 'leaflet';
import { control, layerGroup } from 'leaflet';
import { Observable, Subscription, interval } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { AppService, ApplicationType } from 'src/app/app.service';
import { LayerConfigService } from 'src/app/planung/karte/layer-config.service';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { TaktischeZeichenService } from '../../taktische-zeichen/taktische-zeichen.service';
import { currentFuehrungsebeneSelector } from '../fuehrungsebene/+state/fuehrungsebene.selectors';
import { FuehrungsebeneService } from '../fuehrungsebene/fuehrungsebene.service';
import { LayerService } from '../layer.service';
import {
  CreateTzDialogData,
  TaktischeZeichenDialogComponent,
} from '../taktische-zeichen/taktische-zeichen-dialog/taktische-zeichen-dialog.component';
import { Tool, ToolType } from '../tool.interface';
import { ToolService } from '../tool.service';
import { MapFuehrungsebeneRenderer } from './map-fuehrungsebene-renderer';
import { MapHandler } from './map-handler';
import { ZeichenLeisteComponent } from './zeichen-leiste/zeichen-leiste.component';

/**
 * Item, das einen OverlayConfig-Leafletlayer mit einer Refresh-Subscription bündelt, um die Verwaltung in einer Map zu vereinfachen.
 */
interface OverlayMapItem {
  layer: L.Layer;
  refreshSubscription?: Subscription;
}

@Component({
  selector: 'app-base-map',
  templateUrl: './base-map.component.html',
  styleUrls: ['./base-map.component.scss'],
  standalone: true,
  imports: [CdkDropListGroup, ZeichenLeisteComponent, NgIf, MatCardModule, AsyncPipe, MatMenuModule],
})
export class BaseMapComponent implements OnInit {
  ApplicationType = ApplicationType;

  private destroyRef = inject(DestroyRef);
  public linesVisible = true;

  private map: L.Map | undefined;
  private currentTileLayer: L.TileLayer | undefined;

  private overlayLayers: L.LayerGroup = layerGroup();
  private overlayMapping = new Map<number, OverlayMapItem>();

  @ViewChild(MatMenuTrigger)
  contextMenuTrigger!: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  contextMenu: Element | null = null;

  // LatLng der geklickten ContextMenu Position zwischenspeichern
  contextMenuPoint?: L.Point;
  contextMenuLatLngText = '';

  private selectedTool: Tool | null = null;
  private rememberedGeometry?: Geometry;

  protected taktischeZeichenInfoMapping = this.taktischeZeichenService.taktischeZeichenInfoMapping;
  private clipboardService = inject(ClipboardService);
  private dialog = inject(MatDialog);

  private appService = inject(AppService);
  protected selectedApp$: Observable<ApplicationType | undefined> = this.appService.selectedApplicationObservable();

  constructor(
    private store: Store<AppStateInterface>,
    private mapHandler: MapHandler,
    private el: ElementRef,
    protected toolService: ToolService,
    private layerService: LayerService,
    private layerConfigService: LayerConfigService,
    private taktischeZeichenService: TaktischeZeichenService,
    private fuehrungsebeneRenderer: MapFuehrungsebeneRenderer,
    private fuehrungsebeneService: FuehrungsebeneService
  ) {}

  ngOnInit(): void {
    this.initializeMap();

    this.contextMenu = document.querySelector('#context-menu');
    // Listener, der offenes Contextmenu schließt, wenn irgendwo außerhalb geklickt
    document.addEventListener('click', (event) => {
      const target = event.target as Node;
      if (!this.contextMenu?.contains(target)) {
        this.closeContextMenuWhenOpen();
      }
    });

    this.toolService.selectedTool$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((selectedTool) => {
      this.selectedTool = selectedTool;
    });

    // Draggen/Zoomen der Karte de-/aktivieren
    this.layerService.mapLocked$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((locked) => {
      if (locked) {
        this.map?.dragging.disable();
        this.map?.scrollWheelZoom.disable();
        this.map?.zoomControl.remove();
        this.map?.doubleClickZoom.disable();
      } else {
        this.map?.dragging.enable();
        this.map?.scrollWheelZoom.enable();
        this.map?.zoomControl.addTo(this.map);
        this.map?.doubleClickZoom.enable();
      }
    });
  }

  initializeMap() {
    const mapDiv = this.el.nativeElement.children[0].children[0];
    this.map = L.map(mapDiv);
    this.overlayLayers.addTo(this.map);

    this.layerConfigService.selectedBaseLayerConfig$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((baseLayerConfig) => {
        if (this.currentTileLayer) {
          this.map?.removeLayer(this.currentTileLayer);
        }

        if (!baseLayerConfig) {
          return;
        }

        this.currentTileLayer = this.layerConfigService.createTileLayerFromConfig(baseLayerConfig);

        if (this.currentTileLayer) {
          this.map?.addLayer(this.currentTileLayer);
        }
      });

    this.layerConfigService.selectedOverlayConfigs$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((overlayConfigs) => {
        // Alle aktuell gezeichneten Layer durchgehen
        for (const overlayId of this.overlayMapping.keys()) {
          if (!overlayConfigs.find((config) => config.id === overlayId)) {
            const layerItem = this.overlayMapping.get(overlayId);
            if (layerItem) {
              this.overlayLayers.removeLayer(layerItem.layer);
              this.map?.removeLayer(layerItem.layer);
              layerItem.refreshSubscription?.unsubscribe();
              this.overlayMapping.delete(overlayId);
            }
          }
        }

        for (const config of overlayConfigs) {
          if (!config.id) {
            console.warn('Overlay Config kann nicht gezeichnet werden, da sie keine ID besitzt.');
            return;
          }

          // Wenn Config schon gezeichnet ist, mit der nächsten Config weitermachen
          if (this.overlayMapping.has(config.id)) {
            continue;
          }

          const overlayLayer = this.layerConfigService.createTileLayerFromConfig(config);
          if (!overlayLayer) {
            console.warn(`OverlayConfig '${config.name}' konnte nicht erzeugt werden.`);
            continue;
          }
          const configMapItem: OverlayMapItem = {
            layer: overlayLayer,
          };

          this.overlayLayers.addLayer(overlayLayer);

          // opt redraw initialisieren
          if (config.refreshMs) {
            const sub = interval(config.refreshMs)
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(() => {
                overlayLayer.redraw();
              });

            configMapItem.refreshSubscription = sub;
          }
          this.overlayMapping.set(config.id, configMapItem);
        }
      });

    this.mapHandler.init(this.map);
    this.fuehrungsebeneRenderer.init(this.map);
    // Resize anstoßen, damit Karte gestreckt wird (Liegt an Einbettung in Mat-Tab)
    window.dispatchEvent(new Event('resize'));

    // ContextMenu schließen, sobald gezoomt oder gedragt wird
    this.map.on('zoomstart', () => this.closeContextMenuWhenOpen());
    this.map.on('dragstart', () => this.closeContextMenuWhenOpen());

    /**
     * Wenn das Tab z.B. auf "Organigramm" gewechselt wird, wird die Karte auf 0x0 Pixel reduziert.
     * Nachdem zurück auf dieses Tab gewechselt wird, muss wieder der richtige Kartenausschnitt fokussiert werden
     */
    this.map.on('resize', (event) => {
      if (event.oldSize.x === 0 && event.oldSize.y === 0 && this.rememberedGeometry) {
        this.fitMapToGeometry(this.rememberedGeometry);
        this.rememberedGeometry = undefined;
      }
    });

    this.map.on('contextmenu', (event) => {
      this.onContextMenu(event);
    });

    const currentLage = this.fuehrungsebeneService.getCurrentLage();
    if (currentLage?.geometry) {
      MapHandler.setMapViewToGeometry(this.map, currentLage.geometry as GeoJSON.Geometry);
    }

    this.store
      .select(currentFuehrungsebeneSelector)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((currentFuehrungsebene) => {
        this.fitMapToGeometry(currentFuehrungsebene?.geometry as GeoJSON.Geometry);
      });

    control
      .scale({
        imperial: false,
      })
      .addTo(this.map);
  }

  /**
   * Positioniert die Karte auf übergebene Geometrie:
   * - Ist Geometrie ein Punkt, wird dieser anvisiert
   * - Ist Geometrie ein Polygon, wird die Karte auf dessen Bounds gesetzt
   */
  private fitMapToGeometry(geometry?: Geometry) {
    if (this.map && geometry) {
      // Wenn Karte gerade nicht sichtbar, Geometrie merken und positionieren, sobald sichtbar
      if (this.map.getSize().x === 0 && this.map.getSize().y === 0) {
        this.rememberedGeometry = geometry;
      }
      MapHandler.setMapViewToGeometry(this.map, geometry);
    }
  }

  /**
   * ContextMenu an Rechtsklick-Position öffnen und ggf. aktuelles ContextMenu schließen
   */
  onContextMenu(event: L.LeafletMouseEvent) {
    this.closeContextMenuWhenOpen();
    this.contextMenuPosition.x = event.originalEvent.x + 'px';
    this.contextMenuPosition.y = event.originalEvent.y + 'px';

    if (!this.map) {
      return;
    }
    this.contextMenuPoint = L.point(event.latlng.lng, event.latlng.lat);
    this.contextMenuLatLngText = event.latlng.lat.toFixed(6) + ', ' + event.latlng.lng.toFixed(6);

    if (this.selectedTool?.type === ToolType.Cursor) {
      // 50ms delay, da Menu sonst flackert
      setTimeout(() => this.contextMenuTrigger.openMenu(), 50);
    }
  }

  /**
   * ContextMenu schließen, wenn es offen ist
   */
  closeContextMenuWhenOpen() {
    if (this.contextMenuTrigger && this.contextMenuTrigger.menuOpen) {
      this.contextMenuTrigger.closeMenu();
    }
  }

  copyCoordinateToClipboard() {
    this.clipboardService.copy(this.contextMenuLatLngText);
  }

  toggleLineVisibility() {
    this.linesVisible = !this.linesVisible;
    this.taktischeZeichenService.setLineVisiblity(this.linesVisible);
  }

  openTzDialog(tzTyp: TaktischesZeichenTyp) {
    if (!this.contextMenuPoint) {
      console.warn('Keine Koordinate für die Erzeugung eines TZs gefunden');
      return;
    }

    const geometry: GeoJSON.Point = {
      type: 'Point',
      coordinates: [this.contextMenuPoint.x, this.contextMenuPoint.y],
    };

    const currentFuehrungsebene = this.fuehrungsebeneService.getCurrentFuehrungsebene();
    if (!currentFuehrungsebene) {
      console.warn('Keine Führungsebene ausgewählt');
      return;
    }

    const inputData: CreateTzDialogData = {
      taktischesZeichenTyp: tzTyp,
      geometry: geometry,
      fuehrungsebeneId: currentFuehrungsebene.id,
      state: this.fuehrungsebeneService.fuehrungsebenentypStatusMapping.get(currentFuehrungsebene.typ),
    };

    return this.dialog.open(TaktischeZeichenDialogComponent, {
      data: inputData,
      disableClose: false,
    });
  }
}
