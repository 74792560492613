import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, GebietDTO } from 'src/app/api/build/openapi';

export const gebietActions = createActionGroup({
  source: 'Gebiete',
  events: {
    'Reset Store': emptyProps(),

    'Get Gebiete': props<{ lageId: string }>(),
    'Get Gebiete Success': props<{ gebietDTOs: GebietDTO[]; lageId: string }>(),
    'Get Gebiete Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Gebiet': props<{ lageId: string; gebietDTO: GebietDTO }>(),
    'Create Gebiet Success': props<{ createdGebietDTO: GebietDTO }>(),
    'Create Gebiet Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Gebiet': props<{ lageId: string; gebietDTO: GebietDTO }>(),
    'Patch Gebiet Success': props<{ patchedGebietDTO: GebietDTO }>(),
    'Patch Gebiet Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Gebiet Success': props<{ deletedGebietDTO: GebietDTO }>(),
  },
});
