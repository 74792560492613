<app-tz-popup-toolbar [tzDto]="dto" (editClicked)="editDto()" (moveClicked)="moveDto()" />

<div class="content">
  <app-fuehrungsebene-icon-and-text *ngIf="parentFuehrungsebene" [fuehrungsebeneDTO]="parentFuehrungsebene" />

  <div class="row">
    <div class="links">
      <ng-container *ngIf="dto.tagebuch && dto.tagebuch.length">
        <mat-icon [matBadge]="dto.tagebuch.length" aria-hidden="false"> menu_book </mat-icon>
        <span>Tagebuch</span>
      </ng-container>
    </div>

    <img class="zeichen" [src]="dto.dataUrl" />

    <div class="rechts"></div>
  </div>

  <div class="unten">
    <ng-container *ngIf="dto.erledigt">
      <span>Erledigt</span>
      <span *ngIf="erledigtDurchRolleText">{{ erledigtDurchRolleText }}</span>
      <span *ngIf="erledigtDurchPerson">{{ erledigtDurchPerson.anzeigename }}</span>
      <span *ngIf="dto.erledigtDurchDatum">{{ dto.erledigtDurchDatum | dateTime | async }}</span>
    </ng-container>

    <ng-container *ngIf="!dto.erledigt">
      <span>Unerledigt</span>
      <span *ngIf="zuErledigenSachgebietText">{{ zuErledigenSachgebietText }}</span>
      <span *ngIf="dto.zuErledigenDatum">{{ dto.zuErledigenDatum | dateTime | async }}</span>
    </ng-container>
  </div>

  <span>
    {{ dto.beschreibung }}
  </span>
</div>
