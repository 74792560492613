<mat-toolbar>
  <h2 class="toolbar-center">
    {{ "Lage" + (lageDTO.id ? " bearbeiten" : " hinzufügen") }}
    <mat-icon *ngIf="lageDTO.lageStatus === LageStatus.Beendet" matTooltip="Lage ist beendet">lock</mat-icon>
  </h2>
</mat-toolbar>

<mat-dialog-content>
  <app-fuehrungsebene-details-panel [fuehrungsebeneDTO]="lageDTO">
    <app-chip-list-input [control]="fcStichworte" label="Stichworte" itemName="Stichworte" class="mb-1" />
  </app-fuehrungsebene-details-panel>
</mat-dialog-content>

<div class="footer">
  <app-last-updated *ngIf="lageDTO.id" [lastUpdated]="lageDTO" />
  <mat-dialog-actions class="end">
    <button
      mat-raised-button
      *ngIf="lageDTO.id"
      color="warn"
      (click)="endLage()"
      matTooltip="Lage beenden"
      [disabled]="lageDTO.lageStatus === LageStatus.Beendet || isClosingLage"
    >
      Beenden
    </button>
    <button mat-raised-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="accent" [disabled]="isClosingLage || (isSaving$ | async)" (click)="save()">
      Speichern
    </button>
  </mat-dialog-actions>
</div>
