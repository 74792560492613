import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, FuehrungsebeneMassnahmeDTO } from 'src/app/api/build/openapi';

export const fuehrungsebeneMassnahmeActions = createActionGroup({
  source: 'FuehrungsebeneMassnahmen',
  events: {
    'Reset Store': emptyProps(),

    'Get Fuehrungsebene Massnahmen': props<{ lageId: string }>(),
    'Get Fuehrungsebene Massnahmen Success': props<{
      fuehrungsebeneMassnahmeDTOs: FuehrungsebeneMassnahmeDTO[];
      lageId: string;
    }>(),
    'Get Fuehrungsebene Massnahmen Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Fuehrungsebene Massnahme': props<{
      lageId: string;
      fuehrungsebeneMassnahmeDTO: FuehrungsebeneMassnahmeDTO;
    }>(),
    'Create Fuehrungsebene Massnahme Success': props<{
      createdFuehrungsebeneMassnahmeDTO: FuehrungsebeneMassnahmeDTO;
    }>(),
    'Create Fuehrungsebene Massnahme Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Fuehrungsebene Massnahme': props<{
      lageId: string;
      fuehrungsebeneMassnahmeDTO: FuehrungsebeneMassnahmeDTO;
    }>(),
    'Patch Fuehrungsebene Massnahme Success': props<{
      patchedFuehrungsebeneMassnahmeDTO: FuehrungsebeneMassnahmeDTO;
    }>(),
    'Patch Fuehrungsebene Massnahme Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Fuehrungsebene Massnahme Success': props<{
      deletedFuehrungsebeneMassnahmeDTO: FuehrungsebeneMassnahmeDTO;
    }>(),
  },
});
