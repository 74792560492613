<app-tz-popup-toolbar [tzDto]="dto" (editClicked)="editDto()" (moveClicked)="moveDto()" />

<div class="content">
  <app-fuehrungsebene-icon-and-text *ngIf="parentFuehrungsebene" [fuehrungsebeneDTO]="parentFuehrungsebene" />

  <div class="row">
    <div class="links"></div>

    <img class="zeichen" [src]="dto.dataUrl" />

    <div class="rechts">
      <span>{{ gebietstyp }}</span>
      <span>{{ gebietsauspraegung }}</span>
    </div>
  </div>

  <div class="unten">
    {{ dto.ortsangabe }}
  </div>
</div>
