import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { FuehrungsebeneService } from '../lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { IseSourceIdService } from './ise-source-id.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private fuehrungsebeneService = inject(FuehrungsebeneService);

  constructor(private router: Router, private sourceIdService: IseSourceIdService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // TODO check URL!!!!!

    let headers = request.headers.set('X-Requested-With', 'JavaScript');

    const sourceId = this.sourceIdService.getIseSourceId();
    if (sourceId) {
      headers = headers.set('ise-source-id', sourceId);
    }

    // Aktuelle LageId in Header senden, um sie im Backend auswerten zu können
    const currentLageId = this.fuehrungsebeneService.getCurrentLage()?.id;
    if (currentLageId) {
      headers = headers.set('ise-current-lage-id', currentLageId);
    }

    const req = request.clone({ headers });
    return next.handle(req).pipe(catchError((resp) => this.handleError(resp)));
  }

  handleError(resp: HttpErrorResponse) {
    // 403 Forbidden - auch für beendete Lagen verwendet
    if (resp.status === 401 || resp.status === 499) {
      this.router.navigate(['/login']);
    }

    return throwError(() => resp);
  }
}
