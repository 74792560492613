<mat-toolbar>
  <h2>[Foto] {{ fotoDTO?.anzeigename }}</h2>
</mat-toolbar>

<mat-dialog-content>
  <img [src]="fotoDTO?.fotoBase64" />
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="accent">Schließen</button>
</mat-dialog-actions>
