import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { ErrorResponse, FotoPreviewDTO, FotoResourceService } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { fotoActions } from './foto.actions';

@Injectable()
export class FotoEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Fotos anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return fotoActions.getFotos({ lageId: props.currentLage.id });
        } else {
          return fotoActions.resetStore();
        }
      })
    )
  );

  /**
   * Alle Fotos einer Lage als Previews (ohne Bilddaten) laden
   */
  getFotos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fotoActions.getFotos),
      mergeMap((props) => {
        return this.fotosResourceService.getFotoPreviewsByLageId(props.lageId).pipe(
          map((fotos: FotoPreviewDTO[]) => fotoActions.getFotosSuccess({ fotoDTOs: fotos, lageId: props.lageId })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(fotoActions.getFotosFailure({ errorResponse }));
          })
        );
      })
    )
  );

  /**
   * Neues Foto anlegen
   */
  createFoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fotoActions.createFoto),
      mergeMap((props) =>
        this.fotosResourceService.createFoto(props.lageId, props.fotoDTO).pipe(
          map((createdFotoDTO) => fotoActions.createFotoSuccess({ createdFotoDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(fotoActions.createFotoFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Existierendes Foto editieren.
   */
  patchFoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fotoActions.patchFoto),
      mergeMap((props) => {
        if (!props.fotoDTO.id) {
          return throwError(() => new Error('Foto muss eine Id besitzen.'));
        }
        return this.fotosResourceService.patchFoto(props.fotoDTO.id, props.lageId, props.fotoDTO).pipe(
          map((patchedFotoDTO) => fotoActions.patchFotoSuccess({ patchedFotoDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(fotoActions.patchFotoFailure({ errorResponse }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private fotosResourceService: FotoResourceService) {}
}
