<mat-toolbar>
  <h2>Maßnahme beenden</h2>
</mat-toolbar>

<mat-card class="container">
  <mat-dialog-content>
    <p>Bitte Person erfassen, die die Maßnahme beendet hat</p>
    <form [formGroup]="formGroup">
      <mat-form-field appearance="outline">
        <mat-label>Datum/Uhrzeit</mat-label>
        <input matInput type="datetime-local" formControlName="datum" />
        <mat-error>{{ getErrorMessage("datum") }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Rolle</mat-label>
        <mat-select formControlName="rolle">
          <mat-option [value]="role.value" *ngFor="let role of Rolle | keyvalue">{{
            roleTextMapping.get(role.value)
          }}</mat-option>
        </mat-select>
        <mat-error>{{ getErrorMessage("rolle") }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" />
        <mat-error>{{ getErrorMessage("name") }}</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
</mat-card>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="onClickBeenden()" [disabled]="isSaving">Maßnahme beenden</button>
</mat-dialog-actions>
