<app-tz-popup-toolbar [tzDto]="dto" (editClicked)="editDto()" (moveClicked)="moveDto()" />

<div class="content">
  <app-fuehrungsebene-icon-and-text *ngIf="parentFuehrungsebene" [fuehrungsebeneDTO]="parentFuehrungsebene" />

  <div class="row">
    <div class="links"></div>

    <img class="zeichen" [src]="dto.dataUrl" />

    <div class="rechts"></div>
  </div>

  <div class="unten">
    <span>{{ dto.ortsangabe }}</span>
  </div>

  <!-- Kontakte -->
  <app-kontakt-icons *ngIf="dto.kommunikationOptionen" [kontaktmoeglichkeiten]="dto.kommunikationOptionen" />

  <!-- Aufträge -->
  <app-auftrag-header-element *ngIf="dto.auftraege" [auftraege]="dto.auftraege"></app-auftrag-header-element>
</div>
