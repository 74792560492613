import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Store } from '@ngrx/store';
import { ErrorService } from '@product/ise-error-lib';
import { BehaviorSubject, take } from 'rxjs';
import { EinsatztagebucheintragDTO, LageDTO } from 'src/app/api/build/openapi';
import { AuthService } from 'src/app/auth/auth.service';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { einsatztagebuchActions } from '../+state/einsatztagebuch.actions';

export interface EinsatztagebucheintragDialogData {
  eintragDTO: EinsatztagebucheintragDTO;
}

@Component({
  selector: 'app-einsatztagebucheintrag-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './einsatztagebucheintrag-dialog.component.html',
  styleUrls: ['./einsatztagebucheintrag-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinsatztagebucheintragDialogComponent {
  private store = inject(Store);
  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private errorService = inject(ErrorService);
  protected isSaving = false;

  username$ = new BehaviorSubject<string>('');
  eintragDTO: EinsatztagebucheintragDTO;

  formBuilder = inject(NonNullableFormBuilder);
  eintragText = this.formBuilder.control<string>('', [Validators.required, Validators.maxLength(2048)]);

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: EinsatztagebucheintragDialogData,
    private dialogRef: MatDialogRef<EinsatztagebucheintragDialogComponent>,
    authService: AuthService
  ) {
    this.eintragDTO = dialogData.eintragDTO;
    if (this.eintragDTO.revisions) {
      const revisions = [...this.eintragDTO.revisions].reverse();
      this.eintragText.setValue(revisions[0].message || '');
    }

    authService.userInfo$.pipe(take(1)).subscribe((userinfo) => {
      const username = userinfo.name || userinfo.upn || userinfo.nickname;
      if (username) {
        this.username$.next(username);
      }
    });
  }

  private validate(): boolean {
    if (this.eintragText.valid) {
      return true;
    }
    this.eintragText.markAsTouched();
    return false;
  }

  save() {
    if (!this.validate() || this.isSaving) {
      return;
    }
    this.isSaving = true;

    const eintrag: EinsatztagebucheintragDTO = { ...this.eintragDTO, message: this.eintragText.value.trim() };
    const lage: LageDTO | null = this.fuehrungsebeneService.getCurrentLage();
    if (!eintrag.id && lage) {
      eintrag.sourceId = lage.id!;
      this.store.dispatch(einsatztagebuchActions.createEinsatztagebucheintrag({ newEintrag: eintrag }));
    } else {
      this.store.dispatch(einsatztagebuchActions.patchEinsatztagebucheintrag({ patchedEintrag: eintrag }));
    }
    this.dialogRef.close();
  }

  getErrorMessage(): string {
    return this.errorService.getErrorMessage(this.eintragText.errors);
  }
}
