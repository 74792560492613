import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  AnalogBand,
  AnalogBetriebsart,
  AnalogRelaisbetrieb,
  AnalogTonruf,
  DigitalBetriebsart,
  Funktechnik,
  Kommunikation,
  KontaktTyp,
} from 'src/app/api/build/openapi';
import { KontaktService } from '../kontakt.service';

export interface KontaktDetailsDialogInputData {
  newContact: boolean;
  kommunikation: Kommunikation;
}

@Component({
  selector: 'app-kontakt-details-dialog',
  templateUrl: './kontakt-details-dialog.component.html',
  styleUrls: ['./kontakt-details-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class KontaktDetailsDialogComponent {
  KontaktTyp = KontaktTyp;
  Funktechnik = Funktechnik;
  DigitalBetriebsart = DigitalBetriebsart;
  AnalogBand = AnalogBand;
  AnalogBetriebsart = AnalogBetriebsart;
  AnalogRelaisbetrieb = AnalogRelaisbetrieb;
  AnalogTonruf = AnalogTonruf;

  // Model
  kommunikation: Kommunikation;
  newContact = false;

  // Namen der Attribute von "Kommunikation" Entity
  readonly name = 'name';
  readonly kontaktTyp = 'kontaktTyp';
  readonly mobiltelefon = 'mobiltelefon';
  readonly festnetz = 'festnetz';
  readonly fax = 'fax';
  readonly email = 'email';
  readonly funktechnik = 'funktechnik';
  readonly digitalBetriebsart = 'digitalBetriebsart';
  readonly digitalFunkrufgruppe = 'digitalFunkrufgruppe';
  readonly analogBand = 'analogBand';
  readonly analogBetriebsart = 'analogBetriebsart';
  readonly analogRelaisbetrieb = 'analogRelaisbetrieb';
  readonly analogTonruf = 'analogTonruf';
  readonly analogFunkkanal = 'analogFunkkanal';
  readonly funkrufname = 'funkrufname';

  private readonly allControlNames = [
    this.name,
    this.kontaktTyp,
    this.mobiltelefon,
    this.festnetz,
    this.fax,
    this.email,
    this.funktechnik,
    this.digitalBetriebsart,
    this.digitalFunkrufgruppe,
    this.analogBand,
    this.analogBetriebsart,
    this.analogRelaisbetrieb,
    this.analogTonruf,
    this.analogFunkkanal,
    this.funkrufname,
  ];

  protected formGroup: FormGroup;
  protected isSaving = false;

  constructor(
    private dialogRef: MatDialogRef<KontaktDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KontaktDetailsDialogInputData,
    protected kontaktService: KontaktService
  ) {
    // Werte aus DialogData auslesen
    this.kommunikation = data.kommunikation;
    this.newContact = data.newContact;

    this.formGroup = new FormGroup({
      [this.name]: new FormControl<string>(this.kommunikation.name || '', Validators.required),
      [this.kontaktTyp]: new FormControl<KontaktTyp | undefined>(this.kommunikation.kontaktTyp, Validators.required),
      [this.mobiltelefon]: new FormControl<string>(this.kommunikation.mobiltelefon || ''),
      [this.festnetz]: new FormControl<string>(this.kommunikation.festnetz || ''),
      [this.fax]: new FormControl<string>(this.kommunikation.fax || ''),
      [this.email]: new FormControl<string>(this.kommunikation.email || '', Validators.email),
      [this.funktechnik]: new FormControl<Funktechnik | undefined>(this.kommunikation.funktechnik),
      [this.digitalBetriebsart]: new FormControl<DigitalBetriebsart | undefined>(this.kommunikation.digitalBetriebsart),
      [this.digitalFunkrufgruppe]: new FormControl<string>(this.kommunikation.digitalFunkrufgruppe || ''),
      [this.analogBand]: new FormControl<AnalogBand | undefined>(this.kommunikation.analogBand),
      [this.analogBetriebsart]: new FormControl<AnalogBetriebsart | undefined>(this.kommunikation.analogBetriebsart),
      [this.analogRelaisbetrieb]: new FormControl<AnalogRelaisbetrieb | undefined>(
        this.kommunikation.analogRelaisbetrieb
      ),
      [this.analogTonruf]: new FormControl<AnalogTonruf | undefined>(this.kommunikation.analogTonruf),
      [this.analogFunkkanal]: new FormControl<string>(this.kommunikation.analogFunkkanal || ''),
      [this.funkrufname]: new FormControl<string>(this.kommunikation.funkrufname || ''),
    });

    this.formGroup
      .get(this.kontaktTyp)
      ?.valueChanges.pipe(takeUntilDestroyed())
      .subscribe((kontaktTyp: KontaktTyp) => {
        this.clearControlsExceptFor([]);
        switch (kontaktTyp) {
          case KontaktTyp.Mobiltelefon:
            this.formGroup.get(this.mobiltelefon)?.setValidators(Validators.required);
            break;
          case KontaktTyp.Festnetz:
            this.formGroup.get(this.festnetz)?.setValidators(Validators.required);
            break;
          case KontaktTyp.Fax:
            this.formGroup.get(this.fax)?.setValidators(Validators.required);
            break;
          case KontaktTyp.Email:
            this.formGroup.get(this.email)?.setValidators([Validators.required, Validators.email]);
            break;
          case KontaktTyp.Funk:
            this.formGroup.get(this.funktechnik)?.setValidators(Validators.required);
            this.formGroup.get(this.funktechnik)?.setValue(Funktechnik.Digital);
            this.formGroup.get(this.funkrufname)?.setValidators(Validators.required);
            break;
        }
      });

    /**
     * Wenn sich die Funktechnik ändert, nur noch die Felder dieser Funktechnik verpflichtend machen
     * Wenn Funktechnik entfernt wurde, auch Funkrufnamen nicht mehr verpflichtend machen
     */
    this.formGroup
      .get(this.funktechnik)
      ?.valueChanges.pipe(takeUntilDestroyed())
      .subscribe((funktechnik: Funktechnik) => {
        this.clearControlsExceptFor([this.funktechnik, this.funkrufname]);
        switch (funktechnik) {
          case Funktechnik.Digital:
            this.formGroup.get(this.digitalBetriebsart)?.setValidators(Validators.required);
            this.formGroup.get(this.digitalBetriebsart)?.setValue(DigitalBetriebsart.Dmo);
            this.formGroup.get(this.digitalFunkrufgruppe)?.setValidators(Validators.required);
            break;
          case Funktechnik.Analog:
            this.formGroup.get(this.analogBand)?.setValidators(Validators.required);
            this.formGroup.get(this.analogBand)?.setValue(AnalogBand.ZweiMeter);
            this.formGroup.get(this.analogBetriebsart)?.setValidators(Validators.required);
            this.formGroup.get(this.analogBetriebsart)?.setValue(AnalogBetriebsart.W);
            this.formGroup.get(this.analogRelaisbetrieb)?.setValidators(Validators.required);
            this.formGroup.get(this.analogRelaisbetrieb)?.setValue(AnalogRelaisbetrieb.Rs1);
            this.formGroup.get(this.analogTonruf)?.setValidators(Validators.required);
            this.formGroup.get(this.analogTonruf)?.setValue(AnalogTonruf.Tonruf1);
            this.formGroup.get(this.analogFunkkanal)?.setValidators(Validators.required);
            break;
          default:
            this.formGroup.get(this.funkrufname)?.setValidators(null);
            this.formGroup.get(this.funkrufname)?.reset();
            this.formGroup.get(this.funkrufname)?.updateValueAndValidity();
        }
      });
  }

  /**
   * Den Wert und Validatoren aller Input Felder entfernen, die nicht in der übergebenen Liste stehen.
   * Ausgenommen sind "name" und "kontaktTyp". Die werden nicht angerührt.
   */
  clearControlsExceptFor(exceptFormControlNames: string[]) {
    const controlsToClear = this.allControlNames.filter(
      (controlName) =>
        controlName !== this.name && controlName !== this.kontaktTyp && !exceptFormControlNames.includes(controlName)
    );

    controlsToClear.forEach((controlName) => {
      const control = this.formGroup.get(controlName);
      if (control) {
        control.reset();
        control.setValidators(null);
        control.updateValueAndValidity();
      }
    });
  }

  /**
   * Schreibt Werte aus der FormGroup in ein Kommunikation Model.
   * Nimmt nur die Werte, die nach Auswahl aller RadioGroups nötig sind.
   */
  formGroupToModel() {
    const kommunikation: Kommunikation = {
      name: this.formGroup.get(this.name)?.value?.trim(),
      kontaktTyp: this.formGroup.get(this.kontaktTyp)?.value,
    };

    switch (kommunikation.kontaktTyp) {
      case KontaktTyp.Mobiltelefon:
        kommunikation.mobiltelefon = this.formGroup.get(this.mobiltelefon)?.value.trim();
        break;
      case KontaktTyp.Festnetz:
        kommunikation.festnetz = this.formGroup.get(this.festnetz)?.value.trim();
        break;
      case KontaktTyp.Fax:
        kommunikation.fax = this.formGroup.get(this.fax)?.value.trim();
        break;
      case KontaktTyp.Email:
        kommunikation.email = this.formGroup.get(this.email)?.value.trim();
        break;
      case KontaktTyp.Funk:
        kommunikation.funktechnik = this.formGroup.get(this.funktechnik)?.value;
        kommunikation.funkrufname = this.formGroup.get(this.funkrufname)?.value.trim();
        switch (kommunikation.funktechnik) {
          case Funktechnik.Digital:
            kommunikation.digitalBetriebsart = this.formGroup.get(this.digitalBetriebsart)?.value;
            kommunikation.digitalFunkrufgruppe = this.formGroup.get(this.digitalFunkrufgruppe)?.value.trim();
            break;
          case Funktechnik.Analog:
            kommunikation.analogBand = this.formGroup.get(this.analogBand)?.value;
            kommunikation.analogBetriebsart = this.formGroup.get(this.analogBetriebsart)?.value;
            kommunikation.analogRelaisbetrieb = this.formGroup.get(this.analogRelaisbetrieb)?.value;
            kommunikation.analogTonruf = this.formGroup.get(this.analogTonruf)?.value;
            kommunikation.analogFunkkanal = this.formGroup.get(this.analogFunkkanal)?.value.trim();
            break;
        }
        break;
    }
    return kommunikation;
  }

  /**
   * Eingaben validieren und fehlerhafte Felder markieren.
   */
  validate() {
    if (this.formGroup.valid) {
      return true;
    }
    this.formGroup.markAllAsTouched();
    return false;
  }

  /**
   * Schließt den Dialog und liefert ein Kommunikation-Model als DialogResult zurück
   */
  save() {
    if (!this.validate() || this.isSaving) {
      return;
    }

    this.isSaving = true;
    const resultModel = this.formGroupToModel();
    this.dialogRef.close(resultModel);
  }
}
