import { Component, inject } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ErrorService } from '@product/ise-error-lib';
import { BefehlDTO } from 'src/app/api/build/openapi';

@Component({
  selector: 'app-befehl-form',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, ReactiveFormsModule],
  templateUrl: './befehl-form.component.html',
  styleUrl: './befehl-form.component.scss',
})
export class BefehlFormComponent {
  protected errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);

  formGroup = this.formBuilder.group({
    auftrag: this.formBuilder.control<string>('', [Validators.required, Validators.maxLength(2048)]),
    einheit: this.formBuilder.control<string>('', [Validators.required, Validators.maxLength(512)]),
    mittel: this.formBuilder.control<string | undefined>(undefined, Validators.maxLength(512)),
    weg: this.formBuilder.control<string | undefined>(undefined, Validators.maxLength(512)),
    ziel: this.formBuilder.control<string | undefined>(undefined, Validators.maxLength(512)),
  });

  private befehlDto?: BefehlDTO;

  setDto(befehlDto: BefehlDTO) {
    this.befehlDto = befehlDto;
    this.formGroup.controls.auftrag.setValue(befehlDto.auftrag);
    this.formGroup.controls.einheit.setValue(befehlDto.einheit);
    this.formGroup.controls.mittel.setValue(befehlDto.mittel);
    this.formGroup.controls.weg.setValue(befehlDto.weg);
    this.formGroup.controls.ziel.setValue(befehlDto.ziel);
  }

  getDto() {
    if (!this.befehlDto) {
      console.warn('Kein BefehlDTO zum Speichern verfügbar');
      return;
    }
    return {
      ...this.befehlDto,
      auftrag: this.formGroup.controls.auftrag.value.trim(),
      einheit: this.formGroup.controls.einheit.value?.trim(),
      mittel: this.formGroup.controls.mittel.value?.trim(),
      weg: this.formGroup.controls.weg.value?.trim(),
      ziel: this.formGroup.controls.ziel.value?.trim(),
    };
  }

  validate(): boolean {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return false;
    }
    return true;
  }
}
