import { Pipe, PipeTransform } from '@angular/core';
import { BefehlTyp } from 'src/app/api/build/openapi';

@Pipe({
  name: 'befehlTyp',
  standalone: true,
})
export class BefehlTypPipe implements PipeTransform {
  transform(befehlTyp: BefehlTyp): string {
    switch (befehlTyp) {
      case BefehlTyp.Befehl:
        return 'Befehl';
      case BefehlTyp.Langzeitbefehl:
        return 'Langzeitbefehl';
      case BefehlTyp.Marschbefehl:
        return 'Marschbefehl';
      default:
        return 'Unbekannt';
    }
  }
}
