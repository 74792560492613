import { Component, Inject, inject } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorService } from '@product/ise-error-lib';
import { WettermessungDTO } from 'src/app/api/build/openapi';
import { DatetimeLocalAccessorDirective } from 'src/app/shared/accessors/datetime-local-accessor.directive';
import { LageValidators } from 'src/app/shared/lage-validators';
import { WetterDialogComponent } from '../wetter-dialog/wetter-dialog.component';
import { WetterService } from '../wetter.service';

export interface WettermessungDialogData {
  wettermessung?: WettermessungDTO;
}

@Component({
  selector: 'app-wettermessung-dialog',
  templateUrl: './wettermessung-dialog.component.html',
  styleUrls: ['./wettermessung-dialog.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    DatetimeLocalAccessorDirective,
  ],
})
export class WettermessungDialogComponent {
  private wetterService: WetterService = inject(WetterService);

  private wettermessung?: WettermessungDTO;
  protected wettermessungForm = inject(NonNullableFormBuilder).group({
    datum: [new Date(), [Validators.required, LageValidators.dateTimeValidator]],
    bewoelkung: [0, [Validators.min(0), Validators.max(9), Validators.required]],
    luftdruck: [0, [Validators.min(0), Validators.required]],
    niederschlag: [0, [Validators.min(0), Validators.required]],
    sicht: [0, [Validators.min(0), Validators.required]],
    temperatur: [0, [Validators.min(-50), Validators.max(50), Validators.required]],
    windgeschwindigkeit: [0, [Validators.min(0), Validators.required]],
    windstaerke: [0, [Validators.min(0), Validators.max(12), Validators.required]],
  });
  protected newObject = true;
  protected isSaving = false;

  constructor(
    private dialog: MatDialogRef<WetterDialogComponent>,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) private data: WettermessungDialogData
  ) {
    if (data && data.wettermessung) {
      this.newObject = false;
      this.wettermessung = data.wettermessung;
      this.wettermessungForm.setValue({
        bewoelkung: data.wettermessung.bewoelkung || 0,
        datum: data.wettermessung.datum ? new Date(data.wettermessung.datum) : new Date(),
        luftdruck: data.wettermessung.luftdruck || 0,
        niederschlag: data.wettermessung.niederschlag || 0,
        sicht: data.wettermessung.sicht || 0,
        temperatur: data.wettermessung.temperatur || 0,
        windgeschwindigkeit: data.wettermessung.windgeschwindigkeit || 0,
        windstaerke: data.wettermessung.windstaerke || 0,
      });
    }
  }

  public onClickSpeichern() {
    if (!this.wettermessungForm.valid || this.isSaving) {
      return;
    }

    this.isSaving = true;
    this.dialog.close({
      ...this.wettermessungForm.getRawValue(),
      datum: this.wettermessungForm.get('datum')?.value.toISOString(),
      id: this.wettermessung?.id,
      version: this.wettermessung?.version,
    } as WettermessungDTO);
  }

  protected onChangeWindgeschwindigkeit() {
    const windgeschwindigkeit = this.wettermessungForm.value.windgeschwindigkeit;
    if (windgeschwindigkeit !== undefined) {
      this.wettermessungForm.patchValue({
        windstaerke: this.wetterService.kmhToBeaufort(windgeschwindigkeit),
      });
    }
  }

  protected getErrorMessage(formControlName: string): string {
    const formControl = this.wettermessungForm.get(formControlName);
    if (!formControl) {
      return '';
    }
    return this.errorService.getErrorMessage(formControl.errors);
  }
}
