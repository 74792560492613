import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';

@Injectable({
  providedIn: 'root',
})
export class CompareService {
  compareDateStrings(sortDir: SortDirection, dateStringA?: string, dateStringB?: string): number {
    if (sortDir === '') {
      return 0;
    }

    if (!dateStringA) {
      if (!dateStringB) {
        return 0;
      }
      return sortDir === 'asc' ? -1 : 1;
    }
    if (!dateStringB) {
      return sortDir === 'asc' ? 1 : -1;
    }
    const timeA = new Date(dateStringA).getTime();
    const timeB = new Date(dateStringB).getTime();
    return sortDir === 'asc' ? timeA - timeB : timeB - timeA;
  }

  compareStrings(sortDir: SortDirection, stringA?: string, stringB?: string) {
    if (sortDir === '') {
      return 0;
    }

    if (!stringA) {
      if (!stringB) {
        return 0;
      }
      return sortDir === 'asc' ? -1 : 1;
    }
    if (!stringB) {
      return sortDir === 'asc' ? 1 : -1;
    }
    return sortDir === 'asc' ? stringA.localeCompare(stringB) : stringB.localeCompare(stringA);
  }

  compareNumbers(sortDir: SortDirection, numberA?: number, numberB?: number) {
    if (sortDir === '') {
      return 0;
    }

    if (!numberA) {
      if (!numberB) {
        return 0;
      }
      return sortDir === 'asc' ? -1 : 1;
    }
    if (!numberB) {
      return sortDir === 'asc' ? 1 : -1;
    }
    return sortDir === 'asc' ? numberA - numberB : numberB - numberA;
  }

  isDateStringAfterNow(date?: string): boolean {
    if (!date) {
      return false;
    }
    return new Date(date) > new Date();
  }

  isDateStringBeforeNow(date?: string): boolean {
    if (!date) {
      return false;
    }
    return new Date(date) < new Date();
  }
}
