import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  EinsatztagebucheintragDialogComponent,
  EinsatztagebucheintragDialogData,
} from 'src/app/einsatztagebuch/einsatztagebucheintrag-dialog/einsatztagebucheintrag-dialog.component';
import { BreadcrumbComponent } from '../fuehrungsebene/breadcrumb/breadcrumb.component';
import { LageInfobarComponent } from '../lagen/lage-infobar/lage-infobar.component';

@Component({
  selector: 'app-lagedarstellung-toolbar',
  templateUrl: './lagedarstellung-toolbar.component.html',
  styleUrls: ['./lagedarstellung-toolbar.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    LageInfobarComponent,
    BreadcrumbComponent,
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class LagedarstellungToolbarComponent {
  constructor(private dialog: MatDialog) {}

  createTagebucheintrag() {
    const dialogData: EinsatztagebucheintragDialogData = {
      eintragDTO: {
        sourceId: '',
        message: '',
      },
    };
    this.dialog.open(EinsatztagebucheintragDialogComponent, { data: dialogData });
  }
}
