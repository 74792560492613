<form [formGroup]="formGroup">
  <mat-form-field class="less-margin">
    <mat-label>Marschbefehl</mat-label>
    <textarea matInput [formControl]="formGroup.controls.marschbefehl" rows="3"></textarea>
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.marschbefehl.errors) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Karte</mat-label>
    <input matInput [formControl]="formGroup.controls.karte" />
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.karte.errors) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Lage</mat-label>
    <textarea matInput [formControl]="formGroup.controls.lageText" rows="3"></textarea>
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.lageText.errors) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Auftrag</mat-label>
    <textarea matInput [formControl]="formGroup.controls.auftrag" rows="3"></textarea>
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.auftrag.errors) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Durchführung</mat-label>
    <textarea matInput [formControl]="formGroup.controls.durchfuehrung" rows="3"></textarea>
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.durchfuehrung.errors) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Versorgung</mat-label>
    <textarea matInput [formControl]="formGroup.controls.versorgung" rows="3"></textarea>
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.versorgung.errors) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Führung und Verbindung</mat-label>
    <textarea matInput [formControl]="formGroup.controls.fuehrungUndVerbindung" rows="3"></textarea>
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.fuehrungUndVerbindung.errors) }}</mat-error>
  </mat-form-field>
</form>
