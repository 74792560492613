import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Store } from '@ngrx/store';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { verwaltungsebenenSelector } from '../+state/bibliothek.selectors';
import { VerwaltungsebenenTreeComponent } from '../verwaltungsebenen-tree/verwaltungsebenen-tree.component';

export interface DialogData {
  taktischesZeichen: TaktischesZeichenDTO;
}

export interface BibliothekZeichenMoveOutputData {
  verwaltungsebeneId: string;
}

/**
 * Dialog, der die Verwaltungsebenen-Hierarchie abbildet.
 * Eine Verwaltungsebene kann zum Verschieben von Taktischen Zeichen ausgewählt werden
 */
@Component({
  selector: 'app-bibliothek-zeichen-move',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, VerwaltungsebenenTreeComponent, MatDialogModule, MatButtonModule],
  templateUrl: './bibliothek-zeichen-move.component.html',
  styleUrls: ['./bibliothek-zeichen-move.component.scss'],
})
export class BibliothekZeichenMoveComponent {
  verwaltungsebenen$ = this.store.select(verwaltungsebenenSelector);

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
    private dialogRef: MatDialogRef<BibliothekZeichenMoveComponent>,
    private store: Store<AppStateInterface>
  ) {}

  selectVerwaltungsebene = (verwaltungsebeneId: string) => {
    const outputData: BibliothekZeichenMoveOutputData = {
      verwaltungsebeneId,
    };
    this.dialogRef.close(outputData);
  };
}
