import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorService } from '@product/ise-error-lib';
import { FuehrungsebeneService } from '../../fuehrungsebene/fuehrungsebene.service';

export interface DialogData {
  confirmationCode: string;
}

export interface DialogResult {
  confirmationCode: string;
  reason: string;
}

/**
 * Dialog der auf die Folgen zum Beenden von Lagen hinweist.
 *
 * Fordert die Eingabe einer Begründung und eines (bekannten) Bestätigungscodes und validiert diese. Gibt das die Eingaben als Ergebnis zurück.
 *
 */
@Component({
  selector: 'app-lage-end-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
  ],
  templateUrl: './lage-end-dialog.component.html',
  styleUrl: './lage-end-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LageEndDialogComponent {
  private dialogRef = inject(MatDialogRef<LageEndDialogComponent>);
  readonly data = inject<DialogData>(MAT_DIALOG_DATA);
  private errorService = inject(ErrorService);
  readonly fuehrungsebenenService = inject(FuehrungsebeneService);
  private formBuilder = inject(NonNullableFormBuilder);

  fcBegruendung = this.formBuilder.control<string>('', [this.reasonTrimValidator(), Validators.minLength(5)]);
  fcBestaetigungscode = this.formBuilder.control<string>('', [Validators.required, this.confirmationCodeValidator()]);

  formGroup = this.formBuilder.group({ begruendung: this.fcBegruendung, code: this.fcBestaetigungscode });

  endLage() {
    const result: DialogResult = { confirmationCode: this.fcBestaetigungscode.value, reason: this.fcBegruendung.value };
    this.dialogRef.close(result);
  }

  getErrorMessage(formControl: FormControl) {
    return this.errorService.getErrorMessage(formControl.errors);
  }

  /**
   * Validierung, ob der übergebene Bestätigungscode mit der Eingabe übereinstimmt
   */
  private confirmationCodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const codeInvalid = control.value !== this.data.confirmationCode;
      // HACK
      // FIXME: "custom"-Feld in errorService erweitern
      return codeInvalid ? { 'Bestätigungscode stimmt nicht überein!': true } : null;
    };
  }

  /**
   * Stellt sicher, dass nicht nur Leerzeichen als Begründung eingetragen werden.
   */
  private reasonTrimValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const stringValue = ('' + control.value).trim();
      return !stringValue ? { required: true } : null;
    };
  }
}
