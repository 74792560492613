<div
  class="item mat-elevation-z3"
  [ngClass]="{ 'mat-elevation-z6': hovering, 'selected-tz': selected, vorlage: isVorlage }"
  (click)="click(item, $event)"
  (contextmenu)="contextmenu(item, $event)"
  (mouseover)="hovering = true"
  (mouseleave)="hovering = false"
>
  <div class="parent-badge" *ngIf="parentFormationInfoDtos.length > 1">
    <h3>{{ parentFormationInfoDtos.length }}</h3>
  </div>
  <div
    class="parent-badge"
    *ngIf="parentFormationInfoDtos.length === 1"
    [matTooltip]="'Übergeordnete Taktische Formation: ' + parentFormationInfoDtos[0].anzeigename"
  >
    <img [src]="parentFormationInfoDtos[0].dataUrl" />
  </div>

  <mat-icon color="warn" class="unavailable-badge" *ngIf="isUnavailable" matTooltip="Nicht verfügbar">cancel</mat-icon>

  <div class="zeichen-image-wrapper">
    <img [src]="item.dataUrl" />
  </div>
  <div class="zeichen-text" [matTooltip]="useDefaultTooltip ? item.anzeigename : ''" [matTooltipShowDelay]="500">
    {{ item.anzeigename }}
  </div>
</div>
