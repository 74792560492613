import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { ErrorResponse, GefahrResourceService } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { gefahrActions } from './gefahr.actions';

@Injectable()
export class GefahrEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der e anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return gefahrActions.getGefahren({ lageId: props.currentLage.id });
        } else {
          return gefahrActions.resetStore();
        }
      })
    )
  );

  /**
   * Alle Gefahren einer Lage laden
   */
  getGefahren$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gefahrActions.getGefahren),
      mergeMap((props) =>
        this.gefahrenResourceService.getGefahrenByLageId(props.lageId).pipe(
          map((gefahren) => gefahrActions.getGefahrenSuccess({ gefahrDTOs: gefahren, lageId: props.lageId })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(gefahrActions.getGefahrenFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Neue Gefahr anlegen
   */
  createGefahr$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gefahrActions.createGefahr),
      mergeMap((props) => {
        return this.gefahrenResourceService.createGefahr(props.lageId, props.gefahrDTO).pipe(
          map((createdGefahrDTO) => gefahrActions.createGefahrSuccess({ createdGefahrDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(gefahrActions.createGefahrFailure({ errorResponse }));
          })
        );
      })
    )
  );

  /**
   * Existierende Gefahr editieren
   */
  patchGefahr$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gefahrActions.patchGefahr),
      mergeMap((props) => {
        if (!props.gefahrDTO.id) {
          return throwError(() => new Error('Gefahr muss eine Id besitzen.'));
        }
        return this.gefahrenResourceService.patchGefahr(props.gefahrDTO.id, props.lageId, props.gefahrDTO).pipe(
          map((patchedGefahrDTO) => gefahrActions.patchGefahrSuccess({ patchedGefahrDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(gefahrActions.patchGefahrFailure({ errorResponse }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private gefahrenResourceService: GefahrResourceService) {}
}
