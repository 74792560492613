import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, StelleDTO } from 'src/app/api/build/openapi';

export const stelleActions = createActionGroup({
  source: 'Stellen',
  events: {
    'Reset Store': emptyProps(),

    'Get Stellen': props<{ lageId: string }>(),
    'Get Stellen Success': props<{ stelleDTOs: StelleDTO[]; lageId: string }>(),
    'Get Stellen Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Stelle': props<{ lageId: string; stelleDTO: StelleDTO }>(),
    'Create Stelle Success': props<{ createdStelleDTO: StelleDTO }>(),
    'Create Stelle Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Stelle': props<{ lageId: string; stelleDTO: StelleDTO }>(),
    'Patch Stelle Success': props<{ patchedStelleDTO: StelleDTO }>(),
    'Patch Stelle Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Stelle Success': props<{ deletedStelleDTO: StelleDTO }>(),
  },
});
