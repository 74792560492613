<mat-toolbar class="toolbar">
  <div>
    <h2>Verwaltungsebene {{ verwaltungsebeneToEdit ? "bearbeiten" : "hinzufügen" }}</h2>
    <div *ngIf="parentVerwaltungsebene" class="second-row">
      <h3>unter: {{ parentVerwaltungsebene.name }}</h3>
    </div>
  </div>

  <div>
    <button
      *ngIf="verwaltungsebeneToEdit"
      class="button"
      mat-icon-button
      matTooltip="Verwaltungsebene verschieben"
      mat-icon
      (click)="initializeMoveVerwaltungsebene()"
    >
      <mat-icon>move_down</mat-icon>
    </button>
  </div>
</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="verwaltungsebeneForm">
    <mat-form-field class="ml-05">
      <mat-label>Name</mat-label>
      <input matInput type="text" [formControl]="nameControl" matTooltip="Name der Verwaltungsebene" />
    </mat-form-field>
    <mat-form-field class="ml-05">
      <mat-label>Verwaltungsebene</mat-label>
      <mat-select [formControl]="verwaltungsebeneControl">
        <mat-option *ngFor="let option of Verwaltungsebenentyp | keyvalue : keepOrder" [value]="option.value">
          {{ verwaltungsebenenMap.get(option.value) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-kontakt-list [kommunikationOptionen]="verwaltungsebeneToEdit?.kommunikationOptionen || []" />
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <!-- div als Workaround, da TootTip bei daktivierten Buttons nicht angezeigt wird. -->
  <div class="del-workaround" [matTooltip]="tooltip">
    <button
      mat-raised-button
      color="warn"
      [disabled]="deletingDisabled || isDeleting || isSaving"
      (click)="askDelete()"
    >
      Löschen
    </button>
  </div>
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="save()" [disabled]="isDeleting || isSaving">Speichern</button>
</mat-dialog-actions>

<mat-spinner *ngIf="isDeleting" color="accent" />
