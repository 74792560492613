import { Component, inject } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TaktischesZeichenDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';

export interface TzDeleteDialogInputData {
  tzDtos: TaktischesZeichenDTO[];
  calledFromBib: boolean;
}

export interface TzDeleteDialogOutputData {
  recursive: boolean;
}

/**
 * Dialog zur Abfrage, ob ausgewählte Taktische Zeichen wirklich gelöscht werden sollen.
 *
 * Wenn unter den Taktischen Zeichen eine Taktische Formation ist, wird zusätzlich abgefragt,
 * ob dessen Untereinheiten rekursiv mitgelöscht werden sollen.
 */
@Component({
  selector: 'app-tz-delete-dialog',
  standalone: true,
  imports: [MatButtonModule, MatToolbarModule, MatDialogModule, MatCheckboxModule, ReactiveFormsModule, MatInputModule],
  templateUrl: './tz-delete-dialog.component.html',
  styleUrl: './tz-delete-dialog.component.scss',
})
export class TzDeleteDialogComponent {
  private dialogRef = inject(MatDialogRef);
  private taktischeZeichenService = inject(TaktischeZeichenService);
  private inputData: TzDeleteDialogInputData = inject(MAT_DIALOG_DATA);

  private formBuilder = inject(NonNullableFormBuilder);
  protected fcRecursive = this.formBuilder.control<boolean>(false);

  protected tzDescription = 'Taktische Zeichen';
  protected deleteVerb = 'löschen';
  protected deleteVerbCapitalized = 'Löschen';

  protected containsFormationen = false;
  protected isSaving = false;

  constructor() {
    if (this.inputData) {
      this.tzDescription = this.taktischeZeichenService.getTzTypDescription(this.inputData.tzDtos);
      this.prepareDeleteVerbs();
      this.containsFormationen = this.inputData.tzDtos.some(
        (tzDto) => tzDto.typ === TaktischesZeichenTyp.TaktischeFormation
      );
    }
  }

  private prepareDeleteVerbs() {
    this.deleteVerb = this.taktischeZeichenService.getTzsDeleteVerb(
      this.inputData.tzDtos,
      this.inputData.calledFromBib
    );

    const split = this.deleteVerb.split('/');
    this.deleteVerbCapitalized = split.map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join('/');
  }

  delete() {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;
    const result: TzDeleteDialogOutputData = {
      recursive: this.fcRecursive.value,
    };
    this.dialogRef.close(result);
  }
}
