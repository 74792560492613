import { createReducer, on } from '@ngrx/store';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { FahrzeugStateInterface } from './fahrzeug-state.interface';
import { fahrzeugActions } from './fahrzeug.actions';

export const initialState: FahrzeugStateInterface = {
  isCreating: false,
  isLoading: false,
  isPatching: false,
  fahrzeuge: [],
  loadedLageId: '',
};

export const fahrzeugReducer = createReducer(
  initialState,
  // Reset Store
  on(fahrzeugActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get Fahrzeuge
  on(fahrzeugActions.getFahrzeuge, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(fahrzeugActions.getFahrzeugeSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    fahrzeuge: action.fahrzeuge,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(fahrzeugActions.getFahrzeugeFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create Fahrzeug
  on(fahrzeugActions.createFahrzeug, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(fahrzeugActions.createFahrzeugSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    fahrzeuge: [...state.fahrzeuge, action.newFahrzeug],
    errorResponse: undefined,
  })),
  on(fahrzeugActions.createFahrzeugFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Fahrzeug
  on(fahrzeugActions.patchFahrzeug, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(fahrzeugActions.patchFahrzeugSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    // Ersetzt ein bekanntes Fahrzeug oder fügt ein Unbekanntes neu hinzu
    fahrzeuge: replaceOrKeepCurrent(state.fahrzeuge, action.patchedFahrzeug),
    errorResponse: undefined,
  })),
  on(fahrzeugActions.patchFahrzeugFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Fahrzeug
  on(fahrzeugActions.deleteFahrzeugSuccess, (state, action) => ({
    ...state,
    fahrzeuge: state.fahrzeuge.filter((fahrzeug) => fahrzeug.id !== action.deletedFahrzeugDTO.id),
  }))
);
