<mat-toolbar>
  <h2>{{ "Tagebucheintrag" + (newEntry ? " hinzufügen" : " bearbeiten") }}</h2>
</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="formGroup" class="content">
    <mat-form-field>
      <mat-label>Text</mat-label>
      <textarea matInput formControlName="freitext" rows="4"></textarea>
      <mat-error>{{ getErrorMessage("freitext") }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" [disabled]="isSaving" (click)="save()">Speichern</button>
</mat-dialog-actions>
