<form [formGroup]="formGroup">
  <mat-card>
    <mat-card-subtitle>Zeichen Informationen</mat-card-subtitle>

    <app-cleanable-form-field label="Anzeigename" [formGroup]="formGroup" formControlName="anzeigename" />

    <!-- Auswahl Organisation: Feuerwehr, Polizei, ... -->
    <mat-form-field
      class="less-margin"
      (mouseover)="organisationMouseover = true"
      (mouseleave)="organisationMouseover = false"
    >
      <mat-label>Organisation</mat-label>
      <mat-select formControlName="organisation">
        <mat-option *ngFor="let organisation of organisationenValues" [value]="organisation">
          {{ organisation.label }}
        </mat-option>
      </mat-select>
      <button
        *ngIf="fcOrganisation.value && organisationMouseover"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="fcOrganisation.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- Auswahl Fachaufgabe: Brandbekämpfung, Höhenrettung, ... -->
    <mat-form-field
      class="less-margin"
      (mouseover)="fachaufgabeMouseover = true"
      (mouseleave)="fachaufgabeMouseover = false"
    >
      <mat-label>Fachaufgabe</mat-label>
      <mat-select formControlName="fachaufgabe">
        <mat-option *ngFor="let fachaufgabe of fauchaufgabeValues" [value]="fachaufgabe">
          {{ fachaufgabe.label }}</mat-option
        >
      </mat-select>
      <button
        *ngIf="fcFachaufgabe.value && fachaufgabeMouseover"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="fcFachaufgabe.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- Auswahl Größenordnung: Gruppe, Trupp, ... -->
    <mat-form-field
      class="less-margin"
      (mouseover)="groessenordnungMouseover = true"
      (mouseleave)="groessenordnungMouseover = false"
    >
      <mat-label>Größenordnung</mat-label>
      <mat-select formControlName="groessenordnung">
        <mat-option *ngFor="let groesse of groessenordnungValues" [value]="groesse"> {{ groesse.label }}</mat-option>
      </mat-select>
      <button
        *ngIf="fcGroessenordnung.value && groessenordnungMouseover"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="fcGroessenordnung.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- Mannschaftsstärke -->
    <app-mannschaft-form />

    <!-- Auswahl Verwaltungsstufe: Gemeinde, Bezirk, ... -->
    <mat-form-field
      class="less-margin"
      (mouseover)="verwaltungsstufeMouseover = true"
      (mouseleave)="verwaltungsstufeMouseover = false"
    >
      <mat-label>Verwaltungsstufe</mat-label>
      <mat-select formControlName="verwaltungsstufe">
        <mat-option *ngFor="let verwaltungsstufe of verwaltungsstufeValues" [value]="verwaltungsstufe">
          {{ verwaltungsstufe.label }}
        </mat-option>
      </mat-select>
      <button
        *ngIf="fcVerwaltungsstufe.value && verwaltungsstufeMouseover"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="fcVerwaltungsstufe.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- Auswahl Einsatzmittelart: Fahrzeug, Wechsellader, Fahrrad, ... -->
    <mat-form-field
      class="less-margin"
      (mouseover)="einsatzmittelartMouseover = true"
      (mouseleave)="einsatzmittelartMouseover = false"
    >
      <mat-label>Einsatzmittelart</mat-label>
      <mat-select formControlName="einsatzmittelart">
        <mat-option *ngFor="let einsatzmittel of einsatzmittelartValues" [value]="einsatzmittel">
          {{ einsatzmittel.label }}
        </mat-option>
      </mat-select>
      <button
        *ngIf="fcEinsatzmittelart.value && einsatzmittelartMouseover"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="fcEinsatzmittelart.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </mat-card>

  <mat-card>
    <mat-card-subtitle>Informationen zum Fahrzeug</mat-card-subtitle>

    <app-cleanable-form-field
      label="Bezeichnung innerhalb Einheit"
      [formGroup]="formGroup"
      formControlName="innereBezeichnung"
    />

    <app-cleanable-form-field
      label="Ortsangaben - Ausgangspunkt"
      [formGroup]="formGroup"
      formControlName="ortsangabe"
    />
  </mat-card>
</form>

<app-kontakt-list [kommunikationOptionen]="fahrzeugToEdit?.kommunikationOptionen || []" />

<app-auftrag-list />
