import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, EventEmitter, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { Geometry } from 'geojson';
import * as L from 'leaflet';
import { control } from 'leaflet';
import { combineLatest, take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { TaktischesZeichenDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { AppService } from 'src/app/app.service';
import { LayerConfigService } from 'src/app/planung/karte/layer-config.service';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';
import { fuehrungsebeneByIdSelector } from '../../fuehrungsebene/+state/fuehrungsebene.selectors';
import { FuehrungsebeneService } from '../../fuehrungsebene/fuehrungsebene.service';
import { LayerService } from '../../layer.service';
import { Tool, ToolType } from '../../tool.interface';
import { ToolService } from '../../tool.service';
import { anlaesseOfFuehrungsebeneSelector } from '../anlass-ereignis/+state/anlass.selectors';
import { befehlsstellenOfFuehrungsebeneSelector } from '../befehlsstelle/+state/befehlsstelle.selectors';
import { fahrzeugeOfFuehrungsebeneSelector } from '../fahrzeuge/+state/fahrzeug.selectors';
import { fotosOfFuehrungsebeneSelector } from '../foto/+state/foto.selectors';
import { gebaeudeOfFuehrungsebeneSelector } from '../gebaeude/+state/gebaeude.selectors';
import { gebieteOfFuehrungsebeneSelector } from '../gebiete/+state/gebiet.selectors';
import { gefahrenOfFuehrungsebeneSelector } from '../gefahren/+state/gefahr.selectors';
import { fuehrungsebeneMassnahmenOfFuehrungsebeneSelector } from '../massnahmen/+state/fuehrungsebene-massnahme.selectors';
import { personenOfFuehrungsebeneSelector } from '../personen/+state/person.selectors';
import { personenschaedenOfFuehrungsebeneSelector } from '../personenschaden/+state/personenschaden.selectors';
import { stellenOfFuehrungsebeneSelector } from '../stelle-einrichtung/+state/stelle.selectors';
import { taktischeFormationenOfFuehrungsebeneSelector } from '../taktische-formation/+state/taktische-formation.selectors';
import { tierschaedenOfFuehrungsebeneSelector } from '../tierschaden/+state/tierschaden.selectors';

@Component({
  selector: 'app-tz-geometry-map',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatButtonToggleModule, MatTooltipModule, MatIconModule],
  templateUrl: './tz-geometry-map.component.html',
  styleUrls: ['./tz-geometry-map.component.scss'],
})
export class TzGeometryMapComponent implements AfterViewInit {
  // True, wenn gerade ein Polygon oder eine Linie gezeichnet wird
  drawingActive = false;

  @Output() geometryChanged = new EventEmitter<GeoJSON.Geometry>();

  private geometry?: Geometry;

  protected mapId = 'map-' + new Date().toISOString();
  private map?: L.Map;

  protected tools: Tool[] = [];
  public selectedTool?: Tool;
  private marker?: L.Marker;
  private polygon?: L.Polygon;
  private line?: L.Polyline;
  private currentDrawingCoords: L.LatLng[] = [];

  private taktischesZeichenDTO?: TaktischesZeichenDTO;

  private toolService = inject(ToolService);
  private layerService = inject(LayerService);
  private layerConfigService = inject(LayerConfigService);
  private fuehrungsebeneService = inject(FuehrungsebeneService);

  private iconUrl = '';
  private markerOptions: L.MarkerOptions = this.layerService.generateMarkerIconOption(this.iconUrl);
  private polygonOptions = this.layerService.generatePolygonOption();
  private polylineOptions = this.layerService.generatePolylineOption();

  private store = inject(Store<AppStateInterface>);
  private taktischeZeichenService = inject(TaktischeZeichenService);
  private appService = inject(AppService);

  private currentTileLayer?: L.TileLayer;

  private destroyRef = inject(DestroyRef);

  ngAfterViewInit(): void {
    this.initMap();
  }

  /**
   * Karte initialisieren, Tilelayer setzen und Listener für Mouse-Events registrieren
   */
  initMap(): void {
    this.map = L.map(this.mapId, { zoomControl: false });

    this.setMapView();

    this.layerConfigService.selectedBaseLayerConfig$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((baseLayerConfig) => {
        if (this.currentTileLayer) {
          this.map?.removeLayer(this.currentTileLayer);
        }

        if (!baseLayerConfig) {
          return;
        }
        this.currentTileLayer = this.layerConfigService.createTileLayerFromConfig(baseLayerConfig);
        if (this.currentTileLayer) {
          this.map?.addLayer(this.currentTileLayer);
        }
      });

    /**
     * Je nach ausgewähltem Tool wird entweder ein neuer Marker gesetzt oder eine weitere Koordinate zum aktuellen Polygon hinzugefügt und der Polygon-Marker neu zentriert
     */
    this.map.on('click', (event) => {
      if (!this.selectedTool) {
        return;
      }

      if (this.selectedTool.type === ToolType.Point) {
        this.updateMarker(event.latlng);
        this.geometryChanged.emit(this.marker?.toGeoJSON().geometry);
      } else {
        this.currentDrawingCoords.push(event.latlng);
        if (this.selectedTool.type === ToolType.Polygon) {
          this.updatePolygon(this.currentDrawingCoords);
        } else if (this.selectedTool.type === ToolType.Line) {
          this.updateLine(this.currentDrawingCoords);
        }
        this.drawingActive = true;
      }
    });

    /**
     * Wenn Polygon-Bearbeitung aktiv ist, soll immer eine Linie zwischen Maus und dem letzten Punkt aktualisiert werden.
     */
    this.map.on('mousemove', (event) => {
      if (!this.drawingActive) {
        return;
      }

      if (this.selectedTool?.type == ToolType.Polygon) {
        this.polygon?.setLatLngs([...this.currentDrawingCoords, event.latlng]);
        this.updatePolygonMarker();
      } else if (this.selectedTool?.type === ToolType.Line) {
        this.line?.setLatLngs([...this.currentDrawingCoords, event.latlng]);
        this.updateLineMarker();
      }
    });

    /**
     * Beim Rechtsklick soll Polygon-Bearbeitung abgeschlossen werden
     */
    this.map.on('contextmenu', () => {
      if (!this.drawingActive || !this.selectedTool) {
        return;
      }

      this.drawingActive = false;
      if (this.selectedTool.type === ToolType.Polygon) {
        this.updatePolygon(this.currentDrawingCoords);
        this.geometryChanged.emit(this.polygon?.toGeoJSON().geometry);
      } else if (this.selectedTool.type === ToolType.Line) {
        this.updateLine(this.currentDrawingCoords);
        this.geometryChanged.emit(this.line?.toGeoJSON().geometry);
      }
      this.currentDrawingCoords = [];
    });

    control
      .scale({
        imperial: false,
      })
      .addTo(this.map);
  }

  /**
   * Setzt den Koordinaten-Marker auf die übergebene Koordinate
   */
  updateMarker(latLng: L.LatLng): void {
    if (this.marker) {
      this.map?.removeLayer(this.marker);
    }
    this.marker = L.marker(latLng, this.markerOptions);
    this.map?.addLayer(this.marker);
  }

  /**
   * Aktualisiert das Polygon auf Basis der hinterlegten Koordinaten und zeichnet einen Marker in die Mitte des Polygons.
   */
  updatePolygon(latLngs: L.LatLng[] | L.LatLng[][] | L.LatLng[][][]): void {
    if (!this.drawingActive) {
      if (this.polygon) {
        this.map?.removeLayer(this.polygon);
      }
      this.polygonOptions = this.layerService.generateTzPolygonOption(this.taktischesZeichenDTO);
      this.polygon = L.polygon(latLngs, this.polygonOptions);
      this.map?.addLayer(this.polygon);
    } else {
      this.polygon?.setLatLngs(latLngs);
    }
    this.updatePolygonMarker();
  }

  updateLine(latLngs: L.LatLng[] | L.LatLng[][]) {
    if (!this.drawingActive) {
      if (this.line) {
        this.map?.removeLayer(this.line);
      }
      this.line = L.polyline(latLngs, this.polylineOptions);
      this.map?.addLayer(this.line);
    } else {
      this.line?.setLatLngs(latLngs);
    }
    this.updateLineMarker();
  }

  /**
   * Marker im Zentrum des gezeichneten Polygons positionieren
   */
  private updatePolygonMarker(): void {
    const polygonCenter = this.polygon?.getBounds()?.getCenter();
    if (polygonCenter) {
      this.updateMarker(polygonCenter);
    }
  }

  /**
   * Marker im Zentrum der gezeichneten Linie positionieren
   */
  private updateLineMarker(): void {
    const lineCenter = this.line?.getBounds()?.getCenter();
    if (lineCenter) {
      this.updateMarker(lineCenter);
    }
  }

  setTaktischesZeichen(
    taktischesZeichenDTO: TaktischesZeichenDTO,
    drawTzsOfSameFuehrungsebene = true,
    additionalTzs: TaktischesZeichenDTO[] = []
  ) {
    this.taktischesZeichenDTO = taktischesZeichenDTO;
    this.resetMap();
    this.drawParentFuehrungsebene();
    if (drawTzsOfSameFuehrungsebene) {
      this.drawOtherTzsOfFuehrungsebene();
    }
    this.drawTzGeometries(additionalTzs);
    this.updateAvailableTools(taktischesZeichenDTO.typ);
    const dtoGeometry = this.taktischesZeichenDTO.geometry as Geometry;

    // Wenn noch keine Geometrie vorhanden, Punkt-Tool wählen (sollte für jeden TZ Typen verfügbar sein)
    if (!dtoGeometry) {
      this.selectTool(ToolType.Point);
      this.setMapView();
      return;
    }

    // Ansonsten Tool, passend zur Geometrie des überebenen DTOs auswählen
    switch (dtoGeometry.type) {
      case 'Point':
        this.selectTool(ToolType.Point);
        this.marker = L.marker(L.latLng(dtoGeometry.coordinates[1], dtoGeometry.coordinates[0]), this.markerOptions);
        this.map?.addLayer(this.marker);
        break;
      case 'Polygon': {
        this.selectTool(ToolType.Polygon);
        const polyCoords = dtoGeometry.coordinates[0].map((position) => L.latLng(position[1], position[0]));
        this.updatePolygon(polyCoords);
        break;
      }
      case 'LineString': {
        this.selectTool(ToolType.Line);
        const lineCoords = dtoGeometry.coordinates.map((position) => L.latLng(position[1], position[0]));
        this.updateLine(lineCoords);
        break;
      }
    }

    this.geometry = dtoGeometry;
    this.geometryChanged.emit(this.geometry);
    this.setMapView();
  }

  setMarkerIcon(iconUrl: string) {
    this.iconUrl = iconUrl;
    this.markerOptions = this.layerService.generateMarkerIconOption(this.iconUrl);

    if (this.marker) {
      this.marker.options = this.markerOptions;
      if (this.marker.getIcon()) {
        this.marker.setIcon(L.icon({ iconUrl: this.iconUrl, iconSize: [30, 30] }));
      }
    }
  }

  /**
   * Positioniert die Karte
   */
  private setMapView() {
    // Wenn TZ Geometrie vorhanden, zu dieser springen
    if (this.zoomToGeometry(this.geometry)) {
      return;
    }

    // Ansonsten zu Führungsebene-Geometrie des TZs springen
    const tzFuehrungsebene = this.fuehrungsebeneService
      .getAllFuehrungsebenen()
      .find((fuehrungsebene) => fuehrungsebene.id === this.taktischesZeichenDTO?.fuehrungsebeneId);
    if (this.zoomToGeometry(tzFuehrungsebene?.geometry as Geometry)) {
      return;
    }

    // Ansonsten zu Lage-Geometrie springen
    if (this.zoomToGeometry(this.fuehrungsebeneService.getCurrentLage()?.geometry as Geometry)) {
      return;
    }

    // Ansonsten zu Fallback-Koordinate springen
    this.map?.fitBounds(this.appService.getSettingsMapBounds());
  }

  /**
   * Positioniert die Karte auf übergebene Geometrie:
   * - Ist Geometrie ein Punkt, wird dieser anvisiert
   * - Ist Geometrie ein Polygon, wird die Karte auf dessen Bounds gesetzt
   */
  private zoomToGeometry(geometry?: Geometry): boolean {
    if (geometry) {
      switch (geometry.type) {
        case 'Point':
          this.map?.setView(L.latLng(geometry.coordinates[1], geometry.coordinates[0]), 16);
          return true;
        case 'Polygon': {
          const polygonCoords = geometry.coordinates[0].map((position) => L.latLng(position[1], position[0]));
          this.map?.fitBounds(L.polygon(polygonCoords).getBounds());
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Aktualisiert die verfügbaren Tools zum Zeichen der Geometrie basierend auf dem übergebenen TZ Typen.
   */
  private updateAvailableTools(taktischesZeichenTyp: TaktischesZeichenTyp) {
    switch (taktischesZeichenTyp) {
      case TaktischesZeichenTyp.Fahrzeug:
      case TaktischesZeichenTyp.Person:
      case TaktischesZeichenTyp.TaktischeFormation:
      case TaktischesZeichenTyp.Personenschaden:
      case TaktischesZeichenTyp.Tierschaden:
      case TaktischesZeichenTyp.Foto:
        this.tools = this.toolService.tools.filter((tool) => tool.type === ToolType.Point);
        break;
      case TaktischesZeichenTyp.Stelle:
      case TaktischesZeichenTyp.Befehlsstelle:
      case TaktischesZeichenTyp.Gebaeude:
        this.tools = this.toolService.tools.filter((tool) => [ToolType.Point, ToolType.Polygon].includes(tool.type));
        break;
      case TaktischesZeichenTyp.Gefahr:
      case TaktischesZeichenTyp.Massnahme:
      case TaktischesZeichenTyp.Anlass:
        this.tools = this.toolService.tools.filter((tool) =>
          [ToolType.Point, ToolType.Polygon, ToolType.Line].includes(tool.type)
        );
        break;
      case TaktischesZeichenTyp.Gebiet:
        this.tools = this.toolService.tools.filter((tool) => tool.type === ToolType.Polygon);
        break;
    }

    // Punkt-Tool wählen, wenn aktuelles Tool nicht mehr verfügbar
    if (this.selectedTool && !this.tools.includes(this.selectedTool) && this.tools.length) {
      this.selectTool(this.tools[0].type);
    }
  }

  protected selectTool(tooltype: ToolType) {
    const tool = this.tools.find((tool) => tool.type === tooltype);
    if (!tool || this.selectedTool?.type === tooltype) {
      return;
    }

    this.selectedTool = tool;

    if (this.marker) {
      this.marker.remove();
      this.marker = undefined;
    }

    if (this.polygon) {
      this.polygon.remove();
      this.polygon = undefined;
    }

    if (this.line) {
      this.line.remove();
      this.line = undefined;
    }

    this.drawingActive = false;
    this.currentDrawingCoords = [];
    this.geometryChanged.emit(undefined);
  }

  private drawParentFuehrungsebene() {
    if (!this.taktischesZeichenDTO || !this.taktischesZeichenDTO.fuehrungsebeneId) {
      return;
    }
    this.store
      .select(fuehrungsebeneByIdSelector(this.taktischesZeichenDTO.fuehrungsebeneId))
      .pipe(take(1))
      .subscribe((fuehrungsebeneDTO) => {
        // Hat Führungsebene keine Geometrie, muss nichts gezeichnet werden
        if (!fuehrungsebeneDTO || !fuehrungsebeneDTO.geometry) {
          return;
        }

        const geometryStyle = this.layerService.getFuehrungsebeneGeometryStyle(fuehrungsebeneDTO, false);
        if (!geometryStyle) {
          return;
        }

        const fuehrungsebeneLayer = L.layerGroup();
        this.map?.addLayer(fuehrungsebeneLayer);
        const geometry = fuehrungsebeneDTO.geometry as GeoJSON.Geometry;
        let marker: L.Marker | undefined;
        if (geometry.type == 'Polygon') {
          const polygon = L.geoJSON(geometry, geometryStyle.polygonOptions);
          fuehrungsebeneLayer.addLayer(polygon);
          marker = L.marker(polygon.getBounds().getCenter(), geometryStyle.markerOptions);
          fuehrungsebeneLayer.addLayer(marker);
        } else if (geometry.type == 'Point') {
          marker = L.marker(L.latLng(geometry.coordinates[1], geometry.coordinates[0]), geometryStyle.markerOptions);
          fuehrungsebeneLayer.addLayer(marker);
        }
        marker?.bindTooltip(fuehrungsebeneDTO.name || '', { direction: 'top' });
      });
  }

  private drawOtherTzsOfFuehrungsebene() {
    if (!this.taktischesZeichenDTO || !this.taktischesZeichenDTO.fuehrungsebeneId) {
      return;
    }

    combineLatest([
      this.store.select(fuehrungsebeneMassnahmenOfFuehrungsebeneSelector(this.taktischesZeichenDTO.fuehrungsebeneId)),
      this.store.select(anlaesseOfFuehrungsebeneSelector(this.taktischesZeichenDTO.fuehrungsebeneId)),
      this.store.select(befehlsstellenOfFuehrungsebeneSelector(this.taktischesZeichenDTO.fuehrungsebeneId)),
      this.store.select(fahrzeugeOfFuehrungsebeneSelector(this.taktischesZeichenDTO.fuehrungsebeneId)),
      this.store.select(gebaeudeOfFuehrungsebeneSelector(this.taktischesZeichenDTO.fuehrungsebeneId)),
      this.store.select(gebieteOfFuehrungsebeneSelector(this.taktischesZeichenDTO.fuehrungsebeneId)),
      this.store.select(gefahrenOfFuehrungsebeneSelector(this.taktischesZeichenDTO.fuehrungsebeneId)),
      this.store.select(personenOfFuehrungsebeneSelector(this.taktischesZeichenDTO.fuehrungsebeneId)),
      this.store.select(personenschaedenOfFuehrungsebeneSelector(this.taktischesZeichenDTO.fuehrungsebeneId)),
      this.store.select(stellenOfFuehrungsebeneSelector(this.taktischesZeichenDTO.fuehrungsebeneId)),
      this.store.select(taktischeFormationenOfFuehrungsebeneSelector(this.taktischesZeichenDTO.fuehrungsebeneId)),
      this.store.select(tierschaedenOfFuehrungsebeneSelector(this.taktischesZeichenDTO.fuehrungsebeneId)),
      this.store.select(fotosOfFuehrungsebeneSelector(this.taktischesZeichenDTO.fuehrungsebeneId)),
    ])
      .pipe(take(1))
      .subscribe(
        ([
          massnahmeDTOs,
          anlassDTOs,
          befehlsstelleDTOs,
          fahrzeugDTOs,
          gebaeudeDTOs,
          gebietDTOs,
          gefahrDTOs,
          personDTOs,
          personenschadenDTOs,
          stelleDTOs,
          taktischeFormationDTOs,
          tierschadenDTOs,
          fotoDTOs,
        ]) => {
          this.drawTzGeometries(massnahmeDTOs);
          this.drawTzGeometries(anlassDTOs);
          this.drawTzGeometries(befehlsstelleDTOs);
          this.drawTzGeometries(fahrzeugDTOs);
          this.drawTzGeometries(gebaeudeDTOs);
          this.drawTzGeometries(gebietDTOs);
          this.drawTzGeometries(gefahrDTOs);
          this.drawTzGeometries(personDTOs);
          this.drawTzGeometries(personenschadenDTOs);
          this.drawTzGeometries(stelleDTOs);
          this.drawTzGeometries(taktischeFormationDTOs);
          this.drawTzGeometries(tierschadenDTOs);
          this.drawTzGeometries(fotoDTOs);
        }
      );
  }

  private drawTzGeometries(tzDTOs: TaktischesZeichenDTO[]) {
    tzDTOs.forEach((tzDTO) => {
      const dtoGeometry = tzDTO.geometry as Geometry;
      if (
        (tzDTO.id === this.taktischesZeichenDTO?.id && tzDTO.typ === this.taktischesZeichenDTO?.typ) ||
        !dtoGeometry
      ) {
        return;
      }

      const tzTooltip = `[${this.taktischeZeichenService.taktischeZeichenInfoMapping.get(tzDTO.typ)?.name}] ${
        tzDTO.anzeigename || ''
      }`;
      const markerOptions = this.layerService.generateMarkerIconOption(tzDTO.dataUrl || '');

      switch (dtoGeometry.type) {
        case 'Point': {
          const point = tzDTO.geometry as GeoJSON.Point;
          const pointMarker = L.marker(L.latLng(point.coordinates[1], point.coordinates[0]), markerOptions);
          pointMarker?.bindTooltip(tzTooltip, { direction: 'top' });
          this.map?.addLayer(pointMarker);
          break;
        }
        case 'LineString': {
          const lineString = tzDTO.geometry as GeoJSON.LineString;
          // Polyline
          const polyline = L.polyline(
            lineString.coordinates.map((position) => L.latLng(position[1], position[0])),
            this.layerService.generatePolylineOption()
          );
          this.map?.addLayer(polyline);

          // Marker für Polyline
          const lineMarker = L.marker(polyline.getBounds().getCenter(), markerOptions);
          lineMarker?.bindTooltip(tzTooltip, { direction: 'top' });
          this.map?.addLayer(lineMarker);
          break;
        }
        case 'Polygon': {
          // Polygon
          const polygon = L.geoJSON(dtoGeometry, this.layerService.generateTzPolygonOption(tzDTO));
          this.map?.addLayer(polygon);

          // Marker für Polygon
          const polygonMarker = L.marker(polygon.getBounds().getCenter(), markerOptions);
          polygonMarker?.bindTooltip(tzTooltip, { direction: 'top' });
          this.map?.addLayer(polygonMarker);
          break;
        }
      }
    });
  }

  resetMap() {
    if (this.map) {
      this.map?.eachLayer((layer) => {
        if (!(layer instanceof L.TileLayer)) {
          this.map?.removeLayer(layer);
        }
      });
      this.marker = undefined;
      this.polygon = undefined;
      this.line = undefined;
      this.drawingActive = false;
      this.currentDrawingCoords = [];
      this.geometry = undefined;
      this.geometryChanged.emit(this.geometry);
    }
  }
}
