<form [formGroup]="formGroup">
  <mat-form-field class="less-margin">
    <mat-label>Lage</mat-label>
    <textarea matInput [formControl]="formGroup.controls.lageText" rows="3"></textarea>
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.lageText.errors) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Auftrag</mat-label>
    <textarea matInput [formControl]="formGroup.controls.auftrag" rows="3"></textarea>
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.auftrag.errors) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Durchführung</mat-label>
    <textarea matInput [formControl]="formGroup.controls.durchfuehrung" rows="3"></textarea>
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.durchfuehrung.errors) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Versorgung</mat-label>
    <textarea matInput [formControl]="formGroup.controls.versorgung" rows="3"></textarea>
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.versorgung.errors) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Führung und Kommando</mat-label>
    <textarea matInput [formControl]="formGroup.controls.fuehrungUndKommando" rows="3"></textarea>
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.fuehrungUndKommando.errors) }}</mat-error>
  </mat-form-field>
</form>
