import { createReducer, on } from '@ngrx/store';
import { LageberichtStateInterface } from './lagebericht-state.interface';
import { lageberichtActions } from './lagebericht.actions';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';

export const initialState: LageberichtStateInterface = {
  isLoadingLagebericht: false,
  isCreatingLagebericht: false,
  isPatchingLagebericht: false,
  isDeletingLagebericht: false,
  lageberichte: [],
  errorResponse: undefined,
};

export const lageberichtReducer = createReducer(
  initialState,
  on(lageberichtActions.getLageberichte, (state) => ({
    ...state,
    isLoadingLagebericht: true,
    errorResponse: undefined,
  })),
  on(lageberichtActions.getLageberichteSuccess, (state, action) => ({
    ...state,
    isLoadingLagebericht: false,
    lageberichte: action.lageberichte,
    errorResponse: undefined,
  })),
  on(lageberichtActions.getLageberichteFailure, (state, action) => ({
    ...state,
    isLoadingLagebericht: false,
    errorResponse: action.errorResponse,
  })),

  on(lageberichtActions.createLagebericht, (state) => ({
    ...state,
    isCreatingLagebericht: true,
    errorResponse: undefined,
  })),
  on(lageberichtActions.createLageberichtSuccess, (state, action) => ({
    ...state,
    isCreatingLagebericht: false,
    lageberichte: [...state.lageberichte, action.newLagebericht],
  })),
  on(lageberichtActions.createLageberichtFailure, (state, action) => ({
    ...state,
    isCreatingLagebericht: false,
    errorResponse: action.errorResponse,
  })),

  on(lageberichtActions.patchLagebericht, (state) => ({
    ...state,
    isPatchingLagebericht: true,
    errorResponse: undefined,
  })),
  on(lageberichtActions.patchLageberichtSuccess, (state, action) => ({
    ...state,
    isPatchingLagebericht: false,
    lageberichte: replaceOrKeepCurrent(state.lageberichte, action.patchedLagebericht),
  })),
  on(lageberichtActions.patchLageberichtFailure, (state, action) => ({
    ...state,
    isPatchingLagebericht: false,
    errorResponse: action.errorResponse,
  })),

  on(lageberichtActions.deleteLagebericht, (state) => ({
    ...state,
    isDeletingLagebericht: true,
    errorResponse: undefined,
  })),
  on(lageberichtActions.deleteLageberichtSuccess, (state, action) => ({
    ...state,
    isDeletingLagebericht: false,
    lageberichte: state.lageberichte.filter((l) => l.id != action.deletedLageberichtId),
  })),
  on(lageberichtActions.deleteLageberichtFailure, (state, action) => ({
    ...state,
    isDeletingLagebericht: false,
    errorResponse: action.errorResponse,
  }))
);
