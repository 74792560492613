import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { ErrorService } from '@product/ise-error-lib';
import { Observable, merge } from 'rxjs';
import { tierschaedenErrorSelector } from 'src/app/lagedarstellung/taktische-zeichen/tierschaden/+state/tierschaden.selectors';
import { AppStateInterface } from '../../+state/appState.interface';
import { ErrorResponse } from '../../api/build/openapi';
import { einsatztagebuchErrorSelector } from '../../einsatztagebuch/+state/einsatztagebuch.selectors';
import { fuehrungsebeneErrorSelector } from '../../lagedarstellung/fuehrungsebene/+state/fuehrungsebene.selectors';
import { lageErrorSelector } from '../../lagedarstellung/lagen/+state/lage.selectors';
import { MassnahmenErrorSelector } from '../../lagedarstellung/massnahmen/+state/lage-massnahme.selectors';
import { anlaesseErrorSelector } from '../../lagedarstellung/taktische-zeichen/anlass-ereignis/+state/anlass.selectors';
import { befehlsstellenErrorSelector } from '../../lagedarstellung/taktische-zeichen/befehlsstelle/+state/befehlsstelle.selectors';
import { fahrzeugeErrorSelector } from '../../lagedarstellung/taktische-zeichen/fahrzeuge/+state/fahrzeug.selectors';
import { fotosErrorSelector } from '../../lagedarstellung/taktische-zeichen/foto/+state/foto.selectors';
import { gebaeudeErrorSelector } from '../../lagedarstellung/taktische-zeichen/gebaeude/+state/gebaeude.selectors';
import { gebieteErrorSelector } from '../../lagedarstellung/taktische-zeichen/gebiete/+state/gebiet.selectors';
import { gefahrenErrorSelector } from '../../lagedarstellung/taktische-zeichen/gefahren/+state/gefahr.selectors';
import { fuehrungsebeneMassnahmenErrorSelector } from '../../lagedarstellung/taktische-zeichen/massnahmen/+state/fuehrungsebene-massnahme.selectors';
import { personenErrorSelector } from '../../lagedarstellung/taktische-zeichen/personen/+state/person.selectors';
import { personenschaedenErrorSelector } from '../../lagedarstellung/taktische-zeichen/personenschaden/+state/personenschaden.selectors';
import { stellenErrorSelector } from '../../lagedarstellung/taktische-zeichen/stelle-einrichtung/+state/stelle.selectors';
import { taktischeFormationenErrorSelector } from '../../lagedarstellung/taktische-zeichen/taktische-formation/+state/taktische-formation.selectors';
import { wetterErrorSelector } from '../../lagedarstellung/wetter/+state/wetter.selectors';
import { meldungenErrorSelector } from '../../meldungen/+state/meldung.selectors';
import { bibliothekErrorSelector } from '../../planung/bibliothek/+state/bibliothek.selectors';
import { lageberichteErrorSelector } from 'src/app/lagebericht/+state/lagebericht.selectors';

@Injectable({
  providedIn: 'root',
})
export class StoreErrorHandlerService {
  private errorService = inject(ErrorService);
  private store = inject(Store<AppStateInterface>);
  private storeErrors$: Observable<ErrorResponse | undefined> = merge(
    this.store.select(anlaesseErrorSelector),
    this.store.select(befehlsstellenErrorSelector),
    this.store.select(bibliothekErrorSelector),
    this.store.select(einsatztagebuchErrorSelector),
    this.store.select(fahrzeugeErrorSelector),
    this.store.select(fotosErrorSelector),
    this.store.select(fuehrungsebeneErrorSelector),
    this.store.select(fuehrungsebeneMassnahmenErrorSelector),
    this.store.select(gebaeudeErrorSelector),
    this.store.select(gebieteErrorSelector),
    this.store.select(gefahrenErrorSelector),
    this.store.select(lageErrorSelector),
    this.store.select(MassnahmenErrorSelector),
    this.store.select(meldungenErrorSelector),
    this.store.select(personenErrorSelector),
    this.store.select(personenschaedenErrorSelector),
    this.store.select(stellenErrorSelector),
    this.store.select(taktischeFormationenErrorSelector),
    this.store.select(tierschaedenErrorSelector),
    this.store.select(wetterErrorSelector),
    this.store.select(lageberichteErrorSelector)
  );

  constructor() {
    this.storeErrors$.pipe(takeUntilDestroyed()).subscribe((errorResponse?: ErrorResponse) => {
      if (errorResponse) {
        this.errorService.showError(errorResponse);
      }
    });
  }
}
