import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '@product/ise-dialog-lib';
import { EMPTY, switchMap } from 'rxjs';
import {
  AnlassDTO,
  BefehlsstelleDTO,
  FahrzeugDTO,
  FotoDTO,
  FuehrungsebeneDTO,
  FuehrungsebeneMassnahmeDTO,
  FuehrungsebeneResourceService,
  GebaeudeDTO,
  GebietDTO,
  GefahrDTO,
  PersonDTO,
  PersonenschadenDTO,
  StelleDTO,
  TaktischeFormationDTO,
  TaktischesZeichenDTO,
  TaktischesZeichenStatus,
  TaktischesZeichenTyp,
  TierschadenDTO,
  VerwaltungsebeneDTO,
} from 'src/app/api/build/openapi';
import {
  BibliothekZeichenMoveComponent,
  BibliothekZeichenMoveOutputData,
} from 'src/app/planung/bibliothek/bibliothek-zeichen-move/bibliothek-zeichen-move.component';
import { BibliothekZeichenService } from 'src/app/planung/bibliothek/bibliothek-zeichen.service';
import { BibTzDTO } from 'src/app/planung/bibliothek/verwaltungsebene-details/verwaltungsebene-details.component';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';
import {
  FuehrungsebeneTreeNestedDialogInputData,
  FuehrungsebeneTreeNestedDialogOutputData,
  FuehrungsebeneTreeSelectNestedDialogComponent,
} from '../fuehrungsebene/fuehrungsebene-tree-select-nested-dialog/fuehrungsebene-tree-select-nested-dialog.component';
import { FuehrungsebeneService } from '../fuehrungsebene/fuehrungsebene.service';
import {
  StatuswechselDialogComponent,
  StatuswechselDialogInputData,
} from '../statuswechsel-dialog/statuswechsel-dialog.component';
import { AnlassPopupComponent } from './anlass-ereignis/anlass-popup/anlass-popup.component';
import { BefehlsstellePopupComponent } from './befehlsstelle/befehlsstelle-popup/befehlsstelle-popup.component';
import {
  BibliothekSelectionDialogComponent,
  BibliothekSelectionDialogInputData,
} from './bibliothek-selection-dialog/bibliothek-selection-dialog.component';
import { FahrzeugPopupComponent } from './fahrzeuge/fahrzeug-popup/fahrzeug-popup.component';
import { FotoPopupComponent } from './foto/foto-popup/foto-popup.component';
import { GebaeudePopupComponent } from './gebaeude/gebaeude-popup/gebaeude-popup.component';
import { GebietPopupComponent } from './gebiete/gebiet-popup/gebiet-popup.component';
import { GefahrPopupComponent } from './gefahren/gefahr-popup/gefahr-popup.component';
import { MassnahmePopupComponent } from './massnahmen/massnahme-popup/massnahme-popup.component';
import { MultiplyDialogComponent, MultiplyDialogInputData } from './multiply-dialog/multiply-dialog.component';
import { PersonPopupComponent } from './personen/person-popup/person-popup.component';
import { PersonenschadenPopupComponent } from './personenschaden/personenschaden-popup/personenschaden-popup.component';
import { StellePopupComponent } from './stelle-einrichtung/stelle-popup/stelle-popup.component';
import {
  TaktischeFormationDialogComponent,
  TaktischeFormationDialogInputData,
} from './taktische-formation/taktische-formation-dialog/taktische-formation-dialog.component';
import { TaktischeFormationPopupComponent } from './taktische-formation/taktische-formation-popup/taktische-formation-popup.component';
import { TaktischeFormationService } from './taktische-formation/taktische-formation.service';
import {
  CreateTzDialogData,
  EditTzDialogData,
  TaktischeZeichenDialogComponent,
} from './taktische-zeichen-dialog/taktische-zeichen-dialog.component';
import { TierschadenPopupComponent } from './tierschaden/tierschaden-popup/tierschaden-popup.component';
import {
  TzDeleteDialogComponent,
  TzDeleteDialogInputData,
  TzDeleteDialogOutputData,
} from './tz-delete-dialog/tz-delete-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class TzDialogService {
  private bibService = inject(BibliothekZeichenService);
  private dialog = inject(MatDialog);
  private dialogService = inject(DialogService);
  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private fuehrungsebeneResourceService = inject(FuehrungsebeneResourceService);
  private taktischeZeichenService = inject(TaktischeZeichenService);
  private taktischeFormationService = inject(TaktischeFormationService);

  /**
   * Neues Taktisches Zeichen in Führungsebene erstellen (LAGE)
   */
  openCreateTzDialogLage(
    fuehrungsebeneDTO: FuehrungsebeneDTO,
    tzStatus: TaktischesZeichenStatus,
    allowedTzTypes: TaktischesZeichenTyp[]
  ): void {
    const currentLage = this.fuehrungsebeneService.getCurrentLage();
    if (currentLage) {
      const inputData: CreateTzDialogData = {
        fuehrungsebeneId: fuehrungsebeneDTO.id,
        taktischesZeichenTyp: TaktischesZeichenTyp.Fahrzeug,
        state: tzStatus,
        allowedTzTypes: allowedTzTypes,
      };
      this.dialog.open(TaktischeZeichenDialogComponent, {
        data: inputData,
        disableClose: false,
      });
    }
  }

  /**
   * Neues Taktisches Zeichen in Verwaltungsebene erstellen (Bibliothek)
   */
  openCreateTzDialogBib(verwaltungsebene: VerwaltungsebeneDTO) {
    const createTzDialogData: CreateTzDialogData = {
      allowedTzTypes: [
        TaktischesZeichenTyp.Fahrzeug,
        TaktischesZeichenTyp.Person,
        TaktischesZeichenTyp.TaktischeFormation,
      ],
      preventSaveActions: true,
      taktischesZeichenTyp: TaktischesZeichenTyp.Fahrzeug,
    };

    const dialogRef = this.dialog.open(TaktischeZeichenDialogComponent, {
      data: createTzDialogData,
      disableClose: false,
    });

    const subscription = dialogRef.componentInstance.handleSave.subscribe((dto: TaktischesZeichenDTO) => {
      const bibTzDto = dto as BibTzDTO;

      // LageID darf für Verwaltungsebene nicht gesetzt sein
      const dtoToCreate: BibTzDTO = { ...bibTzDto, verwaltungsebeneId: verwaltungsebene.id, lageId: undefined };
      this.bibService.saveBibZeichenDto(dtoToCreate);

      dialogRef.close();
      subscription.unsubscribe();
    });
  }

  /**
   * Existierendes Taktisches Zeichen editieren (LAGE und Bibliothek)
   */
  public openEditTzDialog(tzDto: TaktischesZeichenDTO) {
    const editTzDialogData: EditTzDialogData = {
      dto: tzDto,
      taktischesZeichenTyp: tzDto.typ,
    };
    return this.dialog.open(TaktischeZeichenDialogComponent, {
      data: editTzDialogData,
      disableClose: false,
    });
  }

  /**
   * Öffnet einen ConfirmDialog zum Löschen/Abziehen von Taktischen Zeichen und führt die Aktion aus,
   * wenn er bestätigt wurde.
   */
  public openTzDeleteDialog(tzDTOs: TaktischesZeichenDTO[], calledFromBib: boolean) {
    if (!tzDTOs.length) {
      console.warn('Keine Taktischen Zeichen zum Löschen ausgewählt');
      return;
    }

    const inputData: TzDeleteDialogInputData = {
      tzDtos: tzDTOs,
      calledFromBib: calledFromBib,
    };
    this.dialog
      .open(TzDeleteDialogComponent, { data: inputData })
      .afterClosed()
      .subscribe((result: TzDeleteDialogOutputData) => {
        if (!result) {
          return;
        }

        if (calledFromBib) {
          this.bibService.deleteMultipleTzs(tzDTOs, result.recursive);
        } else {
          this.taktischeZeichenService.deleteMultipleTzs(tzDTOs, result.recursive);
        }
      });
  }

  /**
   * Öffnet einen Dialog zum Verschieben von Taktischen Zeichen
   * - Bib: Dialog mit verfügbaren Verwaltungsebenen
   * - Lage: Dialog mit verfügbaren Führungsebenen
   */
  public openTzMoveDialog(tzDtos: TaktischesZeichenDTO[], calledFromBib: boolean) {
    if (!tzDtos.length) {
      console.warn('Verschieben nicht möglich. Keine Taktischen Zeichen wurden ausgewählt.');
      return;
    }

    if (calledFromBib) {
      this.dialog
        .open(BibliothekZeichenMoveComponent)
        .afterClosed()
        .subscribe((result: BibliothekZeichenMoveOutputData) => {
          if (result) {
            this.bibService.moveMultipleTzsToVerwaltungsebene(tzDtos, result.verwaltungsebeneId);
          }
        });
      return;
    }

    const firstTz = tzDtos[0];
    const firstFuehrungsebeneId = firstTz.fuehrungsebeneId;
    if (!firstFuehrungsebeneId) {
      console.warn('Taktische Zeichen haben keine Führungsebene. Verschieben nicht möglich.');
      return;
    }

    const uniqueTzTypes = [...new Set(tzDtos.map((tzDto) => tzDto.typ))];

    this.openFuehrungsebeneHierarchyDialog(firstFuehrungsebeneId, uniqueTzTypes).subscribe(
      (outputData: FuehrungsebeneTreeNestedDialogOutputData) => {
        if (!outputData) {
          return;
        }

        this.openStatuswechselInfoDialog(tzDtos, outputData.selectedFuehrungsebeneId);
      }
    );
  }

  /**
   * Öffnet einen Dialog mit allen verfügbaren Führungsebenen der aktuellen Lage
   * und markiert die übergebene Führungsebene als die Aktuelle.
   */
  public openFuehrungsebeneHierarchyDialog(selectedFuehrungsebeneId: string, tzTypesToMove: TaktischesZeichenTyp[]) {
    // Prüfen, dass aktuell eine Lage ausgewählt ist
    const currentLage = this.fuehrungsebeneService.getCurrentLage();
    if (!currentLage?.id) {
      console.warn('Keine Lage für Navigationsbaum verfügbar');
      return EMPTY;
    }

    // Navigationsbaum im Backend holen und Dialog mit Auswahl öffnen
    return this.fuehrungsebeneResourceService.getTzMoveTree(currentLage.id, tzTypesToMove).pipe(
      switchMap((treeNode) => {
        if (!treeNode) {
          console.warn('Kein Navigationsbaum verfügbar.');
          return EMPTY;
        }

        const dialogInputData: FuehrungsebeneTreeNestedDialogInputData = {
          treeData: treeNode,
          selectedFuehrungsebeneId: selectedFuehrungsebeneId,
          headerText: 'Verschieben nach ...',
        };
        return this.dialog.open(FuehrungsebeneTreeSelectNestedDialogComponent, { data: dialogInputData }).afterClosed();
      })
    );
  }

  /**
   * Öffnet einen StatuswechselDialog und Speichert anschließend neuen Status in übergebenem TZ.
   */
  public openStatuswechselInfoDialog(
    tzDtos: TaktischesZeichenDTO[],
    targetFuehrungsebeneId: string,
    targetStatus?: TaktischesZeichenStatus
  ) {
    const targetFuehrungsebeneDto = this.fuehrungsebeneService
      .getAllFuehrungsebenen()
      .find((fuehrungsebene) => fuehrungsebene.id === targetFuehrungsebeneId);
    if (!targetFuehrungsebeneDto) {
      return;
    }

    const targetTzStatus =
      targetStatus || this.fuehrungsebeneService.fuehrungsebenentypStatusMapping.get(targetFuehrungsebeneDto.typ);
    if (!targetTzStatus) {
      return;
    }

    const inputData: StatuswechselDialogInputData = {
      tzDtos: tzDtos,
      targetFuehrungsebeneDto: targetFuehrungsebeneDto,
      targetTzStatus: targetTzStatus,
    };

    this.dialog.open(StatuswechselDialogComponent, { data: inputData });
  }

  /**
   * Öffnet einen Bibliothek-Dialog zum Importieren von TZs aus der Bibliothek in diesen Container.
   */
  public openBibliothekSelectionDialog(fuehrungsebeneDTO: FuehrungsebeneDTO, tzStatus: TaktischesZeichenStatus): void {
    if (fuehrungsebeneDTO.lageId && fuehrungsebeneDTO.id) {
      const dialogData: BibliothekSelectionDialogInputData = {
        lageId: fuehrungsebeneDTO.lageId,
        fuehrungsebeneId: fuehrungsebeneDTO.id,
        state: tzStatus,
      };
      this.dialog.open(BibliothekSelectionDialogComponent, {
        data: dialogData,
        disableClose: false,
      });
    }
  }

  /**
   * Öffnet den Hierarchie-Editor der übergebenen Taktischen Formation
   */
  public openTaktischeFormationHierarchieDialog(dto: TaktischeFormationDTO) {
    if (!dto.id || dto.typ !== TaktischesZeichenTyp.TaktischeFormation) {
      return;
    }

    this.taktischeFormationService.getTaktischeFormationNested(dto.id).subscribe((taktischeFormationNestedDTO) => {
      const dialogData: TaktischeFormationDialogInputData = {
        taktischeFormationNestedDTO: taktischeFormationNestedDTO,
      };
      this.dialog.open(TaktischeFormationDialogComponent, { data: dialogData });
    });
  }

  /**
   * Öffnet einen Dialog, mit dem ein Taktisches Zeichen dupliziert werden kann
   */
  openMultiplyDialog(tzDto: TaktischesZeichenDTO) {
    const inputData: MultiplyDialogInputData = {
      tzDto,
    };
    this.dialog.open(MultiplyDialogComponent, { data: inputData });
  }

  /**
   * Öffnet einen Dialog, der je nach TzTyp eine Zusammenfassung des Taktischen Zeichens darstellt.
   */
  public openTzPopupDialog(tzDto: TaktischesZeichenDTO) {
    switch (tzDto.typ) {
      case TaktischesZeichenTyp.Anlass:
        this.dialog.open(AnlassPopupComponent).componentInstance.dto = tzDto as AnlassDTO;
        break;
      case TaktischesZeichenTyp.Befehlsstelle:
        this.dialog.open(BefehlsstellePopupComponent).componentInstance.dto = tzDto as BefehlsstelleDTO;
        break;
      case TaktischesZeichenTyp.Fahrzeug:
        this.dialog.open(FahrzeugPopupComponent).componentInstance.dto = tzDto as FahrzeugDTO;
        break;
      case TaktischesZeichenTyp.Foto: {
        const componentInstance = this.dialog.open(FotoPopupComponent).componentInstance;
        componentInstance.fotoPreviewDTO = tzDto as FotoDTO;
        componentInstance.loadFoto();
        break;
      }
      case TaktischesZeichenTyp.Gebaeude:
        this.dialog.open(GebaeudePopupComponent).componentInstance.dto = tzDto as GebaeudeDTO;
        break;
      case TaktischesZeichenTyp.Gebiet:
        this.dialog.open(GebietPopupComponent).componentInstance.dto = tzDto as GebietDTO;
        break;
      case TaktischesZeichenTyp.Gefahr:
        this.dialog.open(GefahrPopupComponent).componentInstance.dto = tzDto as GefahrDTO;
        break;
      case TaktischesZeichenTyp.Massnahme:
        this.dialog.open(MassnahmePopupComponent).componentInstance.dto = tzDto as FuehrungsebeneMassnahmeDTO;
        break;
      case TaktischesZeichenTyp.Person:
        this.dialog.open(PersonPopupComponent).componentInstance.dto = tzDto as PersonDTO;
        break;
      case TaktischesZeichenTyp.Personenschaden:
        this.dialog.open(PersonenschadenPopupComponent).componentInstance.dto = tzDto as PersonenschadenDTO;
        break;
      case TaktischesZeichenTyp.Stelle:
        this.dialog.open(StellePopupComponent).componentInstance.dto = tzDto as StelleDTO;
        break;
      case TaktischesZeichenTyp.TaktischeFormation:
        this.dialog.open(TaktischeFormationPopupComponent).componentInstance.dto = tzDto as TaktischeFormationDTO;
        break;
      case TaktischesZeichenTyp.Tierschaden:
        this.dialog.open(TierschadenPopupComponent).componentInstance.dto = tzDto as TierschadenDTO;
        break;
    }
  }
}
