import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { ErrorResponse, GebaeudeResourceService } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { gebaeudeActions } from './gebaeude.actions';

@Injectable()
export class GebaeudeEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der e anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return gebaeudeActions.getGebaeude({ lageId: props.currentLage.id });
        } else {
          return gebaeudeActions.resetStore();
        }
      })
    )
  );

  /**
   * Alle Gebäude einer Lage laden
   */
  getGebaeude$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gebaeudeActions.getGebaeude),
      mergeMap((props) =>
        this.gebaeudeResourceService.getGebaeudeByLageId(props.lageId).pipe(
          map((gebaeude) => gebaeudeActions.getGebaeudeSuccess({ gebaeudeDTOs: gebaeude, lageId: props.lageId })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(gebaeudeActions.getGebaeudeFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Neues Gebäude anlegen
   */
  createGebaeude$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gebaeudeActions.createGebaeude),
      mergeMap((props) =>
        this.gebaeudeResourceService.createGebaeude(props.lageId, props.gebaeudeDTO).pipe(
          map((createdGebaeudeDTO) => gebaeudeActions.createGebaeudeSuccess({ createdGebaeudeDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(gebaeudeActions.createGebaeudeFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Existierendes Gebäude editieren
   */
  patchGebaeude$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gebaeudeActions.patchGebaeude),
      mergeMap((props) => {
        if (!props.gebaeudeDTO.id) {
          return throwError(() => new Error('Gebaeude muss eine Id besitzen'));
        }
        return this.gebaeudeResourceService.patchGebaeude(props.gebaeudeDTO.id, props.lageId, props.gebaeudeDTO).pipe(
          map((patchedGebaeudeDTO) => gebaeudeActions.patchGebaeudeSuccess({ patchedGebaeudeDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(gebaeudeActions.patchGebaeudeFailure({ errorResponse }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private gebaeudeResourceService: GebaeudeResourceService) {}
}
