import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { debounceTime } from 'rxjs';
import { LageMassnahmeDTO, Prioritaet } from 'src/app/api/build/openapi';
import { LoadableComponentComponent } from 'src/app/shared/components/loadable-component/loadable-component.component';
import { DateTimePipe } from 'src/app/shared/pipes/date-time.pipe';
import { MassnahmenService } from '../massnahmen.service';
import { currentLageMassnahmenSelector, isLoadingMassnahmenSelector } from './+state/lage-massnahme.selectors';
import { LagePrio, MassnahmeAddDialogComponent } from './massnahme-add-dialog/massnahme-add-dialog.component';
import { MassnahmeBeendenDialogComponent } from './massnahme-beenden-dialog/massnahme-beenden-dialog.component';
import { CompareService } from 'src/app/shared/services/compare.service';

@Component({
  selector: 'app-massnahmen',
  templateUrl: './massnahmen.component.html',
  styleUrls: ['./massnahmen.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatToolbarModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressBarModule,
    NgFor,
    NgClass,
    AsyncPipe,
    DateTimePipe,
    LoadableComponentComponent,
  ],
})
export class MassnahmenComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private dialog = inject(MatDialog);
  private store = inject(Store);
  protected massnahmenService = inject(MassnahmenService);

  massnahmen: LageMassnahmeDTO[] = [];
  unerledigtMassnahmen: LageMassnahmeDTO[] = [];
  isLoadingMassnahmen$ = this.store.select(isLoadingMassnahmenSelector).pipe(debounceTime(500));

  prioMapping: Map<Prioritaet, LagePrio> = MassnahmenService.prioMapping;
  protected showErledigt = false;
  compare = inject(CompareService);

  ngOnInit(): void {
    this.store
      .select(currentLageMassnahmenSelector)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((m) => {
        this.massnahmen = [...m].sort((a, b) => this.sortMassnahmen(a, b));
        this.unerledigtMassnahmen = this.massnahmen.filter((m) => !m.erledigt);
      });
  }

  onClickHinzufuegen() {
    this.dialog
      .open(MassnahmeAddDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result != undefined) {
          this.massnahmenService.addMassnahme(result);
        }
      });
  }

  onClickBeenden(masshanme: LageMassnahmeDTO) {
    this.dialog
      .open(MassnahmeBeendenDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.massnahmenService.massnahmeBeenden(masshanme, result);
        }
      });
  }

  onClickBearbeiten(masshanme: LageMassnahmeDTO) {
    this.dialog
      .open(MassnahmeAddDialogComponent, {
        data: {
          massnahme: masshanme,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result != undefined) {
          this.massnahmenService.updateMassnahme(result);
        }
      });
  }

  onClickShowErledigt() {
    this.showErledigt = !this.showErledigt;
  }

  getAufgabenSortet(massnahmen: LageMassnahmeDTO[]): LageMassnahmeDTO[] {
    //let aufg: LageMassnahmeDTO[] = this.showErledigt ? aufgaben : aufgaben.filter((a) => !a.erledigt);
    //return aufg.sort((a, b) => b.prioritaet!.wert! - a.prioritaet!.wert!).sort((a, b) => this.sortAufgaben(a, b));
    return massnahmen;
  }

  private sortMassnahmen(a: LageMassnahmeDTO, b: LageMassnahmeDTO): number {
    if (!a || !b) {
      return 0;
    }

    if (this.compare.isDateStringAfterNow(a.bisWannDatum) || this.compare.isDateStringAfterNow(b.bisWannDatum)) {
      return this.compare.isDateStringAfterNow(a.bisWannDatum) ? -1 : 1;
    }

    if (a.erledigt || b.erledigt) {
      return a.erledigt ? 1 : -1;
    }

    if (a.prioritaet === b.prioritaet) {
      if (!a.bisWannDatum) {
        if (!b.bisWannDatum) {
          return 0;
        }
        return -1;
      } else if (!b.bisWannDatum) {
        return 1;
      }
      const dA: Date = new Date(a.bisWannDatum);
      const dB: Date = new Date(b.bisWannDatum);
      return dA.getTime() - dB.getTime();
    } else if (a.prioritaet && b.prioritaet) {
      const aWert = this.prioMapping.get(a.prioritaet)?.wert;
      const bWert = this.prioMapping.get(b.prioritaet)?.wert;
      if (!aWert) {
        if (!bWert) {
          return 0;
        }
        return 1;
      } else if (!bWert) {
        return -1;
      }
      return bWert - aWert;
    }
    return 0;
  }
}
