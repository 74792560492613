<mat-toolbar>
  <h2>Personenübersicht & MANV</h2>
</mat-toolbar>

<mat-dialog-content>
  <div class="row1">
    <div class="left">
      <mat-form-field *ngIf="currentFuehrungsebene$ | async as currentFuehrungsebene">
        <mat-label>Auswahl Führungsebene</mat-label>
        <mat-select [value]="currentFuehrungsebene" (selectionChange)="fuehrungsebeneChanged($event)">
          <mat-option [value]="currentFuehrungsebene">
            <app-fuehrungsebene-icon-and-text [fuehrungsebeneDTO]="currentFuehrungsebene" />
          </mat-option>
          <mat-option *ngFor="let childFuehrungsebene of childFuehrungsebenen$ | async" [value]="childFuehrungsebene">
            <app-fuehrungsebene-icon-and-text [fuehrungsebeneDTO]="childFuehrungsebene" />
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Letzte Bearbeitung</mat-label>
        <input matInput type="datetime-local" [formControl]="letzteBearbeitung" dateTimeLocalAsDate [readonly]="true" />
      </mat-form-field>
    </div>

    <app-personenuebersicht-subset
      class="total-box"
      header="Gesamtzahl"
      [personen]="personenGesamt"
      [disabled]="true"
    />
  </div>

  <div class="row2">
    <app-personenuebersicht-subset [disabled]="true" [personen]="personenBevoelkerung" header="Bevölkerung" />
    <app-personenuebersicht-subset [disabled]="true" [personen]="personenEinsatzkraefte" header="Einsatzkräfte" />
  </div>

  <app-manv #manvComponent [disabled]="true" [manv]="manv" />
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="accent" mat-dialog-close>Schließen</button>
</mat-dialog-actions>
