<mat-toolbar>
  <h2>Auftrag {{ auftragDto?.id ? "bearbeiten" : "anlegen" }}</h2>
</mat-toolbar>

<div class="content">
  <form [formGroup]="formGroup">
    <div class="header-row">
      <mat-form-field class="flex-1 less-margin">
        <mat-label>Name</mat-label>
        <input matInput [formControl]="formGroup.controls.name" />
        <mat-error>{{ errorService.getErrorMessage(formGroup.controls.name.errors) }}</mat-error>
      </mat-form-field>

      <mat-checkbox [formControl]="formGroup.controls.erledigt" matTooltip="Wurde der Auftrag erledigt?">
        erledigt</mat-checkbox
      >
    </div>

    <app-tz-combobox
      label="Befehlende Stelle"
      formControlName="befehlendeStelle"
      [formGroup]="formGroup"
      [data$]="befehlsstelleValues$"
      [displayedFields]="['anzeigename']"
    />

    <mat-form-field class="less-margin">
      <mat-label>Abgangsort</mat-label>
      <input matInput [formControl]="formGroup.controls.abgangsort" />
      <mat-error>{{ errorService.getErrorMessage(formGroup.controls.abgangsort.errors) }}</mat-error>
    </mat-form-field>

    <mat-form-field class="less-margin">
      <mat-label>Datum und Uhrzeit</mat-label>
      <input matInput type="datetime-local" [formControl]="formGroup.controls.zeitpunkt" dateTimeLocalAsDate />
      <mat-error>{{ errorService.getErrorMessage(formGroup.controls.zeitpunkt.errors) }}</mat-error>
    </mat-form-field>

    <mat-form-field class="less-margin">
      <mat-label>Verteiler</mat-label>
      <input matInput [formControl]="formGroup.controls.verteiler" />
      <mat-error>{{ errorService.getErrorMessage(formGroup.controls.verteiler.errors) }}</mat-error>
    </mat-form-field>

    <mat-form-field class="less-margin">
      <mat-label>Anlagen</mat-label>
      <textarea matInput [formControl]="formGroup.controls.anlagen" rows="3"></textarea>
      <mat-error>{{ errorService.getErrorMessage(formGroup.controls.anlagen.errors) }}</mat-error>
    </mat-form-field>

    <app-tz-combobox
      label="Name und Funktion"
      formControlName="person"
      [formGroup]="formGroup"
      [data$]="personValues$"
      [displayedFields]="['anzeigename']"
    />

    <mat-radio-group [formControl]="formGroup.controls.befehlTyp" class="befehltyp">
      <mat-radio-button *ngFor="let typ of BefehlTyp | keyvalue" [value]="typ.value">{{ typ.key }}</mat-radio-button>
    </mat-radio-group>
  </form>

  @if (formGroup.controls.befehlTyp.value === BefehlTyp.Befehl) {
  <app-befehl-form />
  } @else if (formGroup.controls.befehlTyp.value === BefehlTyp.Langzeitbefehl) {
  <app-langzeitbefehl-form />
  } @else if (formGroup.controls.befehlTyp.value === BefehlTyp.Marschbefehl) {
  <app-marschbefehl-form />
  }
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="save()" [disabled]="isSaving">Speichern</button>
</mat-dialog-actions>
