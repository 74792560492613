<app-tz-popup-toolbar [tzDto]="dto" (editClicked)="editDto()" (moveClicked)="moveDto()" />

<div class="content">
  <app-fuehrungsebene-icon-and-text *ngIf="parentFuehrungsebene" [fuehrungsebeneDTO]="parentFuehrungsebene" />

  <div class="row">
    <div class="links"></div>

    <img class="zeichen" [src]="dto.dataUrl" />

    <div class="rechts">
      <ul>
        <li *ngIf="dto.schadenumfangMenschen">Menschen</li>
        <li *ngIf="dto.schadenumfangTiere">Tiere</li>
        <li *ngIf="dto.schadenumfangUmwelt">Umwelt</li>
        <li *ngIf="dto.schadenumfangSachwerte">Sachwerte</li>
      </ul>
    </div>
  </div>

  <div class="unten"></div>
</div>
