import { createReducer, on } from '@ngrx/store';
import { GebaeudeDTO } from 'src/app/api/build/openapi';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { GebaeudeStateInterface } from './gebaeude-state.interface';
import { gebaeudeActions } from './gebaeude.actions';

export const initialState: GebaeudeStateInterface = {
  isCreating: false,
  isLoading: false,
  isPatching: false,
  gebaeude: [],
  loadedLageId: '',
};

export const gebaeudeReducer = createReducer(
  initialState,

  // Reset Store
  on(gebaeudeActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get Gebaeude
  on(gebaeudeActions.getGebaeude, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(gebaeudeActions.getGebaeudeSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    gebaeude: action.gebaeudeDTOs,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(gebaeudeActions.getGebaeudeFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create Gebaeude
  on(gebaeudeActions.createGebaeude, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(gebaeudeActions.createGebaeudeSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    gebaeude: [...state.gebaeude, action.createdGebaeudeDTO],
    errorResponse: undefined,
  })),
  on(gebaeudeActions.createGebaeudeFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Gebaeude
  on(gebaeudeActions.patchGebaeude, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(gebaeudeActions.patchGebaeudeSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    gebaeude: replaceOrKeepCurrent(state.gebaeude, action.patchedGebaeudeDTO),
    errorResponse: undefined,
  })),
  on(gebaeudeActions.patchGebaeudeFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Gebaeude
  on(gebaeudeActions.deleteGebaeudeSuccess, (state, action) => ({
    ...state,
    gebaeude: state.gebaeude.filter((gebaeude: GebaeudeDTO) => gebaeude.id !== action.deletedGebaeudeDTO.id),
  }))
);
