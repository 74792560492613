import { AnwesenheitenStateInterface } from '../anwesenheitsliste/+state/anwesenheitenState.interface';
import { EinsatzfilterStateInterface } from '../einsatz/+state/einsatzfilter-state.interface';
import { EinsatztagebuchStateInterface } from '../einsatztagebuch/+state/einsatztagebuch-state.interface';
import { LageberichtStateInterface } from '../lagebericht/+state/lagebericht-state.interface';
import { FuehrungsebeneStateInterface } from '../lagedarstellung/fuehrungsebene/+state/fuehrungsebene-state.interface';
import { LageStateInterface } from '../lagedarstellung/lagen/+state/lage-state.interface';
import { LageMassnahmeStateInterface } from '../lagedarstellung/massnahmen/+state/lage-massnahme-state.interface';
import { AnlassStateInterface } from '../lagedarstellung/taktische-zeichen/anlass-ereignis/+state/anlass-state.interface';
import { BefehlsstelleStateInterface } from '../lagedarstellung/taktische-zeichen/befehlsstelle/+state/befehlsstelle-state.interface';
import { FahrzeugStateInterface } from '../lagedarstellung/taktische-zeichen/fahrzeuge/+state/fahrzeug-state.interface';
import { FotoStateInterface } from '../lagedarstellung/taktische-zeichen/foto/+state/foto-state.interface';
import { GebaeudeStateInterface } from '../lagedarstellung/taktische-zeichen/gebaeude/+state/gebaeude-state.interface';
import { GebietStateInterface } from '../lagedarstellung/taktische-zeichen/gebiete/+state/gebiet-state.interface';
import { GefahrStateInterface } from '../lagedarstellung/taktische-zeichen/gefahren/+state/gefahr-state.interface';
import { FuehrungsebeneMassnahmeStateInterface } from '../lagedarstellung/taktische-zeichen/massnahmen/+state/fuehrungsebene-massnahme-state.interface';
import { PersonStateInterface } from '../lagedarstellung/taktische-zeichen/personen/+state/person-state.interface';
import { PersonenschadenStateInterface } from '../lagedarstellung/taktische-zeichen/personenschaden/+state/personenschaden-state.interface';
import { StelleStateInterface } from '../lagedarstellung/taktische-zeichen/stelle-einrichtung/+state/stelle-state.interface';
import { TaktischeFormationStateInterface } from '../lagedarstellung/taktische-zeichen/taktische-formation/+state/taktische-formation-state.interface';
import { TierschadenStateInterface } from '../lagedarstellung/taktische-zeichen/tierschaden/+state/tierschaden-state.interface';
import { WetterStateInterface } from '../lagedarstellung/wetter/+state/wetter-state.interface';
import { StabMassnahmenStateInterface } from '../lagek/stab-massnahmen/+state/stabmassnahmenState.interface';
import { MeldungStateInterface } from '../meldungen/+state/meldung-state.interface';
import { BibliothekStateInterface } from '../planung/bibliothek/+state/bibliothek-state.interface';
import { MapLayerConfigStateInterface } from '../planung/karte/+state/map-layer-config-state.interface';
import { TatktischeZeichenStateInterface } from '../taktische-zeichen/+state/taktischeZeichenState.interface';

export const anlaesse = 'anlaesse';
export const befehlsstellen = 'befehlsstellen';
export const bibliothek = 'bibliothek';
export const einsatztagebuch = 'einsatztagebuch';
export const einsatzfilter = 'einsatzfilter';
export const fahrzeuge = 'fahrzeuge';
export const fotos = 'fotos';
export const fuehrungsebeneMassnahmen = 'fuehrungsebene-massnahmen';
export const fuehrungsebenen = 'fuehrungsebenen';
export const gebaeude = 'gebaeude';
export const gebiete = 'gebiete';
export const gefahren = 'gefahren';
export const lageMassnahmen = 'lage-massnahmen';
export const lagen = 'lagen';
export const mapConfigs = 'map-configs';
export const meldungen = 'meldungen';
export const personen = 'personen';
export const personenschaeden = 'personenschaeden';
export const stellen = 'stellen';
export const taktischeFormationen = 'taktische-formationen';
export const taktischeZeichenFeatureName = 'taktische-zeichen';
export const tierschaeden = 'tierschaeden';
export const wetter = 'wetter';
export const stabMassnahmen = 'stab-massnahmen';
export const anwesenheiten = 'anwesenheiten';
export const lageberichte = 'lageberichte';

export interface AppStateInterface {
  [anlaesse]: AnlassStateInterface;
  [befehlsstellen]: BefehlsstelleStateInterface;
  [bibliothek]: BibliothekStateInterface;
  [einsatztagebuch]: EinsatztagebuchStateInterface;
  [einsatzfilter]: EinsatzfilterStateInterface;
  [fahrzeuge]: FahrzeugStateInterface;
  [fotos]: FotoStateInterface;
  [fuehrungsebeneMassnahmen]: FuehrungsebeneMassnahmeStateInterface;
  [fuehrungsebenen]: FuehrungsebeneStateInterface;
  [gebaeude]: GebaeudeStateInterface;
  [gebiete]: GebietStateInterface;
  [gefahren]: GefahrStateInterface;
  [lageMassnahmen]: LageMassnahmeStateInterface;
  [lagen]: LageStateInterface;
  [mapConfigs]: MapLayerConfigStateInterface;
  [meldungen]: MeldungStateInterface;
  [personen]: PersonStateInterface;
  [personenschaeden]: PersonenschadenStateInterface;
  [stellen]: StelleStateInterface;
  [taktischeFormationen]: TaktischeFormationStateInterface;
  [taktischeZeichenFeatureName]: TatktischeZeichenStateInterface;
  [tierschaeden]: TierschadenStateInterface;
  [wetter]: WetterStateInterface;
  [stabMassnahmen]: StabMassnahmenStateInterface;
  [anwesenheiten]: AnwesenheitenStateInterface;
  [lageberichte]: LageberichtStateInterface;
}
