import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { FuehrungsebeneDTO, FuehrungsebeneMassnahmeDTO, PersonDTO } from 'src/app/api/build/openapi';
import { FuehrungsebeneIconAndTextComponent } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { MassnahmenService } from 'src/app/lagedarstellung/massnahmen.service';
import { DateTimePipe } from 'src/app/shared/pipes/date-time.pipe';
import { personenSelector } from '../../personen/+state/person.selectors';
import { TaktischesZeichenPopup } from '../../taktische-zeichen.interface';
import { TzDialogService } from '../../tz-dialog.service';
import { TzPopupToolbarComponent } from '../../tz-popup-toolbar/tz-popup-toolbar.component';

@Component({
  selector: 'app-massnahme-popup',
  standalone: true,
  imports: [CommonModule, FuehrungsebeneIconAndTextComponent, MatBadgeModule, DateTimePipe, TzPopupToolbarComponent],
  templateUrl: './massnahme-popup.component.html',
  styleUrls: ['./massnahme-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MassnahmePopupComponent implements OnInit, TaktischesZeichenPopup {
  @Input({ required: true }) dto!: FuehrungsebeneMassnahmeDTO;

  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private store = inject(Store);
  private tzDialogService = inject(TzDialogService);

  protected parentFuehrungsebene?: FuehrungsebeneDTO;
  protected erledigtDurchRolleText?: string;
  protected erledigtDurchPerson?: PersonDTO;
  protected zuErledigenSachgebietText?: string;

  ngOnInit(): void {
    this.parentFuehrungsebene = this.fuehrungsebeneService
      .getAllFuehrungsebenen()
      .find((fuehrungsebeneDTO) => fuehrungsebeneDTO.id === this.dto.fuehrungsebeneId);

    if (this.dto.erledigtDurchRolle) {
      this.erledigtDurchRolleText = MassnahmenService.rolleMapping.get(this.dto.erledigtDurchRolle);
    }

    if (this.dto.erledigtDurchPersonId) {
      this.store
        .select(personenSelector)
        .pipe(take(1))
        .subscribe(
          (personen) =>
            (this.erledigtDurchPerson = personen.find((person) => person.id === this.dto.erledigtDurchPersonId))
        );
    }

    if (this.dto.zuErledigenSachgebiet) {
      this.zuErledigenSachgebietText = MassnahmenService.sachgebietMapping.get(this.dto.zuErledigenSachgebiet);
    }
  }

  moveDto() {
    this.tzDialogService.openTzMoveDialog([this.dto], false);
  }

  editDto() {
    this.tzDialogService.openEditTzDialog(this.dto);
  }
}
