<mat-toolbar>
  <h2>Logistik-Führungsebenen bearbeiten</h2>
</mat-toolbar>

<mat-dialog-content class="logistik-content">
  <app-organigramm-fuehrungsebene-card [fuehrungsebeneDTO]="logistikDTO" />
  <!-- 
      Child-Führungsebenen
     -->
  <div class="child-fuehrungsebenen" *ngIf="logistikChildren.length">
    <!-- Vertikale Linie zu Hauptlogistik-Führungsebene -->
    <svg width="1" height="30" *ngIf="logistikChildren.length">
      <line x1="0" y1="0" x2="0" y2="100%" stroke="black" stroke-width="2" />
    </svg>

    <!-- Zeile mit allen Child-Führungsebenen -->
    <div class="child-fuehrungsebene-row">
      <div class="child-fuehrungsebene-column" *ngFor="let childFuehrungsebene of logistikChildren; let i = index">
        <!-- Horizontale Linie oberhalb der Child-Führungsebenen, um diese zu verbinden -->
        <svg width="100%" height="1" *ngIf="logistikChildren.length > 1">
          <line
            [attr.x1]="i > 0 ? '0' : '50%'"
            y1="0"
            [attr.x2]="i < logistikChildren.length - 1 ? '100%' : '50%'"
            y2="0"
            stroke="black"
            stroke-width="2"
          />
        </svg>

        <!-- Vertikale Linie zwischen Child-Führungsebene und der horizontalen Verbindungslinie aller Child-Führungsebenen -->
        <svg width="1" height="30">
          <line x1="0" y1="0" x2="0" y2="100%" stroke="black" stroke-width="2" />
        </svg>
        <app-organigramm-fuehrungsebene-card [fuehrungsebeneDTO]="childFuehrungsebene" />
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="accent">Schließen</button>
</mat-dialog-actions>
