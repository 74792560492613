import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, PersonDTO } from 'src/app/api/build/openapi';

export const personActions = createActionGroup({
  source: 'Personen',
  events: {
    'Reset Store': emptyProps(),

    'Get Personen': props<{ lageId: string }>(),
    'Get Personen Success': props<{ personen: PersonDTO[]; lageId: string }>(),
    'Get Personen Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Person': props<{ lageId: string; person: PersonDTO }>(),
    'Create Person Success': props<{ newPerson: PersonDTO }>(),
    'Create Person Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Person': props<{ lageId: string; personId: string; personDTO: PersonDTO }>(),
    'Patch Person Success': props<{ patchedPerson: PersonDTO }>(),
    'Patch Person Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Person Success': props<{ deletedPersonDTO: PersonDTO }>(),
  },
});
