import { createReducer, on } from '@ngrx/store';
import { TaktischeFormationDTO } from 'src/app/api/build/openapi';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { TaktischeFormationStateInterface } from './taktische-formation-state.interface';
import { taktischeFormationActions } from './taktische-formation.actions';

export const initialState: TaktischeFormationStateInterface = {
  isCreating: false,
  isLoading: false,
  isPatching: false,
  taktischeFormationen: [],
  loadedLageId: '',
};

export const taktischeFormationReducer = createReducer(
  initialState,

  // Reset Store
  on(taktischeFormationActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get TaktischeFormationen
  on(taktischeFormationActions.getTaktischeFormationen, (state) => ({
    ...state,
    isLoading: true,
    errorResponse: undefined,
  })),
  on(taktischeFormationActions.getTaktischeFormationenSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    taktischeFormationen: action.taktischeFormationDTOs,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(taktischeFormationActions.getTaktischeFormationenFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create TaktischeFormation
  on(taktischeFormationActions.createTaktischeFormation, (state) => ({
    ...state,
    isCreating: true,
    errorResponse: undefined,
  })),
  on(taktischeFormationActions.createTaktischeFormationSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    taktischeFormationen: [...state.taktischeFormationen, action.createdTaktischeFormationDTO],
    errorResponse: undefined,
  })),
  on(taktischeFormationActions.createTaktischeFormationFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch TaktischeFormation
  on(taktischeFormationActions.patchTaktischeFormation, (state) => ({
    ...state,
    isPatching: true,
    errorResponse: undefined,
  })),
  on(taktischeFormationActions.patchTaktischeFormationSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    taktischeFormationen: replaceOrKeepCurrent(state.taktischeFormationen, action.patchedTaktischeFormationDTO),
    errorResponse: undefined,
  })),
  on(taktischeFormationActions.patchTaktischeFormationFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete TaktischeFormation
  on(taktischeFormationActions.deleteTaktischeFormationSuccess, (state, action) => ({
    ...state,
    taktischeFormationen: state.taktischeFormationen.filter(
      (taktischeFormation: TaktischeFormationDTO) => taktischeFormation.id !== action.deletedTaktischeFormationDTO.id
    ),
  }))
);
