import { Component, inject, Input } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

export interface Mannschaft {
  staerke1: number;
  staerke2: number;
  staerke3: number;
  fahrzeugCount?: number;
  formationCount?: number;
}

/**
 * Form für Mannschaftsstärke-Felder
 *
 * Optional können Felder für Anzahl Fahrzeuge/Taktische Formationen eingeblendet werden
 */
@Component({
  selector: 'app-mannschaft-form',
  standalone: true,
  imports: [MatInputModule, MatFormFieldModule, ReactiveFormsModule, MatCardModule],
  templateUrl: './mannschaft-form.component.html',
  styleUrl: './mannschaft-form.component.scss',
})
export class MannschaftFormComponent {
  private formBuilder = inject(NonNullableFormBuilder);

  @Input() showEinheitenCount = false;

  protected formGroup = this.formBuilder.group({
    staerke1: this.formBuilder.control<number>(0),
    staerke2: this.formBuilder.control<number>(0),
    staerke3: this.formBuilder.control<number>(0),
    fahrzeugCount: this.formBuilder.control<number>(0),
    formationCount: this.formBuilder.control<number>(0),
  });

  setMannschaft(mannschaft: Mannschaft) {
    this.formGroup.controls.staerke1.setValue(mannschaft.staerke1);
    this.formGroup.controls.staerke2.setValue(mannschaft.staerke2);
    this.formGroup.controls.staerke3.setValue(mannschaft.staerke3);
    this.formGroup.controls.fahrzeugCount.setValue(mannschaft.fahrzeugCount || 0);
    this.formGroup.controls.formationCount.setValue(mannschaft.formationCount || 0);
  }

  validate(): boolean {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return false;
    }
    return true;
  }

  getMannschaft(): Mannschaft {
    return {
      staerke1: this.formGroup.controls.staerke1.value,
      staerke2: this.formGroup.controls.staerke2.value,
      staerke3: this.formGroup.controls.staerke3.value,
      fahrzeugCount: this.formGroup.controls.fahrzeugCount.value,
      formationCount: this.formGroup.controls.formationCount.value,
    };
  }
}
