import { AsyncPipe, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Output, ViewChild, inject } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SafeHtml } from '@angular/platform-browser';
import { Geometry } from 'geojson';
import {
  ErrorResponse,
  FahrzeugDTO,
  FotoDTO,
  PersonDTO,
  TaktischeFormationDTO,
  TaktischesZeichenDTO,
  TaktischesZeichenStatus,
  TaktischesZeichenTyp,
} from 'src/app/api/build/openapi';
import { GeometryPipe } from 'src/app/shared/pipes/geometry.pipe';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { LogService } from 'src/app/shared/services/log.service';
import { SvgSanitizer } from 'src/app/shared/svg-sanitizer';
import { TaktischeZeichenEditorDialogComponent } from 'src/app/taktische-zeichen/taktische-zeichen-editor-dialog/taktische-zeichen-editor-dialog.component';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';
import { ComponentQualificationService } from '../../../misc/component-qualification.service';
import { LastUpdatedComponent } from '../../../shared/last-updated/last-updated.component';
import { FuehrungsebeneService } from '../../fuehrungsebene/fuehrungsebene.service';
import { AnlassEreignisFormComponent } from '../anlass-ereignis/anlass-ereignis-form/anlass-ereignis-form.component';
import { BefehlsstelleFormComponent } from '../befehlsstelle/befehlsstelle-form/befehlsstelle-form.component';
import { FahrzeugFormComponent } from '../fahrzeuge/fahrzeug-form/fahrzeug-form.component';
import { FotoFormComponent } from '../foto/foto-form/foto-form.component';
import { FotoService } from '../foto/foto.service';
import { GebaeudeFormComponent } from '../gebaeude/gebaeude-form/gebaeude-form.component';
import { GebietFormComponent } from '../gebiete/gebiet-form/gebiet-form.component';
import { GefahrFormComponent } from '../gefahren/gefahr-form/gefahr-form.component';
import { MassnahmeFormComponent } from '../massnahmen/massnahme-form/massnahme-form.component';
import { PersonFormComponent } from '../personen/person-form/person-form.component';
import { PersonenschadenFormComponent } from '../personenschaden/personenschaden-form/personenschaden-form.component';
import { StelleEinrichtungFormComponent } from '../stelle-einrichtung/stelle-einrichtung-form/stelle-einrichtung-form.component';
import { TaktischeFormationFormComponent } from '../taktische-formation/taktische-formation-form/taktische-formation-form.component';
import { TaktischeFormationService } from '../taktische-formation/taktische-formation.service';
import { TaktischesZeichenForm } from '../taktische-zeichen.interface';
import { TierschadenFormComponent } from '../tierschaden/tierschaden-form/tierschaden-form.component';
import { TzGeometryMapComponent } from '../tz-geometry-map/tz-geometry-map.component';

interface PreventSave {
  /**
   * Verhindert das diese Komponente das Speichern auslöst und liefert das Ergebnis via `@Output handleSave`
   *
   * @TODO ausbauen, bisher nur Soderfällt für Bibliothek
   */
  preventSaveActions?: boolean;
}

/**tyTypes String ist der filename des icons */
export interface CreateTzDialogData extends PreventSave {
  fuehrungsebeneId?: string;
  state?: TaktischesZeichenStatus;
  taktischesZeichenTyp: TaktischesZeichenTyp;
  allowedTzTypes?: TaktischesZeichenTyp[];
  geometry?: Geometry;
}

export interface EditTzDialogData extends PreventSave {
  taktischesZeichenTyp: TaktischesZeichenTyp;
  dto: TaktischesZeichenDTO;
}

export interface DataUrlChangeEvent {
  dataUrl: string;
  customZeichen: boolean;
}

function isCreateTzDialogData(dialogData: CreateTzDialogData | EditTzDialogData) {
  return !('dto' in dialogData);
}

@Component({
  selector: 'app-taktische-zeichen-dialog',
  templateUrl: './taktische-zeichen-dialog.component.html',
  styleUrls: ['./taktische-zeichen-dialog.component.scss'],
  standalone: true,
  imports: [
    GeometryPipe,
    MatToolbarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    NgIf,
    AsyncPipe,
    KeyValuePipe,
    FahrzeugFormComponent,
    PersonFormComponent,
    GefahrFormComponent,
    MassnahmeFormComponent,
    AnlassEreignisFormComponent,
    StelleEinrichtungFormComponent,
    BefehlsstelleFormComponent,
    GebaeudeFormComponent,
    TaktischeFormationFormComponent,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    TzGeometryMapComponent,
    MatTooltipModule,
    PersonenschadenFormComponent,
    GebietFormComponent,
    TierschadenFormComponent,
    FotoFormComponent,
    MatCheckboxModule,
    ReactiveFormsModule,
    LastUpdatedComponent,
  ],
})
export class TaktischeZeichenDialogComponent implements AfterViewInit {
  allowedTzTypes: TaktischesZeichenTyp[] = [
    TaktischesZeichenTyp.Anlass,
    TaktischesZeichenTyp.Befehlsstelle,
    TaktischesZeichenTyp.Fahrzeug,
    TaktischesZeichenTyp.Foto,
    TaktischesZeichenTyp.Gebaeude,
    TaktischesZeichenTyp.Gebiet,
    TaktischesZeichenTyp.Gefahr,
    TaktischesZeichenTyp.Massnahme,
    TaktischesZeichenTyp.Person,
    TaktischesZeichenTyp.Personenschaden,
    TaktischesZeichenTyp.Stelle,
    TaktischesZeichenTyp.TaktischeFormation,
    TaktischesZeichenTyp.Tierschaden,
  ];

  @ViewChild(TzGeometryMapComponent) map!: TzGeometryMapComponent;
  @ViewChild(MatSelect) zeichenType!: MatSelect;

  @ViewChild(AnlassEreignisFormComponent) anlassEreignisForm?: AnlassEreignisFormComponent;
  @ViewChild(BefehlsstelleFormComponent) befehlsstelleForm?: BefehlsstelleFormComponent;
  @ViewChild(FahrzeugFormComponent) fahrzeugForm?: FahrzeugFormComponent;
  @ViewChild(FotoFormComponent) fotoForm?: FotoFormComponent;
  @ViewChild(GebaeudeFormComponent) gebaeudeForm?: GebaeudeFormComponent;
  @ViewChild(GebietFormComponent) gebietForm?: GebietFormComponent;
  @ViewChild(GefahrFormComponent) gefahrForm?: GefahrFormComponent;
  @ViewChild(MassnahmeFormComponent) massnahmeForm?: MassnahmeFormComponent;
  @ViewChild(PersonFormComponent) personForm?: PersonFormComponent;
  @ViewChild(PersonenschadenFormComponent) personenschadenForm?: PersonenschadenFormComponent;
  @ViewChild(StelleEinrichtungFormComponent) stelleEinrichtungForm?: StelleEinrichtungFormComponent;
  @ViewChild(TaktischeFormationFormComponent) taktischeFormationForm?: TaktischeFormationFormComponent;
  @ViewChild(TierschadenFormComponent) tierschadenForm?: TierschadenFormComponent;

  anzeigename = '';
  dataUrl: SafeHtml | undefined = undefined;
  isCustomTz = false;
  taktischesZeichenGeometry?: Geometry;

  // True, wenn neues DTO angelegt wird. False, wenn bestehendes DTO editiert wird.
  newObject = false;
  dtoToEdit?: TaktischesZeichenDTO;

  TaktischesZeichenTyp = TaktischesZeichenTyp;
  taktischesZeichenTyp: TaktischesZeichenTyp = TaktischesZeichenTyp.Fahrzeug;
  dialogHeader = 'Fahrzeug-Editor';

  hoverTz = false;

  readonly currentLageId = this.fuehrungsebeneService.getCurrentLage()?.id || '';

  private clipboardService = inject(ClipboardService);
  private geometryPipe = inject(GeometryPipe);
  private taktischeZeichenService = inject(TaktischeZeichenService);
  private fotoService = inject(FotoService);
  private taktischeFormationService = inject(TaktischeFormationService);
  taktischeZeichenInfoMapping = this.taktischeZeichenService.taktischeZeichenInfoMapping;

  @Output() handleSave = new EventEmitter<TaktischesZeichenDTO>();
  protected isSaving = false;

  private formBuilder = inject(NonNullableFormBuilder);
  fcVorlage = this.formBuilder.control<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: CreateTzDialogData | EditTzDialogData,
    private dialogRef: MatDialogRef<TaktischeZeichenDialogComponent>,
    public sanitizer: SvgSanitizer,
    private logService: LogService,
    private fuehrungsebeneService: FuehrungsebeneService,
    private dialog: MatDialog,
    protected componentService: ComponentQualificationService
  ) {}

  /**
   * Nach erfolgreichem Init sollte über DialogData bestimmbar sein, ob eine Person oder ein Fahrzeug editier werden soll.
   * Entsprechend dem Typ, wird dann die PersonForm oder die FahrzeugForm eingesetzt und mit einem ModelDTO gefüllt.
   * Wird über DialogData ein Model mitgeliefert, wird dieses genutzt. Ansonsten wird ein neues Model erzeugt.
   */
  ngAfterViewInit() {
    // setTimeout verhindert Fehlermeldung NG0100
    setTimeout(() => {
      if (!this.data.taktischesZeichenTyp) {
        this.logService.error(`Kein gültiger TaktischesZeichenTyp mitgeliefert.`);
        return;
      }

      this.taktischesZeichenTyp = this.data.taktischesZeichenTyp;
      this.zeichenType.value = this.taktischesZeichenTyp;

      setTimeout(() => {
        this.newObject = isCreateTzDialogData(this.data);
        if (this.newObject) {
          const createTzDialogData: CreateTzDialogData = this.data as CreateTzDialogData;
          this.allowedTzTypes = createTzDialogData.allowedTzTypes || this.allowedTzTypes;
          this.prepareNewDto();
        } else {
          this.dtoToEdit = (this.data as EditTzDialogData).dto;
          if (!this.dtoToEdit.id) {
            return;
          }

          if (this.dtoToEdit.typ === TaktischesZeichenTyp.Foto) {
            this.fotoService.getFotoDTO(this.dtoToEdit).subscribe((fotoDTO: FotoDTO) => {
              this.setFormDto(fotoDTO);
            });
          } else if (this.dtoToEdit.typ === TaktischesZeichenTyp.TaktischeFormation) {
            this.taktischeFormationService
              .getTaktischeFormationNested(this.dtoToEdit.id)
              .subscribe((tfNested) => this.setFormDto(tfNested));
          } else {
            this.setFormDto(this.dtoToEdit);
          }
        }
        this.updateDialogHeader();
        window.dispatchEvent(new Event('resize'));
      });
    });
  }

  private setFormDto(dto: TaktischesZeichenDTO) {
    if (this.taktischeZeichenInfoMapping.get(dto.typ)?.allowVorlage) {
      this.fcVorlage.setValue(!!(dto as FahrzeugDTO | PersonDTO | TaktischeFormationDTO).vorlage);
    }

    const currentForm = this.getCurrentForm();
    if (!currentForm) {
      this.logService.error(`Konnte kein passendes Form für Typ ${this.taktischesZeichenTyp} laden.`);
      return;
    }
    currentForm.setDTO(dto);

    if (!this.map) {
      this.logService.error('Karte nicht verfügbar');
      return;
    }
    this.map.setTaktischesZeichen(dto);

    if (dto.dataUrl) {
      this.updateTaktischesZeichen({ dataUrl: dto.dataUrl, customZeichen: !!dto.customZeichen });
    }

    this.updateDialogHeader();
    window.dispatchEvent(new Event('resize'));
  }

  private updateDialogHeader(): void {
    this.dialogHeader = this.taktischeZeichenInfoMapping.get(this.taktischesZeichenTyp)?.name + '-Editor';
  }

  /**
   * Setzt ein neues DTO in das aktuell angezeigte Form.
   */
  private prepareNewDto(): void {
    const createTzDialogData = this.data as CreateTzDialogData;
    const newDto = {
      anzeigename: '',
      lageId: this.currentLageId,
      fuehrungsebeneId: createTzDialogData.fuehrungsebeneId,
      status: createTzDialogData.state ? { status: createTzDialogData.state } : undefined,
      typ: this.taktischesZeichenTyp || TaktischesZeichenTyp.Fahrzeug,
      geometry: createTzDialogData.geometry,
    };
    setTimeout(() => {
      const currentForm = this.getCurrentForm();
      currentForm?.setDTO(newDto);
      this.map?.setTaktischesZeichen(newDto);
    });
  }

  /**
   * Wechsel zwischen Forms der verschiedenen Taktischen Zeichen.
   * Aktualisiert die angezeigte Form und das Model dahinter.
   */
  changeZeichenType(taktischesZeichenTyp: TaktischesZeichenTyp): void {
    this.taktischesZeichenTyp = taktischesZeichenTyp;

    // Kurz warten, damit dynamische Form-Component geladen wurde
    setTimeout(() => {
      this.prepareNewDto();
      this.updateDialogHeader();
      window.dispatchEvent(new Event('resize'));
    });
  }

  private getCurrentForm(): TaktischesZeichenForm | undefined {
    switch (this.taktischesZeichenTyp) {
      case TaktischesZeichenTyp.Anlass:
        return this.anlassEreignisForm;
      case TaktischesZeichenTyp.Befehlsstelle:
        return this.befehlsstelleForm;
      case TaktischesZeichenTyp.Fahrzeug:
        return this.fahrzeugForm;
      case TaktischesZeichenTyp.Gebaeude:
        return this.gebaeudeForm;
      case TaktischesZeichenTyp.Gefahr:
        return this.gefahrForm;
      case TaktischesZeichenTyp.Massnahme:
        return this.massnahmeForm;
      case TaktischesZeichenTyp.Person:
        return this.personForm;
      case TaktischesZeichenTyp.Stelle:
        return this.stelleEinrichtungForm;
      case TaktischesZeichenTyp.TaktischeFormation:
        return this.taktischeFormationForm;
      case TaktischesZeichenTyp.Personenschaden:
        return this.personenschadenForm;
      case TaktischesZeichenTyp.Gebiet:
        return this.gebietForm;
      case TaktischesZeichenTyp.Foto:
        return this.fotoForm;
      case TaktischesZeichenTyp.Tierschaden:
        return this.tierschadenForm;
      default:
        return undefined;
    }
  }

  updateAnzeigename(anzeigename: string): void {
    this.anzeigename = anzeigename;
  }

  updateTaktischesZeichen(event: DataUrlChangeEvent): void {
    this.dataUrl = event.dataUrl;

    if (this.map) {
      this.map.setMarkerIcon(event.dataUrl);
    }

    this.isCustomTz = event.customZeichen;
  }

  updateTzDto(taktischesZeichen: TaktischesZeichenDTO) {
    this.map.setTaktischesZeichen({ ...taktischesZeichen, geometry: this.taktischesZeichenGeometry });
  }

  geometryChanged(event: GeoJSON.Geometry) {
    this.taktischesZeichenGeometry = event;
  }

  copyGeometryToClipboard(event: MouseEvent): void {
    if (!this.taktischesZeichenGeometry) {
      return;
    }

    switch (this.taktischesZeichenGeometry.type) {
      case 'Point':
        this.clipboardService.copy(this.geometryPipe.transform(this.taktischesZeichenGeometry));
        break;
      case 'LineString':
      case 'Polygon':
        this.clipboardService.copy(JSON.stringify(this.taktischesZeichenGeometry));
    }
    event.stopPropagation();
  }

  protected save(): void {
    if (this.isSaving) {
      return;
    }

    const currentForm = this.getCurrentForm();
    if (!currentForm) {
      this.logService.error('Keine Form zum Speichern verfügbar.');
      return;
    }

    const tzDTO = currentForm.getDTO();
    if (!tzDTO) {
      return;
    }

    tzDTO.geometry = this.taktischesZeichenGeometry;
    if (this.taktischeZeichenInfoMapping.get(tzDTO.typ)?.allowVorlage) {
      (tzDTO as PersonDTO | FahrzeugDTO | TaktischeFormationDTO).vorlage = this.fcVorlage.value;
    }

    if (this.data.preventSaveActions) {
      this.handleSave.emit(tzDTO);
      return;
    }

    this.isSaving = true;
    this.taktischeZeichenService.saveTaktischesZeichen(
      tzDTO,
      this.currentLageId,
      this.closeObserver,
      this.errorObserver
    );
  }

  protected openTzDialog() {
    this.dialog
      .open(TaktischeZeichenEditorDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.updateTaktischesZeichen({ dataUrl: result.dataUrl, customZeichen: true });
          this.getCurrentForm()?.setCustomTz(result.dataUrl);
        }
      });
  }

  protected removeCustomTz() {
    this.getCurrentForm()?.setCustomTz('');
    this.isCustomTz = false;
  }

  private closeObserver = (tzDTO: TaktischesZeichenDTO) => {
    this.dialogRef.close(tzDTO);
  };

  private errorObserver = (error: { errorResponse: ErrorResponse }) => {
    this.logService.error(error.errorResponse.errorMessages?.join('\n'));
    this.isSaving = false;
  };
}
