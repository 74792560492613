<mat-toolbar>
  <h2>
    {{
      fuehrungsebeneService.fuehrungsebenentypMapping.get(fuehrungsebeneDTO.typ)?.longname +
        (fuehrungsebeneDTO.id ? " bearbeiten" : " hinzufügen")
    }}
  </h2>
</mat-toolbar>

<div class="content">
  <app-fuehrungsebene-details-panel [fuehrungsebeneDTO]="fuehrungsebeneDTO" [filteredTypes]="filteredTypes" />
</div>

<div class="footer">
  <app-last-updated *ngIf="fuehrungsebeneDTO.id" [lastUpdated]="fuehrungsebeneDTO" />
  <mat-dialog-actions class="end">
    <button
      mat-raised-button
      *ngIf="
        fuehrungsebeneDTO.id &&
        fuehrungsebeneDTO.typ !== Fuehrungsebenentyp.Lage &&
        fuehrungsebeneDTO.typ !== Fuehrungsebenentyp.Schadengebiet
      "
      color="warn"
      class="ml-1"
      (click)="askDelete()"
    >
      Entfernen
    </button>
    <button mat-raised-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="accent" (click)="save()" [disabled]="isSaving$ | async">Speichern</button>
  </mat-dialog-actions>
</div>
