<form [formGroup]="massnahmeForm">
  <mat-toolbar>
    <h2>Maßnahme {{ isEdit ? "bearbeiten" : "hinzufügen" }}</h2>
  </mat-toolbar>
  <mat-dialog-content class="grid-container">
    <mat-card class="grid-prio">
      <div class="top-row">
        <div class="prio" *ngIf="this.massnahmeForm.value.prioritaet">
          <div
            class="prio-circle"
            [style]="'background-color:' + prioMapping.get(this.massnahmeForm.value.prioritaet)?.farbe"
          >
            <mat-icon>{{ prioMapping.get(this.massnahmeForm.value.prioritaet)?.icon }}</mat-icon>
          </div>

          <mat-form-field>
            <mat-label>Priorität</mat-label>
            <mat-select formControlName="prioritaet">
              <mat-option [value]="Prioritaet.Blitz">
                <mat-icon>flash_on</mat-icon>
                Blitz
              </mat-option>
              <mat-option [value]="Prioritaet.Sofort">
                <mat-icon>arrow_upward</mat-icon>
                Sofort
              </mat-option>
              <mat-option [value]="Prioritaet.Normal">
                <mat-icon>format_align_justify</mat-icon>
                Normal
              </mat-option>
              <mat-option [value]="Prioritaet.Zurueckgestellt">
                <mat-icon>arrow_downward</mat-icon>
                Zurückgestellt
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="erledigt">
          <input class="example-margin" type="checkbox" formControlName="erledigt" />
          <p>erledigt</p>
        </div>
      </div>
      <div>
        <mat-form-field class="textfield-100" appearance="outline">
          <mat-label>Anzeigename</mat-label>
          <input matInput formControlName="anzeigename" />
          <mat-error>Bitte Anzeigename eingeben</mat-error>
        </mat-form-field>
      </div>
    </mat-card>

    <mat-card class="grid-person-zuerledigen">
      <h2>erledigen durch</h2>
      <mat-form-field class="textfield-100">
        <mat-label>Funktion</mat-label>
        <mat-select formControlName="zuErledigenFunktion">
          <mat-option *ngFor="let funktion of Funktionen | keyvalue" [value]="funktion.value">{{
            funktion.value | funktion
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="textfield-100">
        <mat-label>Bereich</mat-label>
        <mat-select formControlName="zuErledigenBereich">
          <mat-option *ngFor="let bereich of Bereiche | keyvalue" [value]="bereich.value">{{
            bereich.value | bereich
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="textfield-100" appearance="outline">
        <mat-label>Datum/Uhrzeit</mat-label>
        <input matInput type="datetime-local" formControlName="zuErledigenDatum" />
      </mat-form-field>
    </mat-card>

    <mat-card class="grid-person-biswann">
      <h2>erledigen bis</h2>
      <mat-form-field class="textfield-100">
        <mat-label>Anwesenheit</mat-label>
        <mat-select formControlName="bisWannAnwesenheit">
          <mat-option *ngFor="let anwesenheit of anwesenheitService.anwesenheiten" [value]="anwesenheit">{{
            anwesenheit.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="textfield-100" appearance="outline">
        <mat-label>Datum/Uhrzeit</mat-label>
        <input matInput type="datetime-local" formControlName="bisWannDatum" />
        <mat-error>Bitte gültiges Datum eingeben</mat-error>
      </mat-form-field>
    </mat-card>

    <mat-card class="grid-massnahme">
      <mat-form-field class="aufgabe-text-area">
        <mat-label>Aufgaben, Benachrichtigungen, Notizen</mat-label>
        <textarea matInput cdkTextareaAutosize formControlName="text"></textarea>
      </mat-form-field>
    </mat-card>

    <mat-card class="grid-person-erledigtdurch">
      <h2>erledigt durch</h2>
      <mat-form-field class="textfield-100">
        <mat-label>Anwesenheit</mat-label>
        <mat-select formControlName="erledigtDurchAnwesenheit">
          <mat-option *ngFor="let anwesenheit of anwesenheitService.anwesenheiten" [value]="anwesenheit">{{
            anwesenheit.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="textfield-100" appearance="outline">
        <mat-label>Datum/Uhrzeit</mat-label>
        <input matInput type="datetime-local" formControlName="erledigtDurchDatum" />
      </mat-form-field>
    </mat-card>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="accent" (click)="onClickSpeichern()" [disabled]="isSaving">Speichern</button>
  </mat-dialog-actions>
</form>
