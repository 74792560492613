@for (tzDto of tzDtos; track $index) {
<div
  class="list-row"
  [ngClass]="{ 'selected-list-row': tzDto.id === selectedTzDto?.id && tzDto.typ === selectedTzDto?.typ }"
  (click)="selectTzDto(tzDto)"
>
  <img [src]="tzDto.dataUrl" />
  <span>{{ tzDto.anzeigename }}</span>
</div>
}
