<form [formGroup]="formGroup">
  <mat-form-field class="less-margin">
    <mat-label>Einheit</mat-label>
    <input matInput [formControl]="formGroup.controls.einheit" />
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.einheit.errors) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Auftrag</mat-label>
    <textarea matInput [formControl]="formGroup.controls.auftrag" rows="3"></textarea>
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.auftrag.errors) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Mittel</mat-label>
    <input matInput [formControl]="formGroup.controls.mittel" />
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.mittel.errors) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Ziel</mat-label>
    <input matInput [formControl]="formGroup.controls.ziel" />
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.ziel.errors) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Weg</mat-label>
    <textarea matInput [formControl]="formGroup.controls.weg" rows="3"></textarea>
    <mat-error>{{ errorService.getErrorMessage(formGroup.controls.weg.errors) }}</mat-error>
  </mat-form-field>
</form>
