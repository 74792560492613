<app-tz-popup-toolbar [tzDto]="dto" (editClicked)="editDto()" (moveClicked)="moveDto()" />

<div class="content">
  <app-fuehrungsebene-icon-and-text *ngIf="parentFuehrungsebene" [fuehrungsebeneDTO]="parentFuehrungsebene" />

  <div class="row">
    <div class="links">
      <ng-container *ngIf="fahrzeugDTO">
        <span>{{ fahrzeugDTO.status?.status | taktischesZeichenStatus }}</span>
        <span> [{{ fahrzeugDTO.status?.zeitpunktStatusWechsel | dateTime | async }}] </span>
      </ng-container>
    </div>

    <img class="zeichen" [src]="dto.dataUrl" />

    <div class="rechts">
      {{ dto.beweglichkeit === Beweglichkeit.Beweglich ? "Beweglich" : "Ortsfest" }}

      <img *ngIf="fahrzeugDTO" [src]="fahrzeugDTO.dataUrl" [matTooltip]="fahrzeugDTO.anzeigename" />
      <img *ngIf="gebaeudeDTO" [src]="gebaeudeDTO.dataUrl" [matTooltip]="gebaeudeDTO.anzeigename" />
    </div>
  </div>

  <div class="unten">
    {{ dto.ortsangabe }}
  </div>

  <!-- Kontakte -->
  <app-kontakt-icons
    *ngIf="dto.kommunikationOptionen && dto.kommunikationOptionen?.length"
    [kontaktmoeglichkeiten]="dto.kommunikationOptionen"
  />
</div>
