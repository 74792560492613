import { Injectable, OnDestroy, inject } from '@angular/core';
import { Observable, Subject, takeUntil, timer } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { KeyValue } from '@angular/common';
import { Prioritaet, StabMassnahmeDTO } from 'src/app/api/build/openapi';
import { LagePrio } from 'src/app/lagedarstellung/massnahmen/massnahme-add-dialog/massnahme-add-dialog.component';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { currentLageSelector } from 'src/app/lagedarstellung/lagen/+state/lage.selectors';
import { stabMassnahmenActions } from './+state/stabmassnahmen.actions';
import { currentStabMassnahmenSelector } from './+state/stabmassnahmen.selectors';
import { PersonenInfo } from './stab-massnahme-beenden-dialog/stab-massnahme-beenden-dialog.component';
import { CompareService } from 'src/app/shared/services/compare.service';

@Injectable({
  providedIn: 'root',
})
export class StabMassnahmenService implements OnDestroy {
  //Sortierfunktion für keyvalue pipe
  static prioSort = (a: KeyValue<Prioritaet, LagePrio>, b: KeyValue<Prioritaet, LagePrio>): number => {
    return b.value.wert - a.value.wert;
  };
  store: Store<AppStateInterface> = inject(Store);

  private destroyed$ = new Subject<void>();
  private lageId: string | undefined = '';

  currentMassnahmen$: Observable<StabMassnahmeDTO[]>;
  erledigteMassnahmen = 0;
  mengeMassnahmen = 0;
  massnahmen: StabMassnahmeDTO[] = [];

  private unerledigteMassnahmenCount = 0;
  compare = inject(CompareService);

  constructor() {
    this.store.pipe(select(currentLageSelector)).subscribe((lage) => (this.lageId = lage?.id));
    timer(10000, 10000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((n) => this.countUnerledigt());
    this.currentMassnahmen$ = this.store.pipe(select(currentStabMassnahmenSelector), takeUntil(this.destroyed$));
    this.currentMassnahmen$.subscribe((m) => {
      this.erledigteMassnahmen = m.filter((m) => m.erledigt === true).length;
      this.mengeMassnahmen = m.length;
      this.massnahmen = [...m];
    });
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
  }
  public addMassnahme(massnahme: StabMassnahmeDTO) {
    this.store.dispatch(stabMassnahmenActions.addStabMassnahme({ lageId: this.lageId, massnahme: massnahme }));
  }
  public getErledigtProzent(): number {
    return Math.floor((this.erledigteMassnahmen / this.mengeMassnahmen) * 100);
  }
  public updateMassnahme(massnahme: StabMassnahmeDTO) {
    this.store.dispatch(
      stabMassnahmenActions.patchStabMassnahme({
        lageid: this.lageId!,
        massnahmeId: massnahme.id!,
        massnahme: massnahme,
      })
    );
  }
  public massnahmeBeenden(massnahme: StabMassnahmeDTO, person: PersonenInfo) {
    //Erledigt name fehlt
    const patchedMassnahme: StabMassnahmeDTO = {
      ...massnahme,
      erledigt: true,
      erledigtDurchAnwesenheitId: person.erledigtDurchAnwesenheit!.id,
      erledigtDurchDatum: person.erledigtDurchDatum!.toString(),
    };
    this.store.dispatch(
      stabMassnahmenActions.patchStabMassnahme({
        lageid: this.lageId!,
        massnahmeId: massnahme.id!,
        massnahme: patchedMassnahme,
      })
    );
  }
  //Massnahmen, die erledigt sein sollten abhaenig von eledigt und personBisWann.date
  public getUnerledigt(): number {
    return this.unerledigteMassnahmenCount;
  }
  getMassnahmenSearch(search: string): StabMassnahmeDTO[] {
    return this.massnahmen.filter(
      (m) =>
        m.anzeigename!.toLowerCase().startsWith(search.toLowerCase()) ||
        m.text!.toLowerCase().startsWith(search.toLowerCase())
    );
  }
  getMAssnahmenAll(): StabMassnahmeDTO[] {
    return this.massnahmen;
  }
  private countUnerledigt() {
    const abgelaufen: StabMassnahmeDTO[] = this.massnahmen
      .filter((a) => !a.erledigt)
      .filter((a) => this.compare.isDateStringBeforeNow(a.bisWannDatum));
    this.unerledigteMassnahmenCount = abgelaufen.length;
  }
}
