import { inject, Injectable, ViewContainerRef } from '@angular/core';
import { TaktischeFormationDTO, TaktischesZeichenDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { BubbleMenueConfig, BubblemenueService } from 'src/app/shared/services/bubblemenue/bubblemenue.service';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';
import { TzItemEvent } from './taktische-zeichen-container/taktische-zeichen-item/taktische-zeichen-item.component';
import { TzDialogService } from './tz-dialog.service';
import { TzMultiSelectService } from './tz-multi-select-service';

@Injectable({
  providedIn: 'root',
})
export class TzBubbleMenuService {
  private bubbleMenuService = inject(BubblemenueService);
  private multiSelectService = inject(TzMultiSelectService);
  private taktischeZeichenService = inject(TaktischeZeichenService);
  private tzDialogService = inject(TzDialogService);

  /**
   * Öffnet ein BubbleMenu für Taktische Zeichen mit unterschiedlichen Aktionen,
   * je nachdem, ob nur ein einzelnes oder mehrere Taktische Zeichen ausgewählt wurden.
   */
  openTzBubbleMenu(event: TzItemEvent, calledFromBib = false) {
    if (!this.multiSelectService.hasSelection()) {
      console.warn('Bubble-Menu kann nicht geöffnet werden, da keine TZs ausgewählt sind.');
      return;
    }

    const multipleTzSelected: boolean = this.multiSelectService.hasMultipleSelections();
    if (multipleTzSelected) {
      this.openForMultipleTzs(this.multiSelectService.getSelection(), event.viewContainerRef, calledFromBib);
    } else {
      this.openForSingleTz(event.dto, event.viewContainerRef, calledFromBib);
    }
  }

  /**
   * Öffnet ein BubbleMenü mit Aktionen, die für einzelne Taktische Zeichen ausgeführt werden dürfen.
   */
  private openForSingleTz(tzDto: TaktischesZeichenDTO, viewContainerRef: ViewContainerRef, calledFromBib: boolean) {
    const menuConfigs: BubbleMenueConfig[] = [];

    // Info-Popup zu TZ anzeigen
    menuConfigs.push({
      matIcon: 'info',
      action: () => this.tzDialogService.openTzPopupDialog(tzDto),
      tooltip: 'Info',
    });

    // Lagekarte auf TZ-Geometrie positionieren
    if (!calledFromBib && tzDto.geometry) {
      menuConfigs.push({
        matIcon: 'center_focus_strong',
        action: () => this.taktischeZeichenService.focusTzOnLageMap(tzDto),
        tooltip: 'Fokussieren',
      });
    }

    // TZ editieren
    menuConfigs.push({
      matIcon: 'edit',
      action: () => this.tzDialogService.openEditTzDialog(tzDto),
      tooltip: 'Bearbeiten',
    });

    // Einheiten einer Taktischen Formation bearbeiten
    if (tzDto.typ === TaktischesZeichenTyp.TaktischeFormation) {
      menuConfigs.push({
        matIcon: 'account_tree',
        action: () => this.tzDialogService.openTaktischeFormationHierarchieDialog(tzDto as TaktischeFormationDTO),
        tooltip: 'Einheiten verwalten',
      });
    }

    // TZ verschieben
    menuConfigs.push({
      matIcon: 'move_down',
      action: () => {
        this.tzDialogService.openTzMoveDialog([tzDto], calledFromBib);
      },
      tooltip: 'Verschieben',
    });

    // TZ duplizieren
    menuConfigs.push({
      matIcon: 'copy',
      action: () => this.tzDialogService.openMultiplyDialog(tzDto),
      tooltip: 'Duplizieren',
    });

    // TZ löschen
    menuConfigs.push({
      ...this.getDeleteButtonConfig([tzDto], calledFromBib),
      action: () => this.tzDialogService.openTzDeleteDialog([tzDto], calledFromBib),
    });

    this.bubbleMenuService.openMenue(viewContainerRef, menuConfigs);
  }

  /**
   * Öffnet ein BubbleMenü mit Aktionen, die für mehrere Taktische Zeichen gleichzeitig ausgeführt werden dürfen.
   */
  private openForMultipleTzs(
    tzDtos: TaktischesZeichenDTO[],
    viewContainerRef: ViewContainerRef,
    calledFromBib: boolean
  ) {
    const menuConfigs: BubbleMenueConfig[] = [];

    // TZ verschieben
    menuConfigs.push({
      matIcon: 'move_down',
      action: () => this.tzDialogService.openTzMoveDialog(tzDtos, calledFromBib),
      tooltip: 'Verschieben',
    });

    // TZ löschen
    menuConfigs.push({
      ...this.getDeleteButtonConfig(tzDtos, calledFromBib),
      action: () => this.tzDialogService.openTzDeleteDialog(tzDtos, calledFromBib),
    });

    this.bubbleMenuService.openMenue(viewContainerRef, menuConfigs);
  }

  /**
   * Generiert den Text für den Button zum Entfernen/Abziehen von Taktischen Zeichen.
   * Ist ein Taktisches Zeichen Teil der Bibliothek aber aktuell einer Lage zugeordnet, wird es "abgezogen".
   * Andernfalls wird es "entfernt".
   */
  private getDeleteButtonConfig(tzDtos: TaktischesZeichenDTO[], calledFromBib: boolean): BubbleMenueConfig {
    if (!tzDtos.length) {
      console.warn('Keine Taktischen Zeichen ausgewählt.');
      return { matIcon: '' };
    }

    let deleteVerb = this.taktischeZeichenService.getTzsDeleteVerb(tzDtos, calledFromBib);
    if (deleteVerb.length) {
      deleteVerb = deleteVerb.charAt(0).toUpperCase() + deleteVerb.slice(1);
    }
    return { tooltip: deleteVerb, matIcon: deleteVerb === 'Löschen' ? 'delete' : 'reply' };
  }
}
