import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Store } from '@ngrx/store';
import { ErrorService } from '@product/ise-error-lib';
import { take } from 'rxjs';
import {
  AuftragDTO,
  BefehlDTO,
  BefehlsstelleDTO,
  BefehlTyp,
  LangzeitbefehlDTO,
  MarschbefehlDTO,
  PersonDTO,
} from 'src/app/api/build/openapi';
import { DatetimeLocalAccessorDirective } from 'src/app/shared/accessors/datetime-local-accessor.directive';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { TzComboboxComponent } from 'src/app/shared/tz-combobox/tz-combobox.component';
import { befehlsstellenSelector } from '../../taktische-zeichen/befehlsstelle/+state/befehlsstelle.selectors';
import { personenSelector } from '../../taktische-zeichen/personen/+state/person.selectors';
import { BefehlFormComponent } from '../befehl-form/befehl-form.component';
import { LangzeitbefehlFormComponent } from '../langzeitbefehl-form/langzeitbefehl-form.component';
import { MarschbefehlFormComponent } from '../marschbefehl-form/marschbefehl-form.component';

export interface DialogData {
  newAuftrag: boolean;
  auftragToEdit?: AuftragDTO;
}

@Component({
  selector: 'app-auftrag-details-dialog',
  templateUrl: './auftrag-details-dialog.component.html',
  styleUrls: ['./auftrag-details-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatRadioModule,
    MatCheckboxModule,
    CleanableFormFieldComponent,
    MatInputModule,
    MatFormFieldModule,
    BefehlFormComponent,
    LangzeitbefehlFormComponent,
    MarschbefehlFormComponent,
    TzComboboxComponent,
    DatetimeLocalAccessorDirective,
  ],
})
export class AuftragDetailsDialogComponent implements AfterViewInit {
  BefehlTyp = BefehlTyp;

  @ViewChild(BefehlFormComponent) befehlForm?: BefehlFormComponent;
  @ViewChild(LangzeitbefehlFormComponent) langzeitbefehlForm?: LangzeitbefehlFormComponent;
  @ViewChild(MarschbefehlFormComponent) marschbefehlForm?: MarschbefehlFormComponent;

  private dialogData: DialogData = inject(MAT_DIALOG_DATA);
  protected errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);
  private store = inject(Store);

  formGroup = this.formBuilder.group({
    abgangsort: this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(2048)]),
    anlagen: this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(2048)]),
    befehlendeStelle: this.formBuilder.control<BefehlsstelleDTO | undefined>(undefined),
    befehlTyp: this.formBuilder.control<BefehlTyp>(BefehlTyp.Befehl, [Validators.required]),
    erledigt: this.formBuilder.control<boolean>(false),
    name: this.formBuilder.control<string>('', [Validators.required, Validators.maxLength(512)]),
    person: this.formBuilder.control<PersonDTO | undefined>(undefined),
    verteiler: this.formBuilder.control<string | undefined>(undefined, Validators.maxLength(2048)),
    zeitpunkt: this.formBuilder.control<Date | undefined>(undefined),
  });

  befehlsstelleValues$ = this.store.select(befehlsstellenSelector);
  personValues$ = this.store.select(personenSelector);

  auftragDto?: AuftragDTO;

  protected isSaving = false;

  constructor(public dialogRef: MatDialogRef<AuftragDetailsDialogComponent>) {
    this.formGroup.controls.befehlTyp.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      // Kurz warten, damit passende Sub-Form geladen wurde
      setTimeout(() => this.initCorrectSubForm(value));
    });
  }

  ngAfterViewInit(): void {
    if (this.dialogData.auftragToEdit) {
      this.setDto(this.dialogData.auftragToEdit);
    }
  }

  private setDto(auftragDto: AuftragDTO): void {
    this.auftragDto = auftragDto;
    this.formGroup.controls.abgangsort.setValue(auftragDto.abgangsort);
    this.formGroup.controls.anlagen.setValue(auftragDto.anlagen);
    if (auftragDto.befehlendeStelleId) {
      this.befehlsstelleValues$.pipe(take(1)).subscribe((befehlsstellen) => {
        this.formGroup.controls.befehlendeStelle.setValue(
          befehlsstellen.find((befehlsstelle) => befehlsstelle.id === auftragDto.befehlendeStelleId)
        );
      });
    }
    this.formGroup.controls.befehlTyp.setValue(auftragDto.befehlTyp);
    this.formGroup.controls.name.setValue(auftragDto.name);
    this.formGroup.controls.erledigt.setValue(auftragDto.erledigt);
    if (auftragDto.personId) {
      this.personValues$.pipe(take(1)).subscribe((personen) => {
        this.formGroup.controls.person.setValue(personen.find((person) => person.id === auftragDto.personId));
      });
    }
    this.formGroup.controls.verteiler.setValue(auftragDto.verteiler);
    if (auftragDto.zeitpunkt) {
      this.formGroup.controls.zeitpunkt.setValue(new Date(Date.parse(auftragDto.zeitpunkt)));
    }

    this.initCorrectSubForm(this.auftragDto.befehlTyp);
  }

  private initCorrectSubForm(befehlTyp: BefehlTyp) {
    if (!this.auftragDto) {
      console.warn('Kein AuftragDto verfügbar');
      return;
    }

    switch (befehlTyp) {
      case BefehlTyp.Befehl:
        this.befehlForm?.setDto(this.auftragDto as BefehlDTO);
        break;
      case BefehlTyp.Langzeitbefehl:
        this.langzeitbefehlForm?.setDto(this.auftragDto as LangzeitbefehlDTO);
        break;
      case BefehlTyp.Marschbefehl:
        this.marschbefehlForm?.setDto(this.auftragDto as MarschbefehlDTO);
        break;
      default:
        console.warn('Fehler beim Laden des Befehltyps');
    }
  }

  private toDTO(): AuftragDTO | undefined {
    let auftragDto;
    switch (this.formGroup.controls.befehlTyp.value) {
      case BefehlTyp.Befehl:
        auftragDto = this.befehlForm?.getDto();
        break;
      case BefehlTyp.Langzeitbefehl:
        auftragDto = this.langzeitbefehlForm?.getDto();
        break;
      case BefehlTyp.Marschbefehl:
        auftragDto = this.marschbefehlForm?.getDto();
        break;
    }

    if (!auftragDto) {
      console.warn('Subtyp des Auftrags konnte nicht gespeichert werden');
      return;
    }

    auftragDto.abgangsort = this.formGroup.controls.abgangsort.value?.trim();
    auftragDto.anlagen = this.formGroup.controls.anlagen.value?.trim();
    auftragDto.befehlendeStelleId = this.formGroup.controls.befehlendeStelle.value?.id;
    auftragDto.befehlTyp = this.formGroup.controls.befehlTyp.value;
    auftragDto.name = this.formGroup.controls.name.value.trim();
    auftragDto.erledigt = this.formGroup.controls.erledigt.value;
    auftragDto.personId = this.formGroup.controls.person.value?.id;
    auftragDto.verteiler = this.formGroup.controls.verteiler.value?.trim();
    auftragDto.zeitpunkt = this.formGroup.controls.zeitpunkt.value?.toISOString();
    return auftragDto;
  }

  validate(): boolean {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return false;
    }

    const currentBefehltyp = this.formGroup.controls.befehlTyp.value;
    if (
      (currentBefehltyp == BefehlTyp.Befehl && !this.befehlForm?.validate()) ||
      (currentBefehltyp == BefehlTyp.Langzeitbefehl && !this.langzeitbefehlForm?.validate()) ||
      (currentBefehltyp == BefehlTyp.Marschbefehl && !this.marschbefehlForm?.validate())
    ) {
      return false;
    }

    return true;
  }

  save(): void {
    if (!this.validate() || this.isSaving) {
      return;
    }

    this.isSaving = true;
    const auftragDto = this.toDTO();
    if (!auftragDto) {
      this.isSaving = false;
      this.errorService.showErrorMessage('Auftrag konnte nicht gespeichert werden.');
      return;
    }

    this.dialogRef.close(auftragDto);
  }
}
