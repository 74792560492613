<mat-toolbar>
  <h2>{{ headerText }}</h2>
</mat-toolbar>

<mat-progress-bar
  *ngIf="isLoadingFahrzeuge || isLoadingPersonen || isLoadingTaktischeFormationen || isLoadingBefehlsstellen"
  mode="indeterminate"
  color="accent"
/>

<div class="content">
  <mat-card class="available">
    <mat-toolbar>
      <h3>Verfügbar</h3>
    </mat-toolbar>

    <div class="items">
      <!-- Taktische Formationen -->
      <app-taktische-zeichen-item
        *ngFor="let taktischeFormation of availableTaktischeFormationen"
        [item]="taktischeFormation"
        [selected]="isSelected(taktischeFormation)"
        (click)="clickAvailableFormation(taktischeFormation)"
      />

      <!-- Fahrzeuge -->
      <app-taktische-zeichen-item
        *ngFor="let fahrzeug of availableFahrzeuge"
        [item]="fahrzeug"
        [selected]="isSelected(fahrzeug)"
        (click)="clickAvailableFahrzeug(fahrzeug)"
      />

      <!-- Personen -->
      <app-taktische-zeichen-item
        *ngFor="let person of availablePersonen"
        [item]="person"
        [selected]="isSelected(person)"
        (click)="clickAvailablePerson(person)"
      />

      <!-- Befehlsstellen -->
      <app-taktische-zeichen-item
        *ngFor="let befehlsstelle of availableBefehlsstellen"
        [item]="befehlsstelle"
        [selected]="isSelected(befehlsstelle)"
        (click)="toggleBefehlsstelle(befehlsstelle)"
      />
    </div>
  </mat-card>

  <mat-card class="selected">
    <mat-toolbar>
      <h3>Ausgewählt</h3>
    </mat-toolbar>
    <div class="items">
      <!-- Taktische Formationen -->
      <app-taktische-zeichen-item
        *ngFor="let taktischeFormation of selectedTaktischeFormationen; let index = index"
        [item]="taktischeFormation"
        (click)="clickSelectedFormation(taktischeFormation, index)"
      />

      <!-- Fahrzeuge -->
      <app-taktische-zeichen-item
        *ngFor="let fahrzeug of selectedFahrzeuge; let index = index"
        [item]="fahrzeug"
        (click)="clickSelectedFahrzeug(fahrzeug, index)"
      />

      <!-- Personen -->
      <app-taktische-zeichen-item
        *ngFor="let person of selectedPersonen; let index = index"
        [item]="person"
        (click)="clickSelectedPerson(person, index)"
      />

      <!-- Befehlsstellen -->
      <app-taktische-zeichen-item
        *ngFor="let befehlsstelle of selectedBefehlsstellen; let index = index"
        [item]="befehlsstelle"
        (click)="removeBefehlsstelleFromSelectionAtIndex(befehlsstelle, index)"
      />
    </div>
  </mat-card>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="save()" [disabled]="isSaving">Speichern</button>
</mat-dialog-actions>
