<mat-toolbar>
  <h2>Angezeigte Führungsebenen wählen</h2>
</mat-toolbar>

<mat-dialog-content>
  <p>Ausgewählte Führungsebenen (max. 6) werden links und rechts neben der Karte dargestellt.</p>
  <table mat-table [dataSource]="childFuehrungsebenen" class="mat-elevation-z1">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef [ngClass]="{ 'max-select': selectedFuehrungsebeneIds.length === 6 }">
        {{ selectedFuehrungsebeneIds.length }}/{{ childFuehrungsebenen.length }}
      </th>
      <td mat-cell *matCellDef="let fuehrungsebeneDTO">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="toggleSelection(fuehrungsebeneDTO.id)"
          [checked]="selectedFuehrungsebeneIds.includes(fuehrungsebeneDTO.id)"
          [disabled]="
            !selectedFuehrungsebeneIds.includes(fuehrungsebeneDTO.id) && selectedFuehrungsebeneIds.length === 6
          "
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let fuehrungsebeneDTO">{{ fuehrungsebeneDTO.name }}</td>
    </ng-container>

    <ng-container matColumnDef="typ">
      <th mat-header-cell *matHeaderCellDef>Typ</th>
      <td mat-cell *matCellDef="let fuehrungsebeneDTO">{{ getFuehrungsebenentypText(fuehrungsebeneDTO.typ) }}</td>
    </ng-container>

    <ng-container matColumnDef="leiterName">
      <th mat-header-cell *matHeaderCellDef>Leiter</th>
      <td mat-cell *matCellDef="let fuehrungsebeneDTO">{{ fuehrungsebeneDTO.leiterName }}</td>
    </ng-container>

    <ng-container matColumnDef="adresse">
      <th mat-header-cell *matHeaderCellDef>Adresse</th>
      <td mat-cell *matCellDef="let fuehrungsebeneDTO">{{ fuehrungsebeneDTO.adresse }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <p *ngIf="showError" class="error" mat-error>Maximal 6 Führungsebenen dürfen ausgewählt werden</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="save()" [disabled]="isSaving">Speichern</button>
</mat-dialog-actions>
