import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { ErrorResponse, GebietResourceService } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { gebietActions } from './gebiet.actions';

@Injectable()
export class GebietEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Gebiete anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return gebietActions.getGebiete({ lageId: props.currentLage.id });
        } else {
          return gebietActions.resetStore();
        }
      })
    )
  );

  /**
   * Alle Gebiete einer Lage laden
   */
  getGebiete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gebietActions.getGebiete),
      mergeMap((props) =>
        this.gebieteResourceService.getGebieteByLageId(props.lageId).pipe(
          map((gebiete) => gebietActions.getGebieteSuccess({ gebietDTOs: gebiete, lageId: props.lageId })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(gebietActions.getGebieteFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Neues Gebiet anlegen
   */
  createGebiet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gebietActions.createGebiet),
      mergeMap((props) =>
        this.gebieteResourceService.createGebiet(props.lageId, props.gebietDTO).pipe(
          map((createdGebietDTO) => gebietActions.createGebietSuccess({ createdGebietDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(gebietActions.createGebietFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Existierendes Gebiet editieren
   */
  patchGebiet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gebietActions.patchGebiet),
      mergeMap((props) => {
        if (!props.gebietDTO.id) {
          return throwError(() => new Error('Gebiet muss eine Id besitzen.'));
        }
        return this.gebieteResourceService.patchGebiet(props.gebietDTO.id, props.lageId, props.gebietDTO).pipe(
          map((patchedGebietDTO) => gebietActions.patchGebietSuccess({ patchedGebietDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(gebietActions.patchGebietFailure({ errorResponse }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private gebieteResourceService: GebietResourceService) {}
}
