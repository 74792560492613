<mat-toolbar>
  <h2>Zeichen aus Bibliothek</h2>
</mat-toolbar>

<div class="content">
  <!-- SelectionMode aktiv, wodurch Editierung  -->
  <app-bibliothek-base [selectionMode]="true" />
  <mat-card class="selection-area">
    <ng-container *ngIf="selectionService.selectedTaktischeZeichen$ | async as selected">
      <mat-toolbar>
        <h3>Ausgewählte Einheiten</h3>
      </mat-toolbar>
      <div class="selection-row">
        <app-taktische-zeichen-item
          *ngFor="let zeichen of selected; let index = index"
          [item]="zeichen"
          (click)="removeAt(index)"
        />
        <h3 class="keine-daten-text" *ngIf="!selected.length">Keine Taktischen Zeichen selektiert</h3>
      </div>
    </ng-container>
  </mat-card>
</div>

<!-- Buttons zum Speichern/Abbrechen -->
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="submitSelection()" [disabled]="isSaving">
    Einheiten hinzufügen
  </button>
</mat-dialog-actions>
