<mat-toolbar>
  <h2>Hierarchie-Editor</h2>
</mat-toolbar>

<div class="content">
  <mat-card class="left">
    <app-taktische-formation-tree
      [rootTaktischeFormationen$]="rootTaktischeFormationen$"
      [selectedTaktischeFormationNested$]="selectedTaktischeFormationNested$"
      class="left-content"
    />
  </mat-card>

  <div class="right">
    <mat-card class="right-header">
      <!-- Taktisches Zeichen selbst -->
      <div class="header-item">
        <h3>Taktische Formation</h3>
        <img [src]="selectedTaktischeFormationNested$.value.dataUrl" height="50px" />
        <h4>{{ selectedTaktischeFormationNested$.value.anzeigename }}</h4>
      </div>

      <!-- Einheitsführer -->
      <div class="header-item" (click)="selectEinheitsfuehrer()">
        <h3>Leiter</h3>
        <ng-container *ngIf="einheitsfuehrer$ | async as einheitsfuehrer; else tzEmpty">
          <img [src]="einheitsfuehrer?.dataUrl" height="50px" />
          <h4>{{ einheitsfuehrer?.anzeigename }}</h4>
        </ng-container>
      </div>

      <!-- Führungseinheit -->
      <div class="header-item" (click)="selectFuehrungseinheit()">
        <h3>Führungseinheit</h3>
        <ng-container *ngIf="fuehrungseinheit$ | async as fuehrungseinheit; else tzEmpty">
          <img [src]="fuehrungseinheit?.dataUrl" height="50px" />
          <h4>{{ fuehrungseinheit?.anzeigename }}</h4>
        </ng-container>
      </div>

      <!-- Befehlsstelle -->
      <div class="header-item" (click)="selectBefehlsstelle()">
        <h3>Befehlsstelle</h3>
        <ng-container *ngIf="befehlsstelle$ | async as befehlsstelle; else tzEmpty">
          <img [src]="befehlsstelle?.dataUrl" height="50px" />
          <h4>{{ befehlsstelle?.anzeigename }}</h4>
        </ng-container>
      </div>

      <!-- Alternativer Button, wenn Leiter, Führungseinheit oder Befehlsstelle nicht gesetzt sind -->
      <ng-template #tzEmpty>
        <mat-icon mat-ripple mat-button fontSet="material-icons-outlined">add_box</mat-icon>
      </ng-template>
    </mat-card>

    <app-taktische-formation-container
      [selectedTaktischeFormationNested$]="selectedTaktischeFormationNested$"
      (taktischeFormationPatched)="reloadRoot()"
      class="right-content"
    />
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button color="accent" mat-dialog-close>Schließen</button>
</mat-dialog-actions>
