import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DialogAction, DialogService } from '@product/ise-dialog-lib';
import { ErrorService } from '@product/ise-error-lib';
import { BehaviorSubject, EMPTY, Observable, catchError, switchMap, take, throwError } from 'rxjs';
import {
  EinsatzfilterDTO,
  EinsatzfilterMoveDTO,
  EinsatzfilterResourceService,
  ErrorResponse,
  FuehrungsebeneResourceService,
  PersonenuebersichtDTO,
} from 'src/app/api/build/openapi';
import { FuehrungsebeneDTO } from 'src/app/api/build/openapi/model/fuehrungsebeneDTO';
import { FuehrungsebeneNestedDTO } from 'src/app/api/build/openapi/model/fuehrungsebeneNestedDTO';
import { Fuehrungsebenentyp } from 'src/app/api/build/openapi/model/fuehrungsebenentyp';
import { LageDTO } from 'src/app/api/build/openapi/model/lageDTO';
import { TaktischesZeichenStatus } from 'src/app/api/build/openapi/model/taktischesZeichenStatus';
import { LogService } from 'src/app/shared/services/log.service';
import { currentLageSelector } from '../lagen/+state/lage.selectors';
import { fuehrungsebeneActions } from './+state/fuehrungsebene.actions';
import { allFuehrungsebenenSelector, currentFuehrungsebeneSelector } from './+state/fuehrungsebene.selectors';
import { FuehrungsebeneDetailsDialogComponent } from './fuehrungsebene-details-dialog/fuehrungsebene-details-dialog.component';
import {
  FuehrungsebeneTreeNestedDialogInputData,
  FuehrungsebeneTreeNestedDialogOutputData,
  FuehrungsebeneTreeSelectNestedDialogComponent,
} from './fuehrungsebene-tree-select-nested-dialog/fuehrungsebene-tree-select-nested-dialog.component';

export interface FuehrungsebenentypSettings {
  // Kurzform des Typs. Wird im Führungsebene-Header ganz oben angezeigt
  shortname: string;
  // Langform des Typs. Wird in Texten und Überschriften, z.B. Führungsebene-Dialog angezeigt
  longname: string;
  // Untertitel der Kurzform im Führungsebene-Header. Wird sehr klein unter der Kurzform angezeigt
  subtitle?: string;
  // Mittlerer Text im Führungsebene-Header
  middle?: string;
  // Farbe der ersten Spalte im Führungsebene-Header
  color: string;
  icon: string;
}

@Injectable({
  providedIn: 'root',
})
export class FuehrungsebeneService {
  /**
   * Mapping von Fuehrungsebenentyp zu verschiedenen Texten und Styles.
   * Wird benötigt, damit Führungsebene-Header einheitlich mit Überschriften und Hintergrundfarben versehen werden können.
   */
  public readonly fuehrungsebenentypMapping = new Map<Fuehrungsebenentyp, FuehrungsebenentypSettings>([
    [
      Fuehrungsebenentyp.Bereitstellungsraum,
      {
        shortname: 'BR',
        longname: 'Bereitstellungsraum',
        subtitle: 'Bereitstellungsraum',
        middle: 'Einsatzleitung',
        color: '#E2E2E3',
        icon: 'assets/svg/tz/bereitstellungsraum.svg',
      },
    ],
    [
      Fuehrungsebenentyp.Einsatzabschnitt,
      { shortname: 'EA', longname: 'Einsatzabschnitt', color: '#E2E2E3', icon: 'assets/svg/tz/einsatzabschnitt.svg' },
    ],
    [
      Fuehrungsebenentyp.Einsatzraum,
      {
        shortname: 'ER',
        longname: 'Einsatzraum',
        middle: 'Einsatzraum',
        color: '#E2E2E3',
        icon: 'assets/svg/tz/einsatzraum.svg',
      },
    ],
    [
      Fuehrungsebenentyp.Einsatzstelle,
      {
        shortname: 'ESt',
        longname: 'Einsatzstelle',
        middle: 'Einsatzstelle',
        color: '#E2E2E3',
        icon: 'assets/svg/tz/einsatzstelle.svg',
      },
    ],
    [
      Fuehrungsebenentyp.Grundschutz,
      {
        shortname: 'GS',
        longname: 'Grundschutz',
        middle: 'Grundschutz',
        color: '#7CAACA',
        icon: 'assets/svg/tz/grundschutz.svg',
      },
    ],
    [
      Fuehrungsebenentyp.LogistikEinsatzabschnitt,
      {
        shortname: 'Log',
        longname: 'Logistik Einsatzabschnitt',
        color: '#f2d74e',
        icon: 'assets/svg/tz/logistik.svg',
      },
    ],
    [
      Fuehrungsebenentyp.LogistikEinsatzstelle,
      {
        shortname: 'Log',
        longname: 'Logistik Einsatzstelle',
        color: '#f2d74e',
        icon: 'assets/svg/tz/logistik.svg',
      },
    ],
    [
      Fuehrungsebenentyp.Lage,
      { shortname: 'L', longname: 'Lage', middle: 'Lage', color: '#E2E2E3', icon: 'assets/svg/tz/lage.svg' },
    ],
    [
      Fuehrungsebenentyp.Schadengebiet,
      {
        shortname: 'SG',
        longname: 'Schadengebiet',
        middle: 'Schadengebiet',
        color: '#E2E2E3',
        icon: 'assets/svg/tz/schadengebiet.svg',
      },
    ],
    [
      Fuehrungsebenentyp.Unterabschnitt,
      { shortname: 'UA', longname: 'Unterabschnitt', color: '#E2E2E3', icon: 'assets/svg/tz/unterabschnitt.svg' },
    ],
    [
      Fuehrungsebenentyp.AdministrativOrganisatorisch,
      {
        shortname: 'AO',
        longname: 'Administrativ-Organisatorische Komponente',
        color: '#E2E2E3',
        icon: 'assets/svg/tz/ao.svg',
      },
    ],
    [
      Fuehrungsebenentyp.OperativTaktisch,
      { shortname: 'OT', longname: 'Operativ-Taktische Komponente', color: '#E2E2E3', icon: 'assets/svg/tz/ot.svg' },
    ],
    [
      Fuehrungsebenentyp.PolitischGesamtverantwortlicher,
      { shortname: 'PG', longname: 'Politisch-Gesamtverantwortlicher', color: '#E2E2E3', icon: 'assets/svg/tz/pg.svg' },
    ],
    [
      Fuehrungsebenentyp.Leitstelle,
      { shortname: 'LsT', longname: 'Leitstelle', color: '#E2E2E3', icon: 'assets/svg/tz/leitstelle.svg' },
    ],
    [
      Fuehrungsebenentyp.Oertlich,
      { shortname: 'ÖE', longname: 'Örtliche Einheiten', color: '#E2E2E3', icon: 'assets/svg/tz/oertlich.svg' },
    ],
    [
      Fuehrungsebenentyp.LogistikEinsatzabschnittChild,
      { shortname: 'UA', longname: 'Unterabschnitt', color: '#f2d74e', icon: 'assets/svg/tz/unterabschnitt.svg' },
    ],
    [
      Fuehrungsebenentyp.LogistikEinsatzstelleChild,
      { shortname: 'EA', longname: 'Einsatzabschnitt', color: '#f2d74e', icon: 'assets/svg/tz/einsatzabschnitt.svg' },
    ],
  ]);

  /**
   * Mapping von Fuehrungsebenentyp auf TaktischesZeichenStatus.
   * Wird benötigt, damit TaktischeZeichen im TaktischeZeichenContainer anhand ihres Status gefiltert werden können.
   */
  readonly fuehrungsebenentypStatusMapping = new Map<Fuehrungsebenentyp, TaktischesZeichenStatus>([
    [Fuehrungsebenentyp.Bereitstellungsraum, TaktischesZeichenStatus.Bereitstellungsraum],
    [Fuehrungsebenentyp.Einsatzabschnitt, TaktischesZeichenStatus.Einsatzabschnitt],
    [Fuehrungsebenentyp.Grundschutz, TaktischesZeichenStatus.Grundschutz],
    [Fuehrungsebenentyp.Einsatzabschnitt, TaktischesZeichenStatus.Einsatzabschnitt],
    [Fuehrungsebenentyp.Unterabschnitt, TaktischesZeichenStatus.Unterabschnitt],
    [Fuehrungsebenentyp.Einsatzstelle, TaktischesZeichenStatus.Einsatzstelle],
    [Fuehrungsebenentyp.Einsatzraum, TaktischesZeichenStatus.Einsatzraum],
    [Fuehrungsebenentyp.Oertlich, TaktischesZeichenStatus.Oertlich],
    [Fuehrungsebenentyp.Lage, TaktischesZeichenStatus.Lage],
    [Fuehrungsebenentyp.Schadengebiet, TaktischesZeichenStatus.Schadengebiet],
    [Fuehrungsebenentyp.LogistikEinsatzstelle, TaktischesZeichenStatus.LogistikEinsatzstelle],
    [Fuehrungsebenentyp.LogistikEinsatzstelleChild, TaktischesZeichenStatus.LogistikEinsatzstelleChild],
    [Fuehrungsebenentyp.LogistikEinsatzabschnitt, TaktischesZeichenStatus.LogistikEinsatzabschnitt],
    [Fuehrungsebenentyp.LogistikEinsatzabschnittChild, TaktischesZeichenStatus.LogistikEinsatzabschnittChild],
  ]);

  readonly mainFuehrungsebenentypen = [
    Fuehrungsebenentyp.Schadengebiet,
    Fuehrungsebenentyp.Einsatzraum,
    Fuehrungsebenentyp.Einsatzstelle,
    Fuehrungsebenentyp.Einsatzabschnitt,
    Fuehrungsebenentyp.Unterabschnitt,
    Fuehrungsebenentyp.LogistikEinsatzstelle,
    Fuehrungsebenentyp.LogistikEinsatzstelleChild,
    Fuehrungsebenentyp.LogistikEinsatzabschnitt,
    Fuehrungsebenentyp.LogistikEinsatzabschnittChild,
  ];

  readonly specialFuehrungsebenentypen = [
    Fuehrungsebenentyp.Grundschutz,
    Fuehrungsebenentyp.Bereitstellungsraum,
    Fuehrungsebenentyp.Oertlich,
    Fuehrungsebenentyp.LogistikEinsatzstelle,
    Fuehrungsebenentyp.LogistikEinsatzabschnitt,
  ];

  readonly movableFuehrungsebenentypen = [
    Fuehrungsebenentyp.Einsatzraum,
    Fuehrungsebenentyp.Einsatzstelle,
    Fuehrungsebenentyp.Einsatzabschnitt,
    Fuehrungsebenentyp.Unterabschnitt,
    Fuehrungsebenentyp.Grundschutz,
    Fuehrungsebenentyp.Bereitstellungsraum,
    Fuehrungsebenentyp.Oertlich,
    Fuehrungsebenentyp.LogistikEinsatzstelle,
    Fuehrungsebenentyp.LogistikEinsatzabschnitt,
  ];

  readonly logistikFuehrungsebenentypen = [
    Fuehrungsebenentyp.LogistikEinsatzstelle,
    Fuehrungsebenentyp.LogistikEinsatzabschnitt,
  ];

  readonly tzFuehrungsebenentypen = [
    ...this.mainFuehrungsebenentypen,
    Fuehrungsebenentyp.Bereitstellungsraum,
    Fuehrungsebenentyp.Grundschutz,
    Fuehrungsebenentyp.Oertlich,
  ];

  readonly allowedFuehrungsebeneChildren = new Map<Fuehrungsebenentyp, Map<Fuehrungsebenentyp, number>>([
    [
      Fuehrungsebenentyp.Lage,
      new Map([
        [Fuehrungsebenentyp.Schadengebiet, 1],
        [Fuehrungsebenentyp.PolitischGesamtverantwortlicher, 1],
        [Fuehrungsebenentyp.AdministrativOrganisatorisch, 1],
        [Fuehrungsebenentyp.OperativTaktisch, 1],
        [Fuehrungsebenentyp.Leitstelle, 1],
      ]),
    ],
    [
      Fuehrungsebenentyp.Schadengebiet,
      new Map([
        [Fuehrungsebenentyp.Einsatzraum, 1000],
        [Fuehrungsebenentyp.Einsatzstelle, 1000],
        [Fuehrungsebenentyp.LogistikEinsatzstelle, 1],
        [Fuehrungsebenentyp.Bereitstellungsraum, 5],
        [Fuehrungsebenentyp.Grundschutz, 5],
        [Fuehrungsebenentyp.Oertlich, 5],
      ]),
    ],
    [
      Fuehrungsebenentyp.Einsatzraum,
      new Map([
        [Fuehrungsebenentyp.Einsatzstelle, 1000],
        [Fuehrungsebenentyp.LogistikEinsatzstelle, 1],
        [Fuehrungsebenentyp.Bereitstellungsraum, 5],
        [Fuehrungsebenentyp.Grundschutz, 5],
        [Fuehrungsebenentyp.Oertlich, 5],
      ]),
    ],
    [
      Fuehrungsebenentyp.Einsatzstelle,
      new Map([
        [Fuehrungsebenentyp.Einsatzabschnitt, 6],
        [Fuehrungsebenentyp.LogistikEinsatzabschnitt, 1],
        [Fuehrungsebenentyp.Bereitstellungsraum, 5],
        [Fuehrungsebenentyp.Grundschutz, 5],
        [Fuehrungsebenentyp.Oertlich, 5],
      ]),
    ],
    [
      Fuehrungsebenentyp.Einsatzabschnitt,
      new Map([
        [Fuehrungsebenentyp.Unterabschnitt, 6],
        [Fuehrungsebenentyp.Bereitstellungsraum, 5],
      ]),
    ],
    [Fuehrungsebenentyp.Unterabschnitt, new Map([[Fuehrungsebenentyp.Bereitstellungsraum, 5]])],
    [
      Fuehrungsebenentyp.LogistikEinsatzstelle,
      new Map([
        [Fuehrungsebenentyp.LogistikEinsatzstelleChild, 5],
        [Fuehrungsebenentyp.Bereitstellungsraum, 5],
      ]),
    ],
    [
      Fuehrungsebenentyp.LogistikEinsatzabschnitt,
      new Map([
        [Fuehrungsebenentyp.LogistikEinsatzabschnittChild, 5],
        [Fuehrungsebenentyp.Bereitstellungsraum, 5],
      ]),
    ],
  ]);

  childFuehrungsebeneHeader$ = new BehaviorSubject<string>('');
  fuehrungsebeneBreadcrumbs$ = new BehaviorSubject<FuehrungsebeneDTO[]>([]);

  private currentLage: LageDTO | null = null;
  private currentFuehrungsebene: FuehrungsebeneDTO | null = null;
  private allFuehrungsebenen: FuehrungsebeneDTO[] = [];

  // BroadcastChannel, über den die aktuell selektierte Führungsebene an andere Fenster verteilt wird.
  private bc = new BroadcastChannel('MultiWindow-CurrentFuehrungsebene');

  private actions$ = inject(Actions);
  private dialogService = inject(DialogService);
  private dialog = inject(MatDialog);
  private logService = inject(LogService);
  private errorService = inject(ErrorService);
  private fuehrungsebeneResourceService = inject(FuehrungsebeneResourceService);
  private einsatzfilterResourceService = inject(EinsatzfilterResourceService);
  private store = inject(Store);

  constructor() {
    // Für Multi-Fenster
    // Immer auf das Setzen der aktuellen Führungsebene hören; aktuelle Führungsebene aus Liste raussuchen und im Anschluss setzen.
    // Der BroadcastChannel sendet nie an Auslöser zurück; keine Filterun notwenidg.
    this.bc.onmessage = (event) => {
      const selectedFuehrungsebene = this.allFuehrungsebenen.find((a) => a.id === event.data);
      if (selectedFuehrungsebene) {
        this.store.dispatch(
          fuehrungsebeneActions.setCurrentFuehrungsebene({ currentFuehrungsebene: selectedFuehrungsebene })
        );
      }
    };

    this.store
      .select(currentLageSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((currentLage: LageDTO | null) => {
        this.currentLage = currentLage;
      });

    this.store
      .select(allFuehrungsebenenSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((fuehrungsebenen) => {
        this.allFuehrungsebenen = fuehrungsebenen;
        this.generateFuehrungsebeneBreadcrumbs();
      });

    this.store
      .select(currentFuehrungsebeneSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((fuehrungsebene) => {
        this.currentFuehrungsebene = fuehrungsebene;
        this.childFuehrungsebeneHeader$.next(this.generateChildFuehrungsebeneHeader(fuehrungsebene));
        this.fuehrungsebeneBreadcrumbs$.next(this.generateFuehrungsebeneBreadcrumbs());

        // selektierte Führungsebene an andere Fenster senden
        if (fuehrungsebene?.id) {
          this.bc.postMessage(fuehrungsebene.id);
        }
      });
  }

  getFuehrungsebeneIcon(fuehrungsebeneDTO: FuehrungsebeneDTO): string {
    // Festgelegtes Symbol hat höchste Priorität
    if (fuehrungsebeneDTO.symbol) {
      return fuehrungsebeneDTO.symbol;
    }

    // Sonst Standard-Icon, passend zum Typ zurückgeben
    return this.fuehrungsebenentypMapping.get(fuehrungsebeneDTO.typ)?.icon || '';
  }

  /**
   * Erzeugt den Titel für Child-Führungsebenen eines bestimmten Fuehrungsebenentyps.
   * Sollen beispielsweise alle Child-Führungsebenen zu einer ausgewählten "Einsatzstelle" aufgelistet werden,
   * erhält die Liste den Titel "Einsatzabschnitte", da sich unter einer Einsatzstelle nur Einsatzabschnitte befinden können.
   */
  generateChildFuehrungsebeneHeader(currentFuehrungsebene: FuehrungsebeneDTO | null) {
    if (!currentFuehrungsebene) {
      return '';
    }

    switch (currentFuehrungsebene.typ) {
      case Fuehrungsebenentyp.Schadengebiet:
        return 'Einsatzräume';
      case Fuehrungsebenentyp.Einsatzraum:
        return 'Einsatzstellen';
      case Fuehrungsebenentyp.Einsatzstelle:
      case Fuehrungsebenentyp.LogistikEinsatzstelle:
        return 'Einsatzabschnitte';
      case Fuehrungsebenentyp.Einsatzabschnitt:
      case Fuehrungsebenentyp.LogistikEinsatzabschnitt:
        return 'Unterabschnitte';
      case Fuehrungsebenentyp.Unterabschnitt:
      case Fuehrungsebenentyp.LogistikEinsatzabschnittChild:
      case Fuehrungsebenentyp.LogistikEinsatzstelleChild:
        return '';
      default:
        return 'Invalid Current Führungsebene: ' + currentFuehrungsebene.typ;
    }
  }

  /**
   * Generiert eine Liste von Parent-Führungsebenen, ausgehend von der Current-Führungsebene.
   */
  generateFuehrungsebeneBreadcrumbs(): FuehrungsebeneDTO[] {
    if (!this.currentFuehrungsebene) {
      return [];
    }

    let breadcrumbFuehrungsebene: FuehrungsebeneDTO | undefined = this.currentFuehrungsebene;
    const result = [this.currentFuehrungsebene];
    while (breadcrumbFuehrungsebene && breadcrumbFuehrungsebene.parentFuehrungsebeneId) {
      const parentFuehrungsebene: FuehrungsebeneDTO | undefined = this.allFuehrungsebenen.find(
        (fuehrungsebene) => fuehrungsebene.id == breadcrumbFuehrungsebene?.parentFuehrungsebeneId
      );
      if (parentFuehrungsebene) {
        result.push(parentFuehrungsebene);
      }
      breadcrumbFuehrungsebene = parentFuehrungsebene;
    }

    return result.reverse();
  }

  /**
   * Liefert den passen Child-Fuehrungsebenentyp basierend auf dem übergebenen Parent-Fuehrungsebenentyp.
   */
  getSuggestedChildType(fuehrungsebenentyp: Fuehrungsebenentyp) {
    switch (fuehrungsebenentyp) {
      case Fuehrungsebenentyp.Schadengebiet:
        return Fuehrungsebenentyp.Einsatzraum;
      case Fuehrungsebenentyp.Einsatzraum:
        return Fuehrungsebenentyp.Einsatzstelle;
      case Fuehrungsebenentyp.Einsatzstelle:
        return Fuehrungsebenentyp.Einsatzabschnitt;
      case Fuehrungsebenentyp.LogistikEinsatzstelle:
        return Fuehrungsebenentyp.LogistikEinsatzstelleChild;
      case Fuehrungsebenentyp.LogistikEinsatzabschnitt:
        return Fuehrungsebenentyp.LogistikEinsatzabschnittChild;
      case Fuehrungsebenentyp.Einsatzabschnitt:
        return Fuehrungsebenentyp.Unterabschnitt;
    }
    return undefined;
  }

  /**
   * Erzeugt eine neue Führungsebene für die aktuelle Lage und einer aktuellen Führungsebene als Parent.
   * Der Typ der neuen Führungsebene wird passend zum Parent gewählt.
   */
  prepareNewChildFuehrungsebene(): FuehrungsebeneDTO | null {
    if (!this.currentLage) {
      this.logService.error('Neue Child-Führungsebene kann nicht vorbereitet werden: Keine aktuelle Lage vorhanden');
      return null;
    }

    if (!this.currentFuehrungsebene || !this.currentFuehrungsebene.typ) {
      this.logService.error(
        'Neue Child-Führungsebene kann nicht vorbereitet werden: Keine aktuelle Führungsebene mit validem Typ vorhanden'
      );
      return null;
    }

    const childFuehrungsebenentyp = this.getSuggestedChildType(this.currentFuehrungsebene.typ);
    if (!childFuehrungsebenentyp) {
      this.logService.error(
        'Neue Child-Führungsebene kann nicht vorbereitet werden: Kein valider Fuehrungsebenentyp für Child-Führungsebene'
      );
      return null;
    }

    return {
      name: '',
      lageId: this.currentLage.id,
      parentFuehrungsebeneId: this.currentFuehrungsebene.id,
      typ: childFuehrungsebenentyp,
      startedOn: new Date().toISOString(),
    };
  }

  prepareNewFuehrungsebene(fuehrungsebenentyp: Fuehrungsebenentyp, parentId?: string): FuehrungsebeneDTO | null {
    if (!this.currentLage) {
      this.logService.error('No current lage');
      return null;
    }

    const newFuehrungsebene: FuehrungsebeneDTO = {
      lageId: this.currentLage.id,
      parentFuehrungsebeneId: parentId,
      name: this.fuehrungsebenentypMapping.get(fuehrungsebenentyp)?.longname || '',
      typ: fuehrungsebenentyp,
      startedOn: new Date().toISOString(),
    };

    if (
      fuehrungsebenentyp === Fuehrungsebenentyp.PolitischGesamtverantwortlicher ||
      fuehrungsebenentyp === Fuehrungsebenentyp.AdministrativOrganisatorisch ||
      fuehrungsebenentyp === Fuehrungsebenentyp.OperativTaktisch ||
      fuehrungsebenentyp === Fuehrungsebenentyp.Leitstelle
    ) {
      newFuehrungsebene.parentFuehrungsebeneId = this.currentLage.id;

      switch (fuehrungsebenentyp) {
        case Fuehrungsebenentyp.PolitischGesamtverantwortlicher:
          newFuehrungsebene.name = 'Regierungspräsident / Landrat / Oberbürgermeister';
          break;
        case Fuehrungsebenentyp.AdministrativOrganisatorisch:
          newFuehrungsebene.name = 'Krisenstab';
          break;
        case Fuehrungsebenentyp.OperativTaktisch:
          newFuehrungsebene.name = 'Einsatzleitung';
          break;
        default:
          newFuehrungsebene.name =
            this.fuehrungsebenentypMapping.get(fuehrungsebenentyp)?.longname || 'Kein Namensvorschlag verfügbar';
      }
      return newFuehrungsebene;
    }

    if (!this.currentFuehrungsebene) {
      this.logService.error('No current fuehrungsebene');
      return null;
    }

    newFuehrungsebene.parentFuehrungsebeneId = this.currentFuehrungsebene.id;
    return newFuehrungsebene;
  }

  /**
   * Erzeugt je nach currentFuehrungsebene einen neuen Logistik-Abschnitt
   * - Ist currentFuehrungsebene ein Schadengebiet oder Einsatzraum, wird eine Logistik-Einsatzstelle erzeugt
   * - Ist currentFuehrungsebene ein Einsatzraum, wird ein Logistik-Einsatzabschnitt erzeugt
   * Andernfalls wird null returnt
   */
  prepareNewLogistikFuehrungsebene() {
    if (!this.currentFuehrungsebene) {
      return null;
    }
    if (
      this.currentFuehrungsebene.typ == Fuehrungsebenentyp.Schadengebiet ||
      this.currentFuehrungsebene.typ == Fuehrungsebenentyp.Einsatzraum
    ) {
      return this.prepareNewFuehrungsebene(Fuehrungsebenentyp.LogistikEinsatzstelle, this.currentFuehrungsebene.id);
    }

    if (this.currentFuehrungsebene.typ == Fuehrungsebenentyp.Einsatzstelle) {
      return this.prepareNewFuehrungsebene(Fuehrungsebenentyp.LogistikEinsatzabschnitt, this.currentFuehrungsebene.id);
    }

    return null;
  }

  public getCurrentFuehrungsebene(): FuehrungsebeneDTO | null {
    return this.currentFuehrungsebene;
  }

  public getAllFuehrungsebenen(): FuehrungsebeneDTO[] | [] {
    return this.allFuehrungsebenen;
  }

  public getCurrentLage(): LageDTO | null {
    return this.currentLage;
  }

  /**
   * Lädt die Personenübersicht zur übergebenen Führungsebene.
   */
  getPersonenuebersicht(fuehrungsebene: FuehrungsebeneDTO): Observable<PersonenuebersichtDTO> {
    if (!fuehrungsebene.id || !fuehrungsebene.lageId) {
      return EMPTY;
    }
    return this.fuehrungsebeneResourceService.getPersonenuebersichtByFuehrungsebene(
      fuehrungsebene.id,
      fuehrungsebene.lageId
    );
  }

  /**
   * Öffnet einen Dialog mit dem eine Führungsebene in eine andere Führungsebene verschoben werden kann.
   * Wählt der Nutzer eine Führungsebene aus, wird das Verschieben im Backend angestoßen.
   */
  openMoveFuehrungsebeneDialog(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    if (!fuehrungsebeneDTO.id || !fuehrungsebeneDTO.lageId) {
      this.logService.warn(
        'Führungsebene kann nicht verschoben werden, da sie keiner Lage zugeordnet ist oder nicht persistent ist.'
      );
      return;
    }

    this.fuehrungsebeneResourceService
      .getMoveOptions(fuehrungsebeneDTO.id, fuehrungsebeneDTO.lageId)
      .pipe(
        // MoveOptions im Backend anfordern und Dialog öffnen
        switchMap((rootFuehrungsebene: FuehrungsebeneNestedDTO) => {
          if (!rootFuehrungsebene) {
            return throwError(() => 'Auswahlmöglichkeiten zum Verschieben konnten nicht bestimmt werden.');
          }
          return this.openFuehrungsebeneTreeDialog(rootFuehrungsebene, fuehrungsebeneDTO.id).afterClosed();
        }),
        // Auf Auswahl warten und Führungsebene im Backend verschieben
        switchMap((result: FuehrungsebeneTreeNestedDialogOutputData) => {
          if (!result?.selectedFuehrungsebeneId) {
            return EMPTY;
          }

          if (!fuehrungsebeneDTO.id || !fuehrungsebeneDTO.lageId || !result.selectedFuehrungsebeneId) {
            return throwError(() => 'Führungsebene ist nicht vollständig. Verschieben kann nicht durchgeführt werden.');
          }

          return this.fuehrungsebeneResourceService.moveFuehrungsebene(
            fuehrungsebeneDTO.id,
            fuehrungsebeneDTO.lageId,
            result.selectedFuehrungsebeneId
          );
        }),
        catchError((error: HttpErrorResponse) => {
          const errorResponse: ErrorResponse = error.error;
          this.errorService.showError(errorResponse);
          return EMPTY;
        })
      )
      .subscribe(() => {
        const currentLageId = this.currentLage?.id;
        if (currentLageId) {
          this.store.dispatch(fuehrungsebeneActions.getFuehrungsebenen({ lageId: currentLageId }));
        }
      });
  }

  openDeleteFuehrungsebeneDialog(
    fuehrungsebeneDTO: FuehrungsebeneDTO,
    dialogRef: MatDialogRef<FuehrungsebeneDetailsDialogComponent>
  ) {
    if (!fuehrungsebeneDTO.id || !fuehrungsebeneDTO.lageId) {
      this.logService.warn(
        'Führungsebene kann nicht gelöscht werden, da sie keiner Lage zugeordnet ist oder nicht persistent ist.'
      );
      return;
    }

    this.fuehrungsebeneResourceService
      .canFuehrungsebeneBeDeleted(fuehrungsebeneDTO.lageId, fuehrungsebeneDTO.id)
      .pipe(
        switchMap((canBeDeleted: boolean) => {
          if (!canBeDeleted) {
            this.errorService.showErrorMessage(
              'Führungsebene kann nicht gelöscht werden, da sie oder darunterliegende Führungsebenen noch Taktische Zeichen besitzen.'
            );
            return EMPTY;
          }

          return this.dialogService
            .openConfirmDialog(
              'Führungsebene löschen?',
              'Mit dem Löschen der Führungsebene werden auch alle darunterliegenden Führungsebenen gelöscht. Der Vorgang kann nicht rückgängig gemacht werden.'
            )
            .afterClosed();
        })
      )
      .subscribe((result: DialogAction) => {
        if (result === DialogAction.OK) {
          this.actions$.pipe(ofType(fuehrungsebeneActions.deleteFuehrungsebeneSuccess), take(1)).subscribe(() => {
            dialogRef.close();
          });
          this.store.dispatch(fuehrungsebeneActions.deleteFuehrungsebene({ fuehrungsebene: fuehrungsebeneDTO }));
        }
      });
  }

  private getAllowedFuehrungsebeneChildren(parentType: Fuehrungsebenentyp): Map<Fuehrungsebenentyp, number> {
    return this.allowedFuehrungsebeneChildren.get(parentType) || new Map();
  }

  /**
   * Liefert für eine Führungsebene alle Typen, die noch unter ihr angelegt werden dürfen.
   *
   * @param filteredTypes Optionale Liste von zu fokussierenden Typen. Alle Typen, die nicht enthalten sind werden ignoriert.
   */
  public getAvailableChildTypes(parent: FuehrungsebeneDTO, filteredTypes?: Fuehrungsebenentyp[]) {
    const children = this.allFuehrungsebenen.filter(
      (fuehrungsebene) => fuehrungsebene.parentFuehrungsebeneId === parent.id
    );
    const allowedMapping = this.getAllowedFuehrungsebeneChildren(parent.typ);
    const result: Fuehrungsebenentyp[] = [];

    // Entweder nur die gewünschten Typen prüfen, oder alle in der Führungsebene erlaubten
    const types = filteredTypes || allowedMapping.keys();

    for (const type of types) {
      const allowedCount = allowedMapping.get(type) || 0;
      const currentCount = children.filter((child) => child.typ === type).length;
      if (currentCount < allowedCount) {
        result.push(type);
      }
    }
    return result;
  }

  public canFuehrungsebeneHaveMoreChildrenOfType(fuehrungsebeneDTO: FuehrungsebeneDTO, typ: Fuehrungsebenentyp) {
    const allowedMapping = this.getAllowedFuehrungsebeneChildren(fuehrungsebeneDTO.typ);

    const allowedCountOfType = allowedMapping.get(typ) || 0;
    const currentCountOfType = this.allFuehrungsebenen.filter(
      (fuehrungsebene) => fuehrungsebene.parentFuehrungsebeneId === fuehrungsebeneDTO.id && fuehrungsebene.typ === typ
    ).length;
    return currentCountOfType < allowedCountOfType;
  }

  /**
   * Prüft, ob die übergebene Fuehrungsebene noch eine Child-Führungsebene bekommen darf.
   */
  canFuehrungsebeneHaveMoreChildren(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    const childMapping = this.getAllowedFuehrungsebeneChildren(fuehrungsebeneDTO.typ);
    const children = this.allFuehrungsebenen.filter((child) => child.parentFuehrungsebeneId === fuehrungsebeneDTO.id);

    for (const childType of childMapping.keys()) {
      const actualChildrenOfTypeCount = children.filter((child) => child.typ === childType).length;
      const allowedChildrenOfTypeCount = childMapping.get(childType) || 0;
      if (actualChildrenOfTypeCount < allowedChildrenOfTypeCount) {
        return true;
      }
    }

    return false;
  }

  /**
   * Liefert die erlaubte Anzahl Children eines bestimmten Typs für eine Parent-Führungsebene.
   */
  getMaxChildCount(parentFuehrungsebene: FuehrungsebeneDTO, childType: Fuehrungsebenentyp) {
    return this.getAllowedFuehrungsebeneChildren(parentFuehrungsebene.typ).get(childType) || 0;
  }

  /**
   * Öffnet einen Dialog mit dem ein Einsatzfilter aus einer Führungsebene in eine andere Führungsebene verschoben werden kann.
   * Wählt der Nutzer eine Führungsebene aus, wird das Verschieben im Backend angestoßen.
   */
  openMoveEinsatzfilterDialog(einsatzFilterDTO: EinsatzfilterDTO, currentFuehrungsebeneDTO: FuehrungsebeneDTO) {
    if (!einsatzFilterDTO.lageId) {
      this.logService.warn(
        'Einsatzfilter kann nicht verschoben werden, da er keiner Lage zugeordnet ist oder nicht persistent ist.'
      );
      return EMPTY;
    }

    return this.fuehrungsebeneResourceService.getEinsatzfilterMoveTree(einsatzFilterDTO.lageId).pipe(
      switchMap((rootFuehrungsebene) => {
        if (!rootFuehrungsebene) {
          return throwError(() => 'Auswahlmöglichkeiten zum Verschieben konnten nicht bestimmt werden.');
        }

        return this.openFuehrungsebeneTreeDialog(rootFuehrungsebene, currentFuehrungsebeneDTO.id).afterClosed();
      }),
      switchMap((dialogResult: FuehrungsebeneTreeNestedDialogOutputData) => {
        if (!dialogResult) {
          return EMPTY;
        }

        if (!einsatzFilterDTO.id || !einsatzFilterDTO.lageId) {
          return throwError(() => 'Einsatzfilter ist nicht vollständig. Verschieben kann nicht durchgeführt werden.');
        }

        const moveDTO: EinsatzfilterMoveDTO = {
          oldFuehrungsebeneId: currentFuehrungsebeneDTO.id,
          newFuehrungsebeneId: dialogResult.selectedFuehrungsebeneId,
        };
        return this.einsatzfilterResourceService.moveEinsatzfilter(
          einsatzFilterDTO.id,
          einsatzFilterDTO.lageId,
          moveDTO
        );
      })
    );
  }

  /**
   * Öffnet einen Navigationsbaum aus der übergebenen Root-Führungsebene.
   * Die optionale selectedFührungsebene wird optisch hervorgehoben.
   */
  private openFuehrungsebeneTreeDialog(rootFuehrungsebene: FuehrungsebeneNestedDTO, selectedFuehrungsebeneId?: string) {
    const inputData: FuehrungsebeneTreeNestedDialogInputData = {
      treeData: rootFuehrungsebene,
      headerText: 'Verschieben in...',
      selectedFuehrungsebeneId: selectedFuehrungsebeneId,
    };

    return this.dialog.open(FuehrungsebeneTreeSelectNestedDialogComponent, { data: inputData });
  }
}
