import { Component, inject } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ErrorService } from '@product/ise-error-lib';
import { MarschbefehlDTO } from 'src/app/api/build/openapi';

@Component({
  selector: 'app-marschbefehl-form',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, ReactiveFormsModule],
  templateUrl: './marschbefehl-form.component.html',
  styleUrl: './marschbefehl-form.component.scss',
})
export class MarschbefehlFormComponent {
  protected errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);

  formGroup = this.formBuilder.group({
    auftrag: this.formBuilder.control<string>('', [Validators.required, Validators.maxLength(2048)]),
    durchfuehrung: this.formBuilder.control<string | undefined>(undefined, Validators.maxLength(2048)),
    fuehrungUndVerbindung: this.formBuilder.control<string | undefined>(undefined, Validators.maxLength(2048)),
    karte: this.formBuilder.control<string | undefined>(undefined, Validators.maxLength(2048)),
    lageText: this.formBuilder.control<string | undefined>(undefined, Validators.maxLength(2048)),
    marschbefehl: this.formBuilder.control<string | undefined>(undefined, Validators.maxLength(2048)),
    versorgung: this.formBuilder.control<string | undefined>(undefined, Validators.maxLength(2048)),
  });

  private marschbefehlDto?: MarschbefehlDTO;

  setDto(marschbefehlDto: MarschbefehlDTO) {
    this.marschbefehlDto = marschbefehlDto;
    this.formGroup.controls.auftrag.setValue(marschbefehlDto.auftrag);
    this.formGroup.controls.durchfuehrung.setValue(marschbefehlDto.durchfuehrung);
    this.formGroup.controls.fuehrungUndVerbindung.setValue(marschbefehlDto.fuehrungUndVerbindung);
    this.formGroup.controls.karte.setValue(marschbefehlDto.karte);
    this.formGroup.controls.lageText.setValue(marschbefehlDto.lageText);
    this.formGroup.controls.marschbefehl.setValue(marschbefehlDto.marschbefehl);
    this.formGroup.controls.versorgung.setValue(marschbefehlDto.versorgung);
  }

  getDto() {
    if (!this.marschbefehlDto) {
      console.warn('Kein MarschbefehlDTO zum Speichern verfügbar');
      return;
    }
    return {
      ...this.marschbefehlDto,
      auftrag: this.formGroup.controls.auftrag.value.trim(),
      durchfuehrung: this.formGroup.controls.durchfuehrung.value?.trim(),
      fuehrungUndVerbindung: this.formGroup.controls.fuehrungUndVerbindung.value?.trim(),
      karte: this.formGroup.controls.karte.value?.trim(),
      lageText: this.formGroup.controls.lageText.value?.trim(),
      marschbefehl: this.formGroup.controls.marschbefehl.value?.trim(),
      versorgung: this.formGroup.controls.versorgung.value?.trim(),
    };
  }

  validate(): boolean {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return false;
    }
    return true;
  }
}
