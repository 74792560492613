import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output, ViewContainerRef } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EinsatzDTO } from '../../api/build/openapi';
import { EinsatzService } from '../einsatz.service';

@Component({
  selector: 'app-einsatz-item',
  templateUrl: './einsatz-item.component.html',
  styleUrls: ['./einsatz-item.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatCardModule, MatTooltipModule, MatDialogModule],
})
export class EinsatzItemComponent {
  @Input({ required: true })
  einsatzDto!: EinsatzDTO;

  @Input()
  selected = false;

  @Input()
  useDefaultTooltip = true;

  @Output()
  einsatzContextmenu = new EventEmitter<ViewContainerRef>();

  protected hovering = false;
  protected einsatzService = inject(EinsatzService);

  constructor(private viewContainerRef: ViewContainerRef) {}

  contextmenu() {
    this.einsatzContextmenu.emit(this.viewContainerRef);
  }
}
