import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LageberichtDTO } from 'src/app/api/build/openapi';
import { ErrorResponse } from '../../api/build/openapi/model/errorResponse';

export const lageberichtActions = createActionGroup({
  source: 'Lageberichte',
  events: {
    'Reset Store': emptyProps(),

    'Get Lageberichte': props<{ lageId: string }>(),
    'Get Lageberichte Success': props<{ lageberichte: LageberichtDTO[] }>(),
    'Get Lageberichte Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Lagebericht': props<{ lageId: string; copy: boolean }>(),
    'Create Lagebericht Success': props<{ newLagebericht: LageberichtDTO }>(),
    'Create Lagebericht Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Lagebericht': props<{ lagebericht: LageberichtDTO }>(),
    'Patch Lagebericht Success': props<{ patchedLagebericht: LageberichtDTO }>(),
    'Patch Lagebericht Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Lagebericht': props<{ lagebericht: LageberichtDTO }>(),
    'Delete Lagebericht Success': props<{ deletedLageberichtId: string }>(),
    'Delete Lagebericht Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
